/**=========================================================
 * Module: access-login.js
 * Login api
 =========================================================*/
namespace wg {
  App.controller('LoginFormController', ['$rootScope', '$scope', '$http', '$state', '$translate', '$location', '$timeout', 'AuthService',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $state: _IState, $translate: ng.translate.ITranslateService, $location: ng.ILocationService, $timeout: ng.ITimeoutService, AuthService: IAuthService) {

      function goToLastState(_reload) {
        _reload = _.isNil(_reload) ? true : _reload;
        let pName = $rootScope.previousState?.name;
        let params = $rootScope.previousState?.params;

        if (!pName
            || !$state.get(pName)
            || AuthService.isLoginState(pName)
            || AuthService.isPageState(pName)) {
          pName = AuthService.homeState;
          params = {};
        }

        // $state.go(pName, params, {reload: _reload, notify: _reload, inherit: false}).then(
        //     function (response) { // onSuccess
        //       console.info('Finished transitioning after login', response, _.cloneDeep({reload: _reload, notify: _reload, inherit: false, pName: pName, params: params}));
        //     }, function (reason) { // onError
        //       console.info('Error transitioning after login', reason, _.cloneDeep({reload: _reload, notify: _reload, inherit: false, pName: pName, params: params}));
        //       $state.go(AuthService.homeState);
        //     }
        // );
        $timeout(() => {
          $state.go(pName, params, {reload: _reload, notify: _reload, inherit: false}).then(
              function (response) { // onSuccess
              console.info('Finished transitioning after login', response, _.cloneDeep({reload: _reload, notify: _reload, inherit: false, pName: pName, params: params}));
              }, function (reason) { // onError
              console.info('Error transitioning after login', reason, _.cloneDeep({reload: _reload, notify: _reload, inherit: false, pName: pName, params: params}));
                $state.go(AuthService.homeState);
              }
          );
        }, 1);
      }

      if (AuthService.isLoginState($state.current.name) && AuthService.isLogged) {
        console.log('Logged in and still here. Going forward', AuthService.homeState, $rootScope.previousState);
        goToLastState(true);
      }

      $scope.resend_confirmation_email = false;
      // if ($state.params.status === 201) {
      //   $scope.resend_confirmation_email = true;
      // }

      // bind here all data from the form
      $scope.account = {
        username: '',
        password: '',
        remember: true
      };

      $scope.retStatus = {
        loading: false,
        result: null,
        message: '',
      } as IReturnResult;

      $scope.login = function () {
        if ($scope.retStatus.loading)
          return;
        $scope.retStatus.message = '';
        $scope.retStatus.loading = true;
        $scope.retStatus.result = null;

        let timeoutRequest = $timeout(function () {
          $scope.retStatus.loading = false;
          $scope.retStatus.message = 'app.error.TIMEOUT';
          $scope.retStatus.result = "error";
        }, 10000);

        AuthService.login($scope.account.username, $scope.account.password).then(
            function (data) {
              if (WG_debug) console.log('login', data);
              // assumes if ok, response is an object with some data, if not, a string with error
              // customize according to your api
              if (!data.token) {
                $scope.retStatus.message = $translate.instant('pages.login.INCORRECT');
                $scope.retStatus.result = "error";

                if ($scope.account.username.indexOf('@') > -1) {
                  $scope.retStatus.message += '\n' + $translate.instant('pages.login.INCORRECT_WITH_EMAIL');
                }

              } else {
                $scope.retStatus.result = "success";
                AuthService.setUserData({remember: $scope.account.remember || false});
                goToLastState(true);
              }
            }, function (data) {
              if (WG_debug) console.log('login error', data);
              if (data?.non_field_errors) {
                $scope.retStatus.message = data.non_field_errors[0];
                if (AuthService.response.status === 400) {
                  if ($scope.retStatus.message === 'E-mail is not verified.') {
                    $scope.resend_confirmation_email = true;
                  } else if ($scope.account.username.indexOf('@') > -1) {
                    $scope.retStatus.message += '\n' + $translate.instant('pages.login.INCORRECT_WITH_EMAIL');
                  }
                }
              } else {
                $scope.retStatus.message = 'pages.login.SERVERREQUEST';
              }
              $scope.retStatus.result = "error";
            })
            .finally(function () {
              $scope.retStatus.loading = false;
              $timeout.cancel(timeoutRequest);
            });
      };

      $scope.logout = function () {
        AuthService.logout()
            .then(logoutSuccessFn, logoutErrorFn);

        function logoutSuccessFn(data) {
          console.log('logout', 'go', AuthService.logoutState);
          // $state.go(AuthService.logoutState, {}, {reload: AuthService.logoutState, notify: true, inherit: false});
          // Forces a full refresh of the dashboard, to clear local data/cache/etc
          location.hash = '';
          location.reload();
          // $timeout(function () {
          //   location.reload();
          // }, 500);
        }

        function logoutErrorFn(data) {
        }
      };

      $scope.resend = function () {
        // console.log('resend confirmation email', $scope.account);
        AuthService.resend_confirmation($scope.account.username, $scope.account.password)
            .then(resendSuccessFn, resendErrorFn);

        function resendSuccessFn(data) {
        }

        function resendErrorFn(data) {
          if (data?.non_field_errors) {
            $scope.retStatus.message = data.non_field_errors[0];
            if (AuthService.response.status === 400) {
              if ($scope.retStatus.message === 'E-mail is not verified.') {
                $scope.resend_confirmation_email = true;
              } 
            }
          } else {
            $scope.retStatus.message = 'pages.login.SERVERREQUEST';
          }
        }
      };
    }]);
}