App.controller("DevicesSensorsController", ['$rootScope', '$scope', '$filter', '$http', '$location', '$window', 'ngDialog', '$translate',
  function ($rootScope, $scope, $filter, $http, $location, $window, ngDialog, $translate) {
    $scope.mRealTime = true;
    $scope.values = {};
    $scope.sensors = {};
    $scope.selectedSensor;
    $scope.selectedDevices = [];
    $scope.my = {selectedRadio: ""};

    var _chart = undefined;

    var currentBegin, currentEnd;
    var currentSelectedButton;
    var selectedButton;
    var selectedMin, selectedMax;

    Highcharts.setOptions({
      global: {
        useUTC: false
      },
      lang: {
        loading: $translate.instant('highchart.lang.loading'),
        months: $translate.instant('highchart.lang.months').split(', '),
        shortMonths: $translate.instant('highchart.lang.shortMonths').split(', '),
        weekdays: $translate.instant('highchart.lang.weekdays').split(', '),
        exportButtonTitle: $translate.instant('highchart.lang.exportButtonTitle'),
        printButtonTitle: $translate.instant('highchart.lang.printButtonTitle'),
        rangeSelectorFrom: $translate.instant('highchart.lang.rangeSelectorFrom'),
        rangeSelectorTo: $translate.instant('highchart.lang.rangeSelectorTo'),
        rangeSelectorZoom: $translate.instant('highchart.lang.rangeSelectorZoom'),
        resetZoom: $translate.instant('highchart.lang.resetZoom'),
        resetZoomTitle: $translate.instant('highchart.lang.resetZoomTitle'),
        decimalPoint: $translate.instant('highchart.lang.decimalPoint'),
        thousandsSep: $translate.instant('highchart.lang.thousandsSep'),
        numericSymbols: $translate.instant('highchart.lang.numericSymbols').split(', '),
        printChart: $translate.instant('highchart.lang.printChart'),
        downloadPNG: $translate.instant('highchart.lang.downloadPNG'),
        downloadJPEG: $translate.instant('highchart.lang.downloadJPEG'),
        downloadPDF: $translate.instant('highchart.lang.downloadPDF'),
        downloadSVG: $translate.instant('highchart.lang.downloadSVG'),
        contextButtonTitle: $translate.instant('highchart.lang.contextButtonTitle')
        }
    });
    var exportData = {
      devices: [],
      sensor: '',
      min: 0,
      max: 0
    };
    $scope.chartConfig = {
      options: {
        chart: {
          panning: false,
          zoomType: 'x'
        },
        rangeSelector: {
          allButtonsEnabled: true,
          enabled: true,
          buttons: [{
            type: 'day',
            count: 1,
            query: '1d',
            text: '1' + $translate.instant('highchart.buttons.d')
          }, {
            type: 'week',
            count: 1,
            query: '1w',
            text: '1' + $translate.instant('highchart.buttons.w')
          }, {
            type: 'month',
            count: 1,
            query: '1mth',
            text: '1' + $translate.instant('highchart.buttons.m')
          }, {
            type: 'month',
            count: 3,
            query: '3mth',
            text: '3' + $translate.instant('highchart.buttons.m')
          }, {
            type: 'month',
            count: 6,
            query: '6mth',
            text: '6' + $translate.instant('highchart.buttons.m')
          }, {
            type: 'year',
            count: 1,
            query: '1y',
            text: '1' + $translate.instant('highchart.buttons.y')
          }, {
            type: 'all',
            query: '30y',
            text: $translate.instant('highchart.buttons.All')
          }]
        },
        xAxis: {
          events: {
            afterSetExtremes: afterSetExtremes
          },
          ordinal: false,
          title: {
            text: ""
          }
        },
        yAxis: {
          title: {
            text: ""
          }
        },
        legend: {
          enabled: true
        },
        navigator: {
          enabled: true
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: $translate.instant('highchart.lang.downloadPNG'),
                  onclick: function () {
                    _chart.exportChart({type: 'image/png'});
                  }
                }, {
                  text: $translate.instant('highchart.lang.downloadJPEG'),
                  onclick: function () {
                    _chart.exportChart({type: 'image/jpeg'});
                  }
                },
                {
                  text: $translate.instant('highchart.lang.downloadPDF'),
                  onclick: function () {
                    _chart.exportChart({type: 'application/pdf'});
                  }
                },
                {
                  text: $translate.instant('highchart.lang.downloadSVG'),
                  onclick: function () {
                    _chart.exportChart({type: 'image/svg+xml'});
                  }
                },
                {
                  text: $translate.instant('highchart.export', {format: 'CSV'}),
                  onclick: function () {
                    if ($scope.selectedDevices.length <= 0)
                      return;
                    $scope.my.selectedRadio = $scope.selectedDevices[0];
                    ngDialog.openConfirm({
                      template: 'modalDialogId',
                      className: 'ngdialog-theme-default',
                      scope: $scope
                    }).then(function (value) {
                      $window.open($scope.$parent.getUrlExport(value.uuid,
                        exportData.sensor, exportData.min, exportData.max,
                        $scope.chartConfig.options.exporting.filename + "_" + value.name,
                        '.csv'), '_blank');
                    }, function (reason) {
                      console.log('Modal promise rejected. Reason: ', reason);
                    });
                  }
                },
                {
                  text: $translate.instant('highchart.export', {format: 'XML'}),
                  onclick: function () {
                    if ($scope.selectedDevices.length <= 0)
                      return;
                    $scope.my.selectedRadio = $scope.selectedDevices[0];
                    ngDialog.openConfirm({
                      template: 'modalDialogId',
                      className: 'ngdialog-theme-default',
                      scope: $scope
                    }).then(function (value) {
                      $window.open($scope.$parent.getUrlExport(value.uuid,
                        exportData.sensor, exportData.min, exportData.max,
                        $scope.chartConfig.options.exporting.filename + "_" + value.name,
                        '.xml'), '_blank');
                    }, function (reason) {
                      console.log('Modal promise rejected. Reason: ', reason);
                    });
                  }
                },
                {
                  text: $translate.instant('highchart.export', {format: 'JSON'}),
                  onclick: function () {
                    if ($scope.selectedDevices.length <= 0)
                      return;
                    $scope.my.selectedRadio = $scope.selectedDevices[0];
                    ngDialog.openConfirm({
                      template: 'modalDialogId',
                      className: 'ngdialog-theme-default',
                      scope: $scope
                    }).then(function (value) {
                      $window.open($scope.$parent.getUrlExport(value.uuid,
                        exportData.sensor, exportData.min, exportData.max,
                        $scope.chartConfig.options.exporting.filename + "_" + value.name,
                        '.json'), '_blank');
                    }, function (reason) {
                      console.log('Modal promise rejected. Reason: ', reason);
                    });
                  }
                }]
            }
          },
          enabled: true,
          filename: ""
        },
        credits: {
          enabled: false
        }
      },
      series: [],
      title: {
        //text: 'Hello'
      },
      useHighStocks: true,
      loading: false,
      func: function (chart) {
        _chart = chart;
      }
    };

    function afterSetExtremes(e) {
      //console.log($scope.selectedDevices, e);
      $scope.loading = true;
      var min, max;
      if (e.trigger == 'rangeSelectorInput') {
        var rangeSelector = $('input.highcharts-range-selector');
        min = Date.parse(rangeSelector[0].value);
        max = Date.parse(rangeSelector[1].value);
        selectedMin = min;
        selectedMax = max;
        currentBegin = min;
        currentEnd = max;
        currentSelectedButton = undefined;
      } else if (e.trigger == 'rangeSelectorButton') {
        var defaultTime = e.rangeSelectorButton.query;
        selectedButton = _.findIndex($scope.chartConfig.options.rangeSelector.buttons, "query", e.rangeSelectorButton.query);
        currentSelectedButton = selectedButton;
        max = new Date().getTime();
        min = max - defaultTime.parseTime('s') * 1000;
        if (min < 0)
          min = 0;

        currentBegin = min;
        currentEnd = max;
      } else {
        currentSelectedButton = undefined;
        min = e.min;
        max = e.max;

        currentBegin = min;
        currentEnd = max;
      }

      if (e.trigger != "updatedData") {
        exportData.min = min;
        exportData.max = max;
        for (var index in $scope.selectedDevices) {
          $scope.$parent.getDeviceSensorData($scope.selectedDevices[index].uuid, $scope.selectedSensor, min, max, null,
              sensorLoadedData, [$scope.selectedDevices[index], $scope.selectedSensor, index]);
        }
      } else {
        if (selectedButton != undefined) {
          setTimeout(function () {
            $($(".highcharts-button")[selectedButton]).click();
            selectedButton = undefined;
          }, 100);
        }
        if (selectedMin != undefined && selectedMax != undefined) {
          for (var serie in $scope.chartConfig.series) {
            var seriesData = $scope.chartConfig.series[serie].data;
            selectedMin = selectedMin > seriesData[0][0] ? selectedMin : seriesData[0][0];
            selectedMax = selectedMax < seriesData[seriesData.length - 1][0] ? selectedMax : seriesData[seriesData.length - 1][0];
          }
          _chart.xAxis[0].setExtremes(selectedMin, selectedMax);
          selectedMin = undefined;
          selectedMax = undefined;
        }
        $scope.loading = false;
      }
    }

    /*
     * Args:
     * 0 - device
     * 1 - sensor
     * 2 - serieIndex
     */
    function sensorLoadedData(args) {
      var device = args[0];
      var sensor = args[1];
      var serieIndex = args[2];
      var q = null;
      if (angular.isDefined($scope.sensors[sensor].configs.sub_query)) {
        q = $scope.sensors[sensor].configs.sub_query;
      }
      if (!angular.isDefined($scope.chartConfig.series[serieIndex])) {
        $scope.chartConfig.series[serieIndex] = {
          name: device.name + " (" + device.description + ")",
          visible: true,
          data: []
        };
        if (q == null) {
          angular.copy($scope.$parent.data[device.uuid][sensor].seriesData, $scope.chartConfig.series[serieIndex].data);
        } else {
          $scope.chartConfig.series[serieIndex].data = _.map($scope.$parent.data[device.uuid][sensor].seriesData, function (elem) {
            return [elem[0], select(q, elem[1])[0]];
          });
        }
        $rootScope.$watch('lastKnownMessages["' + device.uuid + '"]["' + sensor + '"].payload.timestamp', function (newTimestamp, oldTimestamp) {
          if (!angular.isDefined(newTimestamp) || newTimestamp <= oldTimestamp || !$scope.mRealTime) {
            return;
          }
          var value = $rootScope.lastKnownMessages[device.uuid][sensor].payload.value;
          if (q !== null) {
            value = select(q, value)[0];
          }
          //console.log(newTimestamp, oldTimestamp, 'lastKnownMessages["' + device.uuid + '"]["' , sensor , '"]', $rootScope.lastKnownMessages[device.uuid][sensor].payload, value);
          var dataLength = $scope.chartConfig.series[serieIndex].data.length;
          if (dataLength > 0 && newTimestamp > $scope.chartConfig.series[serieIndex].data[dataLength - 1][0]) {
            $scope.chartConfig.series[serieIndex].data.push([newTimestamp, value]);
            if (!angular.isDefined($scope.values[device.uuid])) {
              $scope.values[device.uuid] = {};
            }
            if (!angular.isDefined($scope.values[device.uuid][sensor])) {
              $scope.values[device.uuid][sensor] = {}
            }
            $scope.values[device.uuid][sensor] = value;
            //  console.log('realtime data added');
            //} else {
            //  console.log('realtime data to old');
          }
        }, true);
      } else {
        if ($scope.$parent.data[device.uuid][sensor].seriesData.length > $scope.chartConfig.series[serieIndex].data.length) {
          if (q == null) {
            angular.copy($scope.$parent.data[device.uuid][sensor].seriesData, $scope.chartConfig.series[serieIndex].data);
          } else {
            $scope.chartConfig.series[serieIndex].data = _.map($scope.$parent.data[device.uuid][sensor].seriesData, function (elem) {
              return [elem[0], select(q, elem[1])[0]];
            });
          }
        }
      }
      $scope.loading = false;
    }

    $scope.deviceChecked = function (device, sensor) {
      //console.log(device, sensor);
      if (!$scope.sensors[sensor].isOpen) {
        // Changed device, clear plot
        $scope.chartConfig.options.exporting.filename = sensor;
        $scope.selectedDevices.length = 0;
        $scope.chartConfig.series.length = 0;
        exportData.devices.length = 0;
        currentBegin = undefined;
        currentEnd = undefined;
        selectedButton = undefined;
        selectedMin = undefined;
        selectedMax = undefined;
        for (var s in $scope.sensors) {
          if ($scope.sensors[s].isOpen) {
            $scope.sensors[s].isOpen = false;
            for (var dev in $scope.sensors[s].devices)
              $scope.sensors[s].devices[dev].isChecked = false;
          }
        }
        $scope.sensors[sensor].isOpen = true;
        $scope.chartConfig.options.yAxis.title.text = (angular.isDefined($rootScope.watgridSensorNames[sensor]) ?
            $rootScope.watgridSensorNames[sensor].unit : "");
      }
      if (!$scope.sensors[sensor].devices[device.uuid].isChecked) {
        for (var dev in $scope.selectedDevices) {
          if ($scope.selectedDevices[dev].uuid == device.uuid) {
            $scope.selectedDevices.splice(dev, 1);
            $scope.chartConfig.series.splice(dev, 1);
            break;
          }
        }
        return;
      }
      $scope.loading = true;
      $scope.selectedSensor = sensor;

      var index = $scope.selectedDevices.length;
      $scope.selectedDevices.push(device);
      selectedButton = currentSelectedButton;

      if (!angular.isDefined(currentBegin)) {
        selectedButton = 0;
        var defaultTime = $scope.chartConfig.options.rangeSelector.buttons[selectedButton].query;
        currentBegin = new Date().getTime() - defaultTime.parseTime('s') * 1000;
      }
      if (angular.isDefined($scope.chartConfig.series[index]))
        $scope.chartConfig.series[index].data.length = 0;

      exportData.sensor = sensor;
      exportData.devices.push(device.uuid);
      $scope.$parent.getDeviceSensorData(device.uuid, sensor, currentBegin, null, null, sensorLoadedData, [device, sensor, index]);
    };

    $rootScope.$watch('WGApiData.AllReady', function () {
      if (!$rootScope.WGApiData.AllReady) {
        return;
      }
      var device;
      for (var index in $scope.$parent.devices) {
        device = $scope.$parent.devices[index];
        if (!angular.isDefined($scope.$parent.data[device.uuid]))
          continue;
        for (var sensor in $scope.$parent.data[device.uuid]) {
          if (angular.isDefined($scope.$parent.watgridSensorNames[sensor])) {
            if (!angular.isDefined($scope.$parent.watgridSensorNames[sensor].configs) || !$scope.$parent.watgridSensorNames[sensor].configs.graph)
              continue
          } else {
            continue
          }
          if (!angular.isDefined($scope.sensors[sensor])) {
            $scope.sensors[sensor] = {};
            $scope.sensors[sensor].configs = $scope.$parent.watgridSensorNames[sensor].configs;
            $scope.sensors[sensor].devices = {};
            $scope.sensors[sensor].isOpen = false;
          }
          $scope.sensors[sensor].devices[device.uuid] = {
            name: device.name,
            internal_name: device.internal_name,
            uuid: device.uuid,
            description: device.description,
            isChecked: false
          };
        }
      }
    });
  }]);