namespace wg {

  const yearsToMilliseconds = (years) => {
    return years * 365.25 * 24 * 60 * 60 * 1000;
  }


  export enum ModalBehaviour {
    RENEW = "renew",
    EDIT = "edit",
    CREATE = "create",

  }

  interface ILicenseSelection extends ILicense {
    client_license_value: null,
    client_license_value_fms?: number,
    client_license_value_other?: number,
    client_license_value_total: number,

    resale_license_value: null,
    resale_license_value_fms?: number,
    resale_license_value_other?: number,
    resale_license_value_total: number,
  }

  export class LicenseSubscriptionModalController {
    NOTES_MAX_LENGTH: number = 2000;


    public form: ng.IFormController;
    public formErrors: any = {};

    public loading: boolean = true;
    public submitted: boolean = false;
    public requestError: string = undefined;
    public success: boolean = undefined;

    public durationOptions = [1, 2, 3, 4, 5];
    public durationInMilliseconds: number = yearsToMilliseconds(this.durationOptions[0]);

    public behaviour: ModalBehaviour;

    //values currently selected/entered by the user (single values)
    public selected: ILicenseSelection = {
      // @ts-ignore
      distributor: {},
      // @ts-ignore
      client_manager: {},
      license_start_date: undefined,
      license_expiration_date: null,
      client_invoice_id: "",
      client_invoice_date: undefined,
      client_license_value: null,
      client_license_value_fms: 0,
      client_license_value_other: 0,
      client_license_value_total: 0,
      resale_invoice_id: "",
      resale_invoice_date: undefined,
      resale_license_value: null,
      resale_license_value_fms: 0,
      resale_license_value_other: 0,
      resale_license_value_total: 0,
      owned_by_distributor: false, // device in trial
      notes: "",
    }
    // WPs, BPs
    private count_fms_sensors = 0;
    // e-aphrom, e-charmat, e-bung, smartcellar
    private count_other_sensors = 0;
    private fms_models = DEVICE_MODELS_THAT_HAS_DENSITY;
    // private resale_default_values = {
    //   "aphrom|charmat|bung|cellar": {
    //     1: 35,
    //     3: 83,
    //     4: 105,
    //   },
    //   "wp1|bp1": {
    //     1: 79,
    //     3: 187,
    //     4: 237,
    //   }
    // }

    public numberOfInvoices: number = 2;

    public newLicenseData: ILicense[];

    // object with the same keys as newLicenseData, but with boolean values indicating if the value is multiple
    public isMultipleValue: {
      [key: string]: boolean
    };


    //cached arrays to pass to child components
    public cachedDistributors: IDistributor[] = null;
    public cachedClientManagers: IUser[] = null;

    static $inject = ['ngDialog', 'moment', '$scope', '$http'];

    constructor(private ngDialog: any, private moment: any, private $scope: ng.IScope, private $http: ng.IHttpService) {
    }

    public $onInit() {
      // set date_now to start of day today
      let date_now = new Date();
      date_now.setHours(0, 0, 0, 0);

      if (this.behaviour === undefined) throw new Error("Behaviour type must be defined");

      //if none is passed, init default values
      if (!this.newLicenseData) {
        this.newLicenseData = [{
          distributor: {} as IDistributor,
          device: {} as IDevice,
          client_manager: {} as IUser,
          license_start_date: date_now,
          license_expiration_date: new Date(new Date(date_now.setFullYear(date_now.getFullYear() + 1)).setHours(23, 59, 59)),
          client_invoice_id: "",
          client_invoice_date: date_now,
          client_license_value: 0.0,
          resale_invoice_id: "",
          resale_invoice_date: date_now,
          resale_license_value: 0.0,
          owned_by_distributor: false,
          notes: "",
        }]
      }

      this.count_fms_sensors = 0;
      this.count_other_sensors = 0;

      _.forEach(this.newLicenseData, (licenseData) => {
        if (!licenseData.distributor) {
          licenseData.distributor = {} as IDistributor;
        }
        if (!licenseData.device) {
          licenseData.device = {} as IDevice;
        }
        if (!licenseData.client_manager) {
          licenseData.client_manager = {} as IUser;
        }

        if (!licenseData.license_start_date) {
          if (licenseData.license_expiration_date) {
            licenseData.license_start_date = new Date(new Date(licenseData.license_expiration_date.getTime() - this.durationInMilliseconds).setHours(0, 0, 0));
          } else {
            licenseData.license_start_date = date_now;
          }
        }
        if (!licenseData.license_expiration_date) {
          licenseData.license_expiration_date = new Date(new Date(licenseData.license_start_date.getTime() + this.durationInMilliseconds).setHours(23, 59, 59));
        }

        if (this.fms_models.includes(licenseData.device?.model?.toUpperCase())) {
          this.count_fms_sensors++;
        } else {
          this.count_other_sensors++;
        }

      });


      this.isMultipleValue = this.checkIfMultipleValues(this.newLicenseData);

      //set selected values to the first element of the array if not multiple value
      // Object.keys(this.isMultipleValue).forEach((key) => {
      //   if (!this.isMultipleValue[key]) {
      //     this.selected[key] = this.newLicenseData[0][key];
      //   }
      // });

      // Fill initial selected values from newLicenseData
      _.forEach(this.selected, (value, key) => {
        // Ignore total values, already calculated
        if (["client_license_value_total", "resale_license_value_total"].includes(key)) {
          this.selected[key] = 0;
        }
        // Find first non-null value from newLicenseData
        _.forEach(this.newLicenseData, (licenseData) => {
          if ((key === "client_license_value_fms" || key === "client_license_value_other")
              && licenseData.client_license_value > 0) {
            if (this.fms_models.includes(licenseData.device?.model?.toUpperCase())) {
              this.selected.client_license_value_fms = licenseData.client_license_value;
            } else {
              this.selected.client_license_value_other = licenseData.client_license_value;
            }
            return false;
          } else if ((key === "resale_license_value_fms" || key === "resale_license_value_other")
              && licenseData.resale_license_value > 0) {
            if (this.fms_models.includes(licenseData.device?.model?.toUpperCase())) {
              this.selected.resale_license_value_fms = licenseData.resale_license_value;
            } else {
              this.selected.resale_license_value_other = licenseData.resale_license_value;
            }
            return false;
          } else if (key === "client_license_value_total") {
            if (_.isFinite(licenseData.client_license_value)) {
              this.selected.client_license_value_total += licenseData.client_license_value;
            }
          } else if (key === "resale_license_value_total") {
            if (_.isFinite(licenseData.resale_license_value)) {
              this.selected.resale_license_value_total += licenseData.resale_license_value;
            }
          } else if (!_.isNil(licenseData[key])) {
            this.selected[key] = licenseData[key];
            return false;
          }
        });
        this.selected.client_license_value_total = _.round(this.selected.client_license_value_total, 2);
        this.selected.resale_license_value_total = _.round(this.selected.resale_license_value_total, 2);
      });

      //cache stuff to pass to child components
      this.cachedDistributors = this.getDistributors();
      this.cachedClientManagers = this.getClientManagers();

      this.getReadableDuration();
    }

    onChangedByKey(key: string) {
      if (WG_debug) console.log("onChangedByKey", key);
      switch (key) {
        case "distributor":
          this.onDistributorChange(this.selected[key]);
          break;
        case "client_manager":
          this.onClientManagerChange(this.selected[key]);
          break;
        case "license_start_date":
          this.onStartDateChange(this.selected[key]);
          break;
        case "license_expiration_date":
          this.onEndDateChange(this.selected[key]);
          break;
        case "client_invoice_id":
          this.onClientInvoiceIDChange(this.selected[key]);
          break;
        case "client_invoice_date":
          this.onClientInvoiceDateChange(this.selected[key]);
          break;
        case "client_license_value":
          this.onClientLicenseValueChanged(this.selected[key]);
          break;
        case "client_license_value_fms":
        case "client_license_value_other":
          this.onClientLicenseValueChanged(this.selected[key], key.endsWith("_fms") ? "fms" : "other");
          break;
        case "resale_invoice_id":
          this.onResaleInvoiceIdChange(this.selected[key]);
          break;
        case "resale_invoice_date":
          this.onResaleInvoiceDateChange(this.selected[key]);
          break;
        case "resale_license_value":
          this.onResaleLicenseValueChanged(this.selected[key]);
          break;
        case "resale_license_value_fms":
        case "resale_license_value_other":
          this.onResaleLicenseValueChanged(this.selected[key], key.endsWith("_fms") ? "fms" : "other");
          break;
        case "notes":
          this.onNotesChange(this.selected[key]);
          break;

      }
    }

    onDistributorChange = (user: any) => {
      if (WG_debug) console.log("onDistributorChange");
      this.selected.distributor = user;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.distributor = user;
      })
    }

    onClientManagerChange = (user: any) => {
      if (WG_debug) console.log("onClientManagerChange");
      this.selected.client_manager = user;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.client_manager = user;
      })
    }


    onStartDateChange = (date: Date) => {
      if (WG_debug) console.log("onStartDateChange");
      date.setHours(0, 0, 0, 0);

      this.selected.license_start_date = date;

      this.selected.license_expiration_date = this.moment(date).add(this.durationInMilliseconds, "milliseconds").toDate();
      this.selected.license_expiration_date.setHours(23, 59, 59);

      this.newLicenseData.forEach((licenseData) => {
        licenseData.license_start_date = this.selected.license_start_date;
        licenseData.license_expiration_date = this.selected.license_expiration_date;
      })
    }

    onEndDateChange = (date: Date) => {
      if (WG_debug) console.log("onEndDateChange");
      //if date > start date

      if (date < this.selected.license_start_date) {
        alert("app.common.END_DATE_BEFORE_START_DATE");
        return;
      }

      this.selected.license_expiration_date = date;
      this.selected.license_expiration_date.setHours(23, 59, 59);

      this.newLicenseData.forEach((licenseData) => {
        licenseData.license_expiration_date = date;
      })


      if (this.selected.license_start_date == null) {
        this.durationInMilliseconds = null;
        return // cannot calculate the duration without a start date
      }

      //calculate the duration
      this.durationInMilliseconds = date.getTime() - this.selected.license_start_date?.getTime();

      this.getReadableDuration();
    }

    onDurationChange = (durationInYears: number) => {
      // TODO: Allow multiple values for license_duration
      // if (WG_debug) console.log("onDurationChange");

      // add `durationInYears` years to the start date
      this.newLicenseData.forEach((license) => {
        license.license_expiration_date = this.moment(license.license_start_date || this.selected.license_start_date).add(durationInYears, "years").toDate();
        license.license_expiration_date.setHours(23, 59, 59);
      });

      this.selected.license_expiration_date = this.newLicenseData[0].license_expiration_date;

      this.durationInMilliseconds = yearsToMilliseconds(durationInYears);
      this.getReadableDuration();

    }

    readableDuration: string = null;

    getReadableDuration() {
      if (WG_debug) console.log("getReadableDuration");
      if (this.durationInMilliseconds === null) {
        return "N/A";
      }

      let duration = this.moment.duration(this.durationInMilliseconds, "milliseconds");
      if (duration.days() > 20) {
        duration = duration.add(11, "days");
      }

      const years = duration.years();
      const months = duration.months();

      let _readableDuration = "";

      if (years > 0) {
        _readableDuration += this.moment.duration(years, "years").humanize({d: 7, M: 12});
      }
      if (months > 0) {

        if (_readableDuration.length > 0) {
          _readableDuration += " ";
        }
        _readableDuration += this.moment.duration(months, "months").humanize({d: 7, M: 12});
      }

      this.readableDuration = "~" + _readableDuration;
      return this.readableDuration;
    }

    public onClientInvoiceIDChange(id: string) {
      this.selected.client_invoice_id = id;
      this.newLicenseData.forEach((licenseData) => {
        licenseData.client_invoice_id = id;
      })
    }

    public onClientInvoiceDateChange(date: Date) {
      this.selected.client_invoice_date = date;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.client_invoice_date = date;
      })
    }

    public onClientLicenseValueChanged(value: number, type: string = "fms") {
      // if (WG_debug) console.log("onClientLicenseValueChanged", this.newLicenseData);
      if (!_.isFinite(value)) return;
      // this.selected.client_license_value = value;
      if (type === "fms") {
        this.selected.client_license_value_fms = value;
      } else {
        this.selected.client_license_value_other = value;
      }
      this.selected.client_license_value_total = 0;

      this.newLicenseData.forEach((licenseData) => {
        if (type === "fms"
            && this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
          licenseData.client_license_value = this.selected.client_license_value_fms;
        }
        if (type !== "fms"
            && !this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
          licenseData.client_license_value = this.selected.client_license_value_other;
        }
        if (_.isFinite(licenseData.client_license_value)) {
          this.selected.client_license_value_total += licenseData.client_license_value;
        }
      })
      this.selected.client_license_value_total = _.round(this.selected.client_license_value_total, 2);
    }

    public onResaleInvoiceIdChange(id: string) {
      this.selected.resale_invoice_id = id;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.resale_invoice_id = id;
      })
    }

    public onResaleInvoiceDateChange(date: Date) {
      this.selected.resale_invoice_date = date;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.resale_invoice_date = date;
      })
    }

    public onResaleLicenseValueChanged(value: number, type: string = "fms") {
      if (!_.isFinite(value)) return;
      // this.selected.resale_license_value = value;
      if (type === "fms") {
        this.selected.resale_license_value_fms = value;
      } else {
        this.selected.resale_license_value_other = value;
      }
      this.selected.resale_license_value_total = 0;

      this.newLicenseData.forEach((licenseData) => {
        if (type === "fms"
            && this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
          licenseData.resale_license_value = this.selected.resale_license_value_fms;
        }
        if (type !== "fms"
            && !this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
          licenseData.resale_license_value = this.selected.resale_license_value_other;
        }
        if (_.isFinite(licenseData.resale_license_value)) {
          this.selected.resale_license_value_total += licenseData.resale_license_value;
        }
      })
      this.selected.resale_license_value_total = _.round(this.selected.resale_license_value_total, 2);
    }

    public onOwnedByDistributorChange(owned_by_distributor: boolean) {
      // if (WG_debug) console.log("onOwnedByDistributorChange", owned_by_distributor);
      this.selected.owned_by_distributor = owned_by_distributor;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.owned_by_distributor = owned_by_distributor;
      })
    }

    public onNotesChange(notes: string) {
      this.selected.notes = notes;

      this.newLicenseData.forEach((licenseData) => {
        licenseData.notes = notes;
      })
    }


    saveData() {
      if (WG_debug) console.log("saveData", this.newLicenseData);

      // If a validateForm() fails on any newLicenseData, return
      if (!this.newLicenseData.every((licenseData) => this.validateForm(licenseData))) {
        return;
      }

      const postData = this.buildPostData(this.newLicenseData);

      this.submitted = true;
      this.loading = true;

      Promise.all(
          postData.map((data) => {
            if (this.behaviour === ModalBehaviour.CREATE || this.behaviour === ModalBehaviour.RENEW) {
              return this.$http.post('api/dashboard/distributors/license/', data)
            } else {
              return this.$http.put("api/dashboard/distributors/license/" + data["id"] + "/", data)
            }
          }))
          .then((response) => {
            if (response.every((res) => res.status === 201 || res.status === 200)) {
              this.$scope.$apply(() => {
                this.success = true;
                this.loading = false;

              });

              setTimeout(() => {
                this.ngDialog.close();
              }, 1000);
            } else {
              this.requestError = "app.error.UNEXPECTED_ERROR";
            }
            this.success = true;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.requestError = error.data;
          })


    }

    cancel() {
      this.ngDialog.close();
    }

    validateForm(license: ILicense): boolean {

      this.formErrors = {};

      //DISTIBUTOR
      if (!license.distributor || !license.distributor.id) {
        this.formErrors['distributor'] = 'app.error.REQUIRED';
      }

      // //CLIENT MANAGER
      // if (!this.client_manager || this.client_manager.trim().length === 0) {
      //     this.formErrors['client_manager'] = 'app.error.REQUIRED';
      // }

      //LICENSE DATES
      if (!license.license_start_date) {
        this.formErrors['startDate'] = 'app.error.REQUIRED';
      }
      if (license.license_start_date > license.license_expiration_date) {
        this.formErrors['endDate'] = 'app.common.END_DATE_BEFORE_START_DATE';
      }


      // if (!(license.client_invoice_id)) this.formErrors['clientInvoiceId'] = 'app.error.REQUIRED';
      // if (!(license.client_invoice_date)) this.formErrors['clientInvoiceDate'] = 'app.error.REQUIRED';
      // if (!(license.client_license_value)) this.formErrors['clientLicenseValue'] = 'app.error.REQUIRED';


      //NOTES
      if (license.notes && license.notes.length > this.NOTES_MAX_LENGTH) {
        this.formErrors['notes'] = 'app.common.MAX_LENGTH';
      }

      return _.isEmpty(this.formErrors);
    }


    buildPostData(info: ILicense[]): {}[] {

      //if deviceInfo is not an array, throw an error
      if (!Array.isArray(info)) {
        throw new Error("deviceInfo must be an array");
      }

      return info.map((licenseData) => {
        return {
          id: licenseData?.id,
          distributor_id: licenseData?.distributor.id,
          device_id: licenseData?.device.id,
          client_manager_id: licenseData?.client_manager.id,
          license_start_date: licenseData?.license_start_date,
          license_expiration_date: licenseData?.license_expiration_date,
          client_invoice_id: licenseData?.client_invoice_id,
          client_invoice_date: licenseData?.client_invoice_date,
          client_license_value: licenseData?.client_license_value,
          resale_invoice_id: licenseData?.resale_invoice_id,
          resale_invoice_date: licenseData?.resale_invoice_date,
          resale_license_value: licenseData?.resale_license_value,
          owned_by_distributor: licenseData?.owned_by_distributor,
          notes: licenseData?.notes
        }
      });
    }


    checkIfMultipleValues(licenseData: ILicense[]) {
      if (WG_debug) console.log("Checking for multiple values");

      const isMultipleValue = {};
      let _keys = Object.keys(licenseData[0]);
      _.pull(_keys, "id");
      _keys.push("client_license_value_fms");
      _keys.push("client_license_value_other");
      _keys.push("resale_license_value_fms");
      _keys.push("resale_license_value_other");
      _keys.push("device_owner");

      _.forEach(_keys, (key) => {
        isMultipleValue[key] = false;
        for (let i = 0; i < licenseData.length; i++) {
          if (isMultipleValue[key]) break;
          const license_i = licenseData[i];
          for (let j = i + 1; j < licenseData.length; j++) {
            if (isMultipleValue[key]) break;
            const license_j = licenseData[j];
            // If the value of the key is different in any of the elements, set isMultipleValue[key] to true
            // If key containes "date", check if date is equal with precision to the day
            if (key.includes("date")) {
              if (this.moment(license_i[key]).startOf('day').isSame(this.moment(license_j[key]).startOf('day'))) {
                continue;
              } else {
                isMultipleValue[key] = true;
                break;
              }
            } else if (key.includes("time")) { // if key contains "time", compare with precision to the second
              if (this.moment(license_i[key]).isSame(this.moment(license_j[key]))) {
                continue;
              } else {
                isMultipleValue[key] = true;
                break;
              }
            } else if (["distributor", "client_manager", "device", "license"].includes(key)) {
              if (license_i[key]?.id != license_j[key]?.id) {
                isMultipleValue[key] = true;
                break;
              }

            } else if (["device_owner"].includes(key)) {
              if (license_i['device']?.owner?.id != license_j['device']?.owner?.id) {
                isMultipleValue[key] = true;
                break;
              }
            } else if (["client_license_value_fms", "resale_license_value_fms", "client_license_value_other", "resale_license_value_other"].includes(key)) {

              if (key.endsWith("_fms")
                  && !this.fms_models.includes(license_i.device?.model?.toUpperCase())) {
                break;
              }
              if (key.endsWith("_other")
                  && this.fms_models.includes(license_i.device?.model?.toUpperCase())) {
                break;
              }
              if (key.endsWith("_fms")
                  && !this.fms_models.includes(license_j.device?.model?.toUpperCase())) {
                continue;
              }
              if (key.endsWith("_other")
                  && this.fms_models.includes(license_j.device?.model?.toUpperCase())) {
                continue;
              }
              if (key.includes("client_license_value")
                  && license_i.client_license_value != license_j.client_license_value) {
                isMultipleValue[key] = true;
                break;
              }
              if (key.includes("resale_license_value")
                  && license_i.resale_license_value != license_j.resale_license_value) {
                isMultipleValue[key] = true;
                break;
              }
            } else if (license_i[key] != license_j[key]) {
              isMultipleValue[key] = true;
              break;
            }
          }
        }
      });

      return isMultipleValue;
    }

    onMultipleValuesPreventionOverride(key: any) {
      if (WG_debug) console.log("onMultipleValuesPreventionOverride", key, _.cloneDeep(this.isMultipleValue));
      this.isMultipleValue[key] = false;
      if (key === "client_license_value_fms" || key === "client_license_value_other") {
        if (this.fms_models.includes(this.selected.device?.model?.toUpperCase())) {
          this.selected.client_license_value_fms = this.newLicenseData[0].client_license_value;
        } else {
          this.selected.client_license_value_other = this.newLicenseData[0].client_license_value;
        }
      } else if (key === "resale_license_value_fms" || key === "resale_license_value_other") {
        if (this.fms_models.includes(this.selected.device?.model?.toUpperCase())) {
          this.selected.resale_license_value_fms = this.newLicenseData[0].resale_license_value;
        } else {
          this.selected.resale_license_value_other = this.newLicenseData[0].resale_license_value;
        }
      } else {
        this.selected[key] = this.newLicenseData[0][key];
      }
      // Copy first data to all others
      _.forEach(this.newLicenseData, (licenseData) => {
        if (key === "client_license_value_fms" || key === "client_license_value_other") {
          if (this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
            licenseData.client_license_value = this.selected.client_license_value_fms;
          } else {
            licenseData.client_license_value = this.selected.client_license_value_other;
          }
        } else if (key === "resale_license_value_fms" || key === "resale_license_value_other") {
          if (this.fms_models.includes(licenseData.device.model?.toUpperCase())) {
            licenseData.resale_license_value = this.selected.resale_license_value_fms;
          } else {
            licenseData.resale_license_value = this.selected.resale_license_value_other;
          }
        } else {
          licenseData[key] = this.selected[key];
        }

      });
      this.onChangedByKey(key);
    }

    getDistributors = () => {
      if (!this.newLicenseData) return [];

      return this.newLicenseData
          .map((licenseData) => licenseData.distributor)
      // .filter(distributor => distributor !== undefined);
    }


    getClientManagers = () => {

      if (!this.newLicenseData) return [];

      return this.newLicenseData
          .map((licenseData) => licenseData.client_manager)
      // .filter(client_manager => client_manager !== undefined);
    }


  }


  App.component('licenseSubscriptionModal', {
    controller: LicenseSubscriptionModalController as any,
    templateUrl: "app/views/modals/license-subscription-modal.html",
    bindings: {
      invoices: '<?',
      newLicenseData: '=',
      createNew: '<?',
      behaviour: '<',
    },
    controllerAs: 'ctrl'
  });
}
