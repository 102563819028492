/**
 * Created by pmec on 20/11/15.
 */
namespace wg {
  App.controller('CrontabModalInstance', ['$rootScope', '$scope', '$http', '$filter', 'moment', 'WGApiData', '$translate', '$timeout', 'APIUtils',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, moment, WGApiData: WGApiData, $translate: ng.translate.ITranslateService, $timeout, APIUtils: APIUtils) {

      $scope.ui = {
        slider_vals: [1, 2, 3, 4, 6, 8, 12, 24, 48],
        schedulerCells: [[0, 1, 2, 3, 4, 5], [6, 7, 8, 9, 10, 11], [12, 13, 14, 15, 16, 17], [18, 19, 20, 21, 22, 23]],
        schedulerCellsValues: [
          false, false, false, false, false, false,
          false, false, false, false, false, false,
          false, false, false, false, false, false,
          false, false, false, false, false, false],
        minute_vals: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
        isValid: false,
        help: false,
        crontab_automatic: false,
        cronner: '',
      };

      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'crontabRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }

      // @ts-ignore
      let CronParser = window?.['CronParser'] || global?.window?.['CronParser'] || global?.['CronParser'] || CronParser;

      $scope.cancel = function () {
        parentRet.loading = false;
        parentRet.result = '';
        parentRet.message = 'Cancelled';

        $scope.closeThisDialog();
      };

      //      ---     ACTIONS            ----        ***
      $scope.save = function (data) { // Called from crontab.jade
        console.log("SAVING. data:", data);
        parentRet.loading = true;
        parentRet.result = '';
        parentRet.message = ''

        let devices_loading = 0;

        // For Each device, save !
        for (let i in $scope.devices) {
          let _device = $scope.devices[i];


          let ret = APIUtils.save_device_configs(_device, {crontab: data}, function (response) { // onSuccess
                console.log('CronTab onSuccess', response);
                if (parentRet.result == '') {
                  parentRet.result = 'success';
                }
                devices_loading--;
                if (devices_loading === 0)
                  $scope.finished();
              }, function (response) { // onError
                console.error('CronTab onError', response);
                parentRet.result = 'error';
                parentRet.message = response?.data?.message || 'error';
                devices_loading--;
                if (devices_loading === 0)
                  $scope.finished();
              }
          );
          if (ret) {
            devices_loading++;
          }
        }
      };

      /**
       * Call when all API calls return
       */
      $scope.finished = function () {
        // Inform load as finished
        parentRet.loading = false;

        if (parentRet.result == 'success') {
          if ($scope.confirm)
            $scope.confirm();
          else if ($scope.closeThisDialog)
            $scope.closeThisDialog();
          else
            $scope.close(0);
        }
      };

      function activate_new() {
        $scope.crontab_manual = false;
        $scope.ui.crontab_automatic = false; // True when sample interval is dynamic according to the density measurement
        if (WG_debug) console.log("Opening set interval modal", $scope.ngDialogData);

        //    NEW METHOD INIT
        let inputer = $('#set-interval-modal #freq-slider');
        // tempo entre leituras em Horas
        let iF: number = null; // intervalo de frequencia
        // numero de leituras por dia
        let mF: number = 12;   // multiplicador de frequencia
        let prevMF: number = null;
        // hora escolhida pelo user no horario
        let hR: number = 0;    // hora de referencia
        // primeira hora calculada, que seja a primeira leitura depois das 00h
        let hI: number = null; // hora inicial

        $scope.ui.help = false;
        $scope.ui.mF = mF;
        $scope.ui.iF = iF;

        $scope.ui.slideredValue = $scope.ui.slider_vals.indexOf(mF);
        $scope.ui.sliderValPos = {
          "left": "" + scale($scope.ui.slideredValue, 0, $scope.ui.slider_vals.length - 1, -10, 220) + "px"
        };

        $scope.ui.minute_offset = 0;
        $scope.ui.sliderMinOffsetPos = {"left": "" + scale($scope.ui.minute_offset, 0, 55, -10, 220) + "px"};

        //      ---     FUNCTIONS            ----        ***

        //  NEW METHOD FUNCTION
        function readCron(ct: string, isTest = false) {
          // read crontab from configs and interpret it for the new interface
          // lacks a catch for unconsidered crontabs
          let g = ct.split(' ');
          // console.log('[SC] reading this crontab', ct);
          // console.log('[SC] split', g);
          // console.log('[SC] ', g[2]==='*'?'in the minutes':'in the hours');
          let mFS: number = null; // multiplicador de frequencia Save(?) - numero de leituras diarias
          let hIS: number = null; // hora inicial Save (?) - primeira hora do dia para ler
          // min or hours

          // breaking it down
          // if (g[2] === '*') {
          if (g.length < 6) {
            // Standard crontab detected. No seconds
            // esta lógica faz que qualquer crontab standard, definido em Minutos,
            // passe a estar dentro dos conformes deste novo método definido em segundos
            // este método não sabe ler esses crontabs, mas força-os a tornarem-se aceitáveis.

            // MINUTES
            let m = g[0].split('/');
            $scope.ui.minute_offset = parseInt(m[0]) || 0;
            mFS = 24;
            if (m.length > 1)
              mFS = 48;
          } else {
            // MINUTES
            let m = g[1].split('/');
            $scope.ui.minute_offset = parseInt(m[0]) || 0;

            if (g[2] === '*') {
              // Every hour or more
              mFS = 24;
              if (m.length > 1)
                mFS = 48;
            } else {

              //  HOURS
              let h = g[2].split('/');

              // check for time zone difference and determine first daily reading
              var offsetInHours = Math.round(new Date().getTimezoneOffset() / 60);
              hIS = parseInt(h[0]) - offsetInHours;
              if (hIS > 23)
                hIS = hIS - 24;
              if (hIS > 23)
                console.log("error#98fe4hf9w84hofw4feho2");

              // determine number of daily readings
              mFS = 1;
              if (h.length > 1)
                mFS = 24 / parseInt(h[1]); // this was the error
            }
          }

          // for tests
          if (isTest) {
            console.log("testing. Crontab: " + ct + ". leituras diarias: x" + mFS + ". 1ªleitura às: " + (hIS || "00h") + ".");
            return;
          }

          // allocate data
          mF = mFS;
          $scope.ui.mF_init = mF || '';
          $scope.ui.mF = mF;
          $scope.ui.slideredValue = $scope.ui.slider_vals.indexOf(mF);
          $scope.onFreqSelected();
          if (hIS !== null) {
            $scope.onHorarioHandle(hIS);
            $scope.ui.hI_init = hIS;
          }

        }

        let getCronner = function (timezone_offset = null, startup = false) {
          if (startup) {
            $scope.validate();
            $scope.parserTest();
            return
          }
          if (!timezone_offset) {
            timezone_offset = new Date().getTimezoneOffset()
          }
          // calculate the crontab from selected value ui uses
          // final value in admin/debug mode is the value that is sent for the config
          let cronner = 'set a start time';
          let intervalo_h = iF;

          // check for time zone and re-determine initial hour
          let offsetInHours = timezone_offset / 60.0;

          let h_inicial = hI + offsetInHours;
          if (h_inicial >= 24) {
            h_inicial = h_inicial - 24;
          }
          if (h_inicial < 0) {
            h_inicial = h_inicial + 24;
          }

          if (h_inicial < 0 || h_inicial >= 24) {
            console.log("error#98fe4hf9w84hofw4feho1");
          }
          if (intervalo_h > 1)
            h_inicial = h_inicial % intervalo_h;

          h_inicial = Math.floor(h_inicial);

          let m_inicial = ($scope.ui.minute_offset || 0) % 60;
          if (intervalo_h < 1) {
            let m_inicial = ($scope.ui.minute_offset || 0) % 30;
            cronner = "0 " + m_inicial + "/30 * * * *";
          } else if (intervalo_h === 1) {
            cronner = "0 " + m_inicial + " * * * *";
          } else if (intervalo_h === 24) {
            cronner = "0 " + m_inicial + " " + h_inicial + " * * *";
          } else if (h_inicial !== null) {
            cronner = "0 " + m_inicial + " " + h_inicial + "/" + intervalo_h + " * * *";
          } else {
            // no error
            // Waiting for secondary user input
            // console.log("error#98hfo4ny9ytvo4lth");
          }
          $scope.ui.cronner = cronner;
          if (WG_debug) console.log("Cron calculated for timezone: ", (timezone_offset / 60.0), cronner);

          $scope.validate();
          $scope.parserTest();
        }

        $scope.changeUIValue = function (valor: string) {
          if (!valor || !angular.isDefined(valor))
            valor = $scope.ui.slideredValue;
          let _valor = parseInt(valor);
          if (WG_debug) console.log("changeUIValue. valor:", valor, "$scope.ui.slider_vals[valor]", $scope.ui.slider_vals[valor]);

          $scope.ui.mF = $scope.ui.slider_vals[_valor];
          $scope.ui.iF = 24 / $scope.ui.mF;

          $scope.ui.sliderValPos = {
            "left": "" + scale($scope.ui.slideredValue, 0, $scope.ui.slider_vals.length - 1, -10, 220) + "px"
          };
          $scope.ui.sliderMinOffsetPos = {
            "left": "" + scale($scope.ui.minute_offset, 0, 55, -10, 220) + "px"
          };
          $scope.onFreqSelected(valor);
          // $scope.onHorarioHandle(hR);

        };

        $scope.onFreqSelected = function (valor: string = null, startup = false) {
          // as this is called on onMouseUp, function doesn't have value, so we go to ng-model to get it
          if (_.isNil(valor)) {
            valor = $scope.ui.slideredValue;
          }
          // abort if same value
          let _valor = parseInt(valor);
          if (_valor === prevMF) {
            // $scope.ui.mF= $scope.ui.slider_vals[_valor];
            return;
          }

          prevMF = _valor;
          // DISABLE SUBMIT
          // submit_int_btn.disabled = true;
          // CHECK SLIDER
          // outputer.text($scope.ui.slider_vals[_valor] + 'x');
          // translate continuos value of slider into usable values
          mF = $scope.ui.slider_vals[_valor];
          $scope.ui.mF = mF;
          // calculate intervalo entre leituras
          iF = 24 / mF;
          $scope.ui.iF = iF;
          if (WG_debug) console.log("onFreqSelected 2", {valor: _valor, mF: mF, iF: iF});
          // limpar hora escolhida
          // hR = 0;
          hI = 0;
          $scope.ui.hI = 0;
          //Limpar horario
          $scope.ui.schedulerCellsValues = [
            false, false, false, false, false, false,
            false, false, false, false, false, false,
            false, false, false, false, false, false,
            false, false, false, false, false, false];

          $scope.onHorarioHandle(hR, startup);
        };

        /**
         * Change the Hour offset and recalculate the cronner
         */
        $scope.onHorarioHandle = function (sel_time: string | number, startup = false) {
          hR = parseInt(sel_time as string);	//	hora de referencia
          let valArr = [];
          hI = hR - (Math.floor(hR / iF) * iF); // hora da primeira leitura diária
          $scope.ui.hI = hI;
          // calcular as horas que estão incluídas, tamanho do array igual ao mF
          for (var k = 0; k <= mF; k++) {
            valArr.push((hI + iF * k));
          }
          _.forEach($scope.ui.schedulerCellsValues, function (val: boolean, i: number) {
            $scope.ui.schedulerCellsValues[i] = (valArr.indexOf(i) > -1);
          });

          if (WG_debug) console.log("onHorarioHandle, sel_time", sel_time, "valArr", valArr, "hI", hI, "hR", hR, "iF", iF);
          getCronner(null, startup);
        };

        /**
         * Change the minute offset and recalculate the cronner
         */
        $scope.changeMinOffset = function (valor: string | number, startup = false) {
          if (_.isNil(valor))
            valor = $scope.ui.minute_offset;
          $scope.ui.minute_offset = parseInt(valor as string);

          if (WG_debug) console.log("changeMinOffset. valor:", $scope.ui.minute_offset);

          $scope.ui.sliderMinOffsetPos = {"left": "" + scale($scope.ui.minute_offset, 0, 55, -10, 220) + "px"};

          getCronner(null, startup);
        };

        $scope.parserTest = function (currentDate = new Date()) {
          let _first = null;
          if (_.size($scope.devices) == 1 && $scope.devices[0].uuid) {
            _first = WGApiData.WGDevices.devices_uuid[$scope.devices[0].uuid]?.next_read;
          }
          if (!_.isFinite(_first) || _first <= 0) {
            _first = null;
          }

          $scope.parserNumTests = 3;
          $scope.parserTests = emptyOrCreateArray($scope.parserTests);

          let parser = null;
          try {
            parser = CronParser.parseExpression($scope.ui.cronner, {
              utc: true,
              currentDate: (_first ? _first + (iF ? (iF * 60 * 60 * 1000 / 2) : 1000) : null)
            }, null);
          } catch (e) {
            if (WG_debug) console.error('Failed to parse Crontab', $scope.ui.cronner);
            return;
          }
          if (!parser)
            return;

          // current config will only be applied during next read
          let i = 0;
          if (_first) {
            $scope.parserTests.push(new Date(_first));
            i++
          }
          for (; i < $scope.parserNumTests; i++) {
            let _next = new Date(parser.next());
            $scope.parserTests.push(_next);
          }
        }

        $scope.validate = function () {
          if (WG_debug) console.log("Validating cron", $scope.ui.cronner);

          let errorField = null;
          if ($('#id-save').length) {
            try {
              errorField = $('#id-save').parsley();
              errorField.reset();
            } catch (e) {
            }
          } else if ($('#id-crontab').length) {
            try {
              errorField = $('#id-crontab').parsley();
              errorField.reset();
            } catch (e) {
            }
          }

          if ($rootScope.AuthService.view_as_owner && $rootScope.AuthService.view_as_owner.write === false) {
            if (errorField)
              errorField.addError('error-0', {message: $translate.instant('app.profile.shared.READ_ONLY')});
            $scope.ui.isValid = false;
            return false;
          }

          if (_.isNil(hI) || hI < 0 || hI >= 24) {
            if (errorField)
              errorField.addError('error-0', {message: "Invalid Start Time:" + hI});
            $scope.ui.isValid = false;
            return false;
          }

          if (_.isNil(mF) || mF > 48) {
            if (errorField)
              errorField.addError('error-0', {message: "Invalid frequency: " + mF});
            $scope.ui.isValid = false;
            return false;
          }

          var currentDate = new Date();

          try {
            let parser = CronParser.parseExpression($scope.ui.cronner);
            if (WG_debug) console.log("cron parsed", parser);
          } catch (err) {
            if (errorField)
              errorField.addError('error-0', {message: err.message});
            $scope.ui.isValid = false;
            return false;
          }
          // $scope.data.error = true;
          // console.log('Error: ', err.message);
          $scope.ui.isValid = true;
        }

        // Supports single and multi-devices call
        $scope.devices = [];

        if ($scope.ngDialogData.device)
          $scope.devices.push($scope.ngDialogData.device);

        for (let i in $scope.ngDialogData.devices) {
          $scope.devices.push($scope.ngDialogData.devices[i]);
        }

        //  GET current cronner configuration from the first device configs', which are stored in lastKnownMessages
        let _device = $scope.devices[0];
        let _configs_value = $rootScope.lastKnownMessages[_device.uuid]?.configs?.payload?.value;

        if (WG_debug) console.log('Current configs', _configs_value);
        if (_configs_value?.crontab_automatic?.active === true) {
          $scope.ui.crontab_automatic = true;
        }

        if (!$scope.ui.crontab_automatic && !_.isNil(_configs_value?.crontab)) {
          readCron(_configs_value.crontab);
        }

        parentRet.loading = false;
        parentRet.result = "";

        // CHECK SLIDER size
        // @ts-ignore
        inputer.max = $scope.ui.slider_vals.length - 1;
        $scope.ui.slideredValue = $scope.ui.slider_vals.indexOf(mF);

        // if (!$scope.ui.crontab_automatic && !_.isNil(_configs_value?.crontab)) {
        //   $scope.ui.cronner = _configs_value.crontab;
        // }
        // $scope.onFreqSelected(null, !_.isEmpty($scope.ui.cronner));

        $timeout(function () {
          if (!$scope.ui.crontab_automatic && !_.isNil(_configs_value?.crontab)) {
            $scope.ui.cronner = _configs_value.crontab;
          }
          $scope.onFreqSelected(null, !_.isEmpty($scope.ui.cronner));
          // $scope.onHorarioHandle(hR, !_.isEmpty($scope.ui.cronner));
        }, 10);
        $scope.ui.sliderValPos = {
          "left": "" + scale($scope.ui.slideredValue, 0, $scope.ui.slider_vals.length - 1, -10, 220) + "px"
        };
        $scope.ui.sliderMinOffsetPos = {
          "left": "" + scale($scope.ui.minute_offset, 0, 55, -10, 220) + "px"
        };

        function scale(num, in_min, in_max, out_min, out_max) {
          return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }

        //      ---     DEV            ----        //
        $scope.dev = {
          crontab: null,
          spillBeans: function () {
            if ($scope.dev.crontab)
              readCron($scope.dev.crontab, true);
            console.log("$scope", $scope);
          }
        };
      }

      function activate_manual() {
        $scope.crontab_manual = true;
        console.log("Crontab manual, Set interval init", $scope.ngDialogData);

        // Supports single and multi-devices call
        $scope.devices = [];

        if ($scope.ngDialogData.device)
          $scope.devices.push($scope.ngDialogData.device);

        for (let i in $scope.ngDialogData.devices) {
          $scope.devices.push($scope.ngDialogData.devices[i]);
        }

        //  GET current cronner configuration from the first device configs', which are stored in lastKnownMessages
        let _device = $scope.devices[0];

        let _configs = {};
        let lkm = $rootScope.lastKnownMessages[_device.uuid] || {};
        if (!_.isNil(lkm?.configs?.payload?.value)) {
          _configs = lkm.configs.payload.value;
        }

        $scope.data = {
          availableOptions: [
            {name: '1min', duration: 1, key: 'minutes', crontab: '0 * * * * *'},
            {name: '2min', duration: 2, key: 'minutes', crontab: '0 0/2 * * * *'},
            {name: '3min', duration: 3, key: 'minutes', crontab: '0 0/3 * * * *'},
            {name: '4min', duration: 4, key: 'minutes', crontab: '0 0/4 * * * *'},
            {name: '5min', duration: 5, key: 'minutes', crontab: '0 0/5 * * * *'},
            {name: '6min', duration: 6, key: 'minutes', crontab: '0 0/6 * * * *'},
            {name: '10min', duration: 10, key: 'minutes', crontab: '0 0/10 * * * *'},
            {name: '15min', duration: 15, key: 'minutes', crontab: '0 0/15 * * * *'},
            {name: '30min', duration: 30, key: 'minutes', crontab: '0 0/30 * * * *'},
            {name: '1h', duration: 1, key: 'hours', crontab: '0 0 * * * *'},
            {name: '2h', duration: 2, key: 'hours', crontab: '0 0 0/2 * * *'},
            {name: '4h', duration: 4, key: 'hours', crontab: '0 0 0/4 * * *'},
            {name: '6h', duration: 6, key: 'hours', crontab: '0 0 0/6 * * *'},
            {name: '8h', duration: 8, key: 'hours', crontab: '0 0 0/8 * * *'},
            {name: '12h', duration: 12, key: 'hours', crontab: '0 0 0/12 * * *'},
            {name: '24h', duration: 24, key: 'hours', crontab: '0 0 0 * * *'},
          ],
          selectedOption: null, //This sets the default value of the select in the ui
          selectedCrontab: '0 0 0/2 * * *', // Overriden by the default entry
          crontab: '0 0 0/2 * * *', // Overriden by the default entry
          time: null,
          iterations: 5,
          error: false
        };


        $scope.data.selectedOption = _.find($scope.data.availableOptions, {default: true});
        if ($scope.data.selectedOption) {
          $scope.data.crontab = $scope.data.selectedCrontab = $scope.data.selectedOption.crontab;
        }
        angular.forEach($scope.data.availableOptions, function (option) {
          // @ts-ignore
          option.humanDuration = $filter('amDurationFormat')(option.duration, option.key);
          option.millisDuration = moment.duration(option.duration, option.key)._milliseconds;
        });


        if ($rootScope.lastKnownMessages[_device.uuid]?.configs?.payload?.value?.crontab) {
          let crontab = $rootScope.lastKnownMessages[_device.uuid].configs.payload.value.crontab;
          console.log('Crontab, current', crontab);
          $scope.data.configsCrontab = crontab;
          $scope.data.crontab = crontab;
          let time = parserGetTime(crontab);
          if (time > 0) {
            _.forEach($scope.data.availableOptions, function (option) {
              if (option.millisDuration === time) {
                $scope.data.selectedOption = option;
                $scope.data.selectedCrontab = $scope.data.selectedOption.crontab;
                $scope.data.crontab = $scope.data.selectedCrontab;
                return false;
              }
            })
          }
        }

        parserTest();
        $scope.selectChange = function () {
          $scope.data.selectedCrontab = $scope.data.selectedOption.crontab;
          $scope.data.crontab = $scope.data.selectedCrontab;
          parserTest();
        };

        function parserTest(currentDate = new Date()) {
          $scope.parserCurrentDate = currentDate;
          $scope.parserNumTests = 2;
          $scope.parserTests = emptyOrCreateArray($scope.parserTests);
          $scope.parser = CronParser.parseExpression($scope.data.crontab, {currentDate: $scope.parserCurrentDate});
          for (var i = 0; i < $scope.parserNumTests; i++) {
            $scope.parserTests.push($scope.parser.next())
          }
        }

        function parserGetTime(crontab, currentDate = new Date()) {
          var parserCurrentDate = currentDate;
          var iterations = 2;
          var parserTests = [];
          var parser = null;
          try {
            parser = CronParser.parseExpression(crontab, {currentDate: parserCurrentDate});
          } catch (e) {
            if (WG_debug) console.warn('Failed to parse Crontab', crontab);
          }
          var time = 0;
          for (var i = 0; i < iterations; i++) {
            var next = parser.next();
            parserTests.push(next);
            if (i > 0) {
              time = next.getTime() - parserTests[i - 1].getTime();
            }
          }
          $scope.data.time = time;
          return time;
        }

        $scope.$watch('data.crontab', function (newCrontab: string, oldCrontab: string) {
          if (!angular.isDefined(newCrontab) || newCrontab === oldCrontab)// Initializing watcher
            return;
          var currentDate = new Date();
          var hasField = $('#id-crontab-manual').length;
          if (hasField) {
            try {
              var specificField = $('#id-crontab-manual').parsley();
              specificField.removeError('error-0');
              // window.ParsleyUI.removeError(specificField, 'error-0');
            } catch (e) {
            }
          }
          try {
            var parser = CronParser.parseExpression(newCrontab, {currentDate: currentDate});
            if (WG_debug) console.log("Crontab parser:", parser);
            $scope.data.error = false;
            $scope.data.selectedCrontab = newCrontab;
            parserTest(currentDate);
          } catch (err) {
            try {
              specificField.addError('error-0', {message: err.message});
              // window.ParsleyUI.addError(specificField, 'error-0', err.message);
            } catch (e) {
            }
            $scope.data.error = true;
            console.log('Error: ' + err.message);
          }
        });

        parentRet.loading = false;
        parentRet.result = "";
      }


      // Supports single and multi-devices call
      let devices = [];

      if ($scope.ngDialogData.device) {
        devices.push($scope.ngDialogData.device);
      }

      for (let i in $scope.ngDialogData.devices) {
        devices.push($scope.ngDialogData.devices[i]);
      }

      //  GET current cronner configuration from the first device configs', which are stored in lastKnownMessages
      let _device = WGApiData.WGDevices.devices_uuid[devices[0]?.uuid] || null;
      if (_device?.configs?.unit_view?.crontab_manual) {
        activate_manual();
      } else {
        activate_new();
      }
    }
  ])
  ;
}