// import {ICtrlScope, parseData} from "../utils/common";

/**
 * Created by pmec on 30/05/16.
 */

namespace wg {
  "use strict";

  declare var WG_debug: boolean;
  declare var WG_is_mar2protect: boolean;

  export interface IRootScope extends ng.IScope {
    WG_debug: boolean;
    WG_is_beta: boolean;
    WG_is_mar2protect: boolean;

    apiKey: string;
    base_url: string;
    // When the last data arrived from the server, to detect stalled info
    last_data_received: number;

    lastKnownMessages: { [device_uuid: string]: ILastKnownMessages };
    lastSensorValues: { [device_uuid: string]: ILastSensorValues };

    WGApiData: WGApiData;
    WGPlaces: WGApiData['WGPlaces'];
    // WGPlaces2: WGPlaces;
    WGUnits: IWGApiUnits;
    WGDevices: IWGApiDevices;
    WGSensors: IWGApiSensors;
    WGProcesses: IWGApiProcesses;
    WGBatches: IWGApiBatches;
    WGAlarms: IWGApiAlarms;

    cardview_sensors_list: string[];
    tableview_sensors_list: string[];

    mqtt_status?: string;
    mqtt_status_color?: 'lawngreen' | 'green' | 'blue' | 'red' | 'yellow' | 'grey';
    mqtt_status_extra?: string;
    mqtt_status_extra_2?: string;
  }

  // Sensor Parameter
  export interface ISensor {
    readonly id: number;
    url?: string;
    stream: string;
    internal_name: string;
    name?: string; // If it's a i18 structure, it is already translated here.
    name_sref?: string; // i18 name to be displayed
    configs?: ISensorConfigs;

    // icon?: any;
    // str_icon?: string; // when previous icon is not defined
    admin?: boolean; // If only admin can access this sensor

    unit?: string; // nice name to be displayed 'ºC'
    unit_sref?: string; // i18 unit name to be displayed
    unit_orig?: string; // before conversion
    unit_orig_sref?: string; // before conversion

    conversion?: ISensorConversion;
    // conversion_unit_name?: string; // name to be used on conversion query 'fahrenheit' 'inches'

    description?: string;
    created_at?: string;
    last_activity?: string;

    unit_type?: string;

    preferred?: boolean; // temporary flag to support ordering
  }

  export interface IGraphOptions {
    dataGrouping?: boolean;
    realtime?: boolean;

    dashStyle?: Highcharts.DashStyleValue;
    tooltip?: {
      pointFormat?: string;
    };
    minRange?: number;
    floor?: number;
    min?: number;
    max?: number;
    softMin?: number;
    softMax?: number;
  }

  export interface ISensorConfigs {
    masterSensor?: string;
    graph?: boolean;
    graph_type?: string;
    graph_options?: IGraphOptions;
    decimals?: number;
    decimals_orig?: number;

    query?: Object; // Used to get [timestamp,value] from /api/data/
    exportQuery?: Object; // Used by the Export Service
    sub_query?: Object; // Used to extract [value] from LKM

    manual?: boolean;
    accessLevel?: string;
    accessException?: string[]; // Array of user-UUIDs with access
    accessDeny?: string[]; // Array of user-UUIDs without access

    name_sref?: string; // i18 name to be displayed

    icon?: string;
    str_icon?: string; // If there's no icon, show this Text
    over_icon?: string; // Icon to show in superscript, over the main icon
    under_icon?: string; // Icon to show in subscript, under the main icon

    unit_sref?: string; // i18 unit to be displayed

    si_type?: string;
    conversions?: { [conversion_key: string]: ISensorConversion };
    convert_all_streams?: boolean;

    classes?: { [key: string]: ISensor };

    // Avoid using this "any-key" entries, to allow TS to detect unexpected keys
    // [key: string]: any;
  }

  export interface ISensorConversion {
    id: string;
    decimals?: number;
    unit: string;
    unit_sref?: string;

    graph_options?: IGraphOptions;

    minRange?: number;
    floor?: number;
    min?: number;
    max?: number;
    softMin?: number;
    softMax?: number;
  }

  export interface ISensorReading {
    sensor: ISensor; // TODO: Uncycle
    internal_name: string;
    timestamp: number;
    // age?: string;
    val_orig?: string | number; // Unconverted
    val: string | number; // Textual
    val_numeric: number; // Converted Numeric value
    color?: string;
    style?: object;
    status?: "OK" | "DISABLED" | "MISSED" | "WP_BOOTING" | "DENS_NO_LIQUID" | "DENS_HIGH_STD" | "FAIL" | "OLD";
    status_sref?: string;
    // Missed = This parameter missed last sample. FAIL = This parameter missing for 3 samples.

    deleted?: boolean; // Implements soft-deletion
  }

  export interface ILastKnownMessages {
    configs?: IDeviceLKMConfigs;
    value?: any;
    created_at?: Date;
    last_activity?: Date;
    first_timestamp?: Date;
    last_timestamp?: Date;

    [stream: string]: any | IDataResult;
  }

  export interface ILastSensorValues {
    [internal_name: string]: ISensorReading;
  }

  export interface IDevice {
    readonly id: number;
    root_device?: IDevice; // Legacy, migrating to new format
    status_update_timer?: angular.IPromise<any>;

    uuid: string;
    iid: string;
    sn: string;
    internal_name?: string;
    model: string;
    model_name: string;
    name?: string;
    // Used for Demos and "Manual Entries" only
    is_virtual?: boolean;
    selected?: boolean;

    icon?: string; // Used in Overview
    tech_icon?: string; // Used in technical_view
    type?: string; // Always equal to "device"
    path: string;
    version?: string;
    hw_version?: string;
    url?: string;
    description?: string;
    created_at?: string;
    last_activity?: string;
    unit_type?: string;

    // Local/Browser time of the last_comm, to estimate time-drift.
    received_time?: number;
    // ms between Smartbox and Browser. Positive = Browser in the future, Smartbox behind. Not compensated for MQTT delay
    time_drift?: number;

    // Smartbox-time timestamp of the last upstream comm
    last_comm?: number;
    // Smartbox-time timestamp of the last sensor measurement
    last_read?: number;

    // Browser-time Timestamp of the last command sent to the device
    last_command?: number;

    // Time between samples in ms, based on last sleep.real_interval, not on crontab
    sample_interval?: number;
    // 0 = "incoming"/"now", NaN = Hide
    // in ms, based on last sleep.real_interval, not on crontab
    next_read?: number;


    capabilities: IDeviceCapabilities;

    via?: string; // to deprecate
    via_mode?: string; // to deprecate
    comm_status: ICommStatus;

    management_active?: boolean;
    status: "ON" | "OFF" | "FAULT" | "DEACTIVATED";
    status_extra?: string;
    status_level_reading?: boolean;
    status_density_reading?: boolean;

    status_ota?: null | "PENDING" | "ONGOING";

    rotation_status?: {
      status?: "OK" | "WARN" | "ERROR",
      status_extra?: string,
      admin_status_extra?: string, // Can be present, even if status is "OK"
      angle?: number, // Angle to rotate symbol
    }

    configs?: IDeviceConfigs;
    users_count?: number;
    owner?: IUser;
    groups?: IGroup[];
    groups_count?: number;

    // Might not exist, e.g. for Smartbox
    unit?: IUnit; // TODO: Uncycle
    // lat?: number;
    // lon?: number;
    sensors?: ISensor[]; // TODO: Uncycle
    alarms?: IAlarm[];

    streams?: string[];
    last_known_message?: ILastKnownMessages;
    lkm?: ILastKnownMessages;

    last_values?: ILastSensorValues;
    last_card_values?: ILastSensorValues;
    cards_count: number;
  }

  // Device configurations from Django Back-end. Not updated via MQTT/Posts
  export interface IDeviceConfigs {
    graph_data_params?: IDataParams;
    // Legacy, to force a device to accept an rgb_alarm
    alarms_actions?: { neo_pixel_rgb: boolean };
    unit_view?: {
      disable_realtime: boolean;
      hide_sensors: string[];
      crontab_manual?: boolean;
    };
    can_force_read?: boolean;
    // legacy. Same as can_force_read
    force_read?: boolean;
    sn?: string;

    // Defines preferred comm mode, either WiFi or LoRa
    // mode?: ICommStatus['preferred_mode'];
    // wifi?: { // Defines default wifi network that sensors connect to
    //   MODE?: string,
    //   SSID?: string,
    //   PASS?: string
    // }
  }

  export interface IDeviceLKMConfigs {
    // Just to help IDE allow parsing of the LKM
    payload?: {value?: IDeviceLKMConfigs};

    // For smartboxes
    devices_sn?: string[], // List of paired devices
    devices_default_configs?: {
      wifi?: { // Defines default wifi network that sensors connect to
        SSID?: string,
        PASS?: string
      }
    },

    // For devices
    crontab?: string,
    ota?: number,
    // BitMask. ...001 = density disabled, ...010 = density disabled
    disable_functions?: number,

    mode?: ICommStatus['preferred_mode'];
    wifi?: { // Defines default wifi network that sensors connect to
      MODE?: string,
      SSID?: string,
      PASS?: string
    },

    // For density sensors
    // Number of reads when getting density
    ldensa_n_reads?: number,
    // Number of reads required to get density accurately. Defined during calibration
    ldensa_n_reads_enabled_value?: number,

    // For control boards
    ctrl_cycle_time?: number,
    ctrl_temp_mode?: number,
    ctrl_temp_hysteresis?: number,
    ctrl_temp_set_point?: number,
    ctrl_manual_output?: number,
  }

  export interface ICommStatus {
// #define MODE_ALL  "All"
// #define MODE_WIFI "WiFi"
// #define MODE_LORA_P2P "LoRa_P2P"
// #define MODE_LORA_WAN "LoRa_WAN"

    // configured lkm.MODE confirmed by the device
    preferred_mode?: null | "WiFi" | "LoRa" | "LoRa_P2P" | "LoRa_WAN" | "All";

    last_gw_id?: number;
    last_gw?: string; // legacy: via. e.g.: "wg-gw86"
    last_mode?: ICommStatus['last_wifi_mode'] | ICommStatus['last_lora_mode']; // legacy: via_mode, e.g.: "Smartbox Wi-Fi"
    last_description?: string;

    last_wifi_description?: string;
    last_wifi_timestamp?: number;
    last_wifi_mode?: "Wi-Fi" | "Smartbox Wi-Fi" | "Local Wi-Fi";
    last_wifi_ssid?: string;
    last_wifi_gw_id?: number;
    last_wifi_gw?: string;
    last_wifi_rssi?: number;
    last_wifi_snr?: number;
    last_wifi_quality?: number;
    last_wifi_color?: string; // CSS color

    last_lora_description?: string;
    last_lora_timestamp?: number;
    last_lora_mode?: null | "LoRa" | "LoRa_P2P" | "LoRa_WAN";
    last_lora_gw_id?: number; // gw_id of the last used gw
    last_lora_gw?: string; // Name of the last used gw
    last_lora_rssi?: number;
    last_lora_snr?: number;
    last_lora_quality?: number;
    last_lora_color?: string; // CSS color
  }

  export interface IDeviceCapabilities {
    force_read: boolean;
    config_density_read: boolean;
    control_board: boolean;
    fermentation_prediction: boolean;
    set_offsets: boolean;
    config_wifi: boolean;
  }

  export interface IPlace {
    readonly id: number;
    name?: string;
    name_sref?: string;
    type?: string; // Always equal to "place"
    description?: string;
    owner?: {
      id: number,
      username: string,
    };

    counts?: {
      devices: number,
      processes: number,
      alarms: number
    };
    last_values?: ILastSensorValues;

    units?: IUnit[]; // TODO: Uncycle
  }

  export interface IUnit {
    readonly id: number;
    name: string;
    name_sref?: string;
    type?: string; // Always equal to "unit"
    description?: string;

    unit_type: string;
    icon?: string;
    owner?: IUser;
    config_fields?: IUnitConfigs;

    lat?: number;
    lon?: number;

    priority?: number;
    alarms?: {
      //  Deprecated. user_rules are in device.alarms
      // user_rules?: object[],
      user_notifications?: object[],
      ai_notifications?: object[],
      status_notifications?: object[],
    };

    is_visible?: boolean; // False if filtered out of view
    selected?: boolean;

    last_values?: ILastSensorValues;

    devices: IDevice[]; // TODO: Uncycle
    process?: IProcess; // TODO: Uncycle
    place?: IPlace; // TODO: Uncycle
  }

  export interface IUnitConfigs {
    is_user_favorite?: object;
    conversions?: ISensorConfigs['conversions'];
  }

  export interface IProcess {
    id: number;
    name?: string;
    type?: string; // Always equal to "process"
    description?: string;

    active?: boolean;
    started_at?: string | Date;
    ended_at?: string | Date;
    duration?: number;

    process_type?: string;
    type_other?: string;

    dynamic_fields?: object;

    owner?: IUser;
    groups?: IGroup[];

    content_type?: string;
    batch?: IBatch;

    // Where the process is taking place
    unit?: IUnit;

    // History of units that have been part of this process
    units?: IUnit[];
  }

  export interface IBatch {
    readonly id: number;
    name?: string;
    type?: string; // Always equal to "batch"
    description?: string;

    processes?: IProcess[], // TODO: Uncycle
  }

  export interface IAlarm {
    readonly id: number,
    type?: string; // Always equal to "alarm"
    sensor: Partial<ISensor>,
    device?: Partial<IDevice>,
    owner?: IUser,

    rules: IAlarmRule[],

    active: boolean,
    name: string,
    rearm_interval: number,
    rearm_interval_option?: any,

    actions: {
      email: boolean,
      sms: boolean,
      neo_pixel_rgb?: boolean,
      neo_pixel_rgb_active?: object,
    };
  }

  export interface IAlarmRule {
    id: string,
    logical: string,
    expression?: string,
    value: number,
    value_conv?: number;
    selectedOption?: IAlarmRuleOptions,
  }

  export interface IAlarmRuleOptions {
    id: string,
    name?: string,
    name_sref?: string,
    symbol: string,
    logical: string,
  }

  // Local Browser Configs
  export interface IDashboardConfigs {
    overview_place?: string;
    overview_orderBy?: string;
    overview_viewType?: string;
    overview_sort?: string;
    overview_filter?: {
      filter?: string,
      searchText?: string,
      favorites?: boolean,
    }
    // [key: string]: any;
  }

  export interface _IState extends ng.ui.IStateService {
    params: {
      nest_by?: string,
      devices?: string[],
      device?: string,
      device_id?: number,
      device_sn?: string,
      device_uuid?: string,
      sensor?: string,
      sensors?: string[],
      parameter?: string,
      parameters?: string[],
      unit?: string,
      unit_id?: number,
      place?: string,
      place_id?: number,
      xAxisMin?: string,
      xAxisMax?: string,
    }
  }

  // export interface IGraphData {
  //   x?: number;
  //   y?: any;
  //
  //   [key: string]: any;
  // }

  export interface IDataResult {
    0?: any;
    x?: any;
    1?: any;
    y?: any;

    timestamp?: number;
    value?: any;
    payload?: any;

    deleted?: boolean;
    observation?: string;

    // [key: string]: any;
  }

  // export interface IDataResponse<T> {
  export interface IDataResponse {
    count: number;
    length?: number;
    next: string;
    previous: string;
    // results: T[];
    results: IDataResult[];

    // [key: string]: any;
  }

  export interface IDataPostResponse {
    detail: {
      rc: number;
      rc_str: string;
    }
    message: string;

    // [key: string]: any;
  }

  export interface IMultiSelect<T> {
    items: T[],
    all_items_are_selected: boolean,
    last_item_selected: T,
    last_single_item_selected: T,

    updateSelectedList(): void,

    reselectFromList(): void,

    selectAll(value?: boolean): void,

    select(item: T, index: number, e: JQueryEventObject): void,
  }

  export interface ILicense {
    readonly id?: number;

    device?: IDevice,
    distributor?: IDistributor,
    client_manager?: IUser,

    created_at?: Date
    license_start_date?: Date
    license_expiration_date?: Date
    client_invoice_id?: string
    client_invoice_date?: Date
    client_license_value?: number
    resale_invoice_id?: string
    resale_invoice_date?: Date
    resale_license_value?: number
    owned_by_distributor?: boolean
    notes?: string

    expired?: boolean
    renewable?: boolean
    selected?: boolean
  }

  export interface IDistributor extends IUser {
    readonly role?: "distributor";
  }


  export interface INestable {
    item: {
      text: string;
      admin?: boolean;
      dev?: string;

      id?: number;
      sn?: string;
      uuid?: string;
      device_name?: string;
      device?: Partial<IDevice>;

      sensor?: string;

      // [data: string]: any;

      // If the entry is selected on the list
      selected?: boolean;
      // CSS Style to be applied using ng-style
      style?: { [css_class: string]: any; };

      isGroup: boolean,
      children?: INestable[],
    },
    children: INestable[],
  }

  export interface IReturnResult {
    // Is it loading
    loading: boolean,
    // Which ids are loading
    loading_ids?: {},
    // Used by SweetAlert Loader
    result: "" | "success" | "error",
    // Error message, when something goes wrong
    message?: string,
  }
}