/**
 * Created by daniel on 06-10-2016.
 */
namespace wg {
  App.controller("DevicesCompareController", ['$rootScope', '$scope', '$translate', '$state', 'DataUtils', 'WGApiData', 'AuthService',
    function ($rootScope: IRootScope, $scope, $translate: ng.translate.ITranslateService, $state: _IState, DataUtils: DataUtils, WGApiData: WGApiData, AuthService: IAuthService) {
      $scope.reload = function () {
        // console.log('DevicesCompareController, reload');
        $state.go('app.devices.compare', $state.params, {reload: 'app.devices.compare'});
      };

      $scope.devices_uuid = {} as {
        [uuid: string]: { id: number, uuid: string, streams: string[] }
      };
      $scope.hasSelectedItems = false;

      // Probably unused from now on
      $scope.selected_devices = [];
      $scope.selected_sensor = null;
      $scope.selected_parameter = null;

      $scope.showInformation = true;

      $scope.selectedUnit = null;
      $scope.hasSelectedUnit = false;
      $scope.hasSelectedUnitGraph = false;


      // Define functions

      function translate(obj) {
        return obj[$translate.use()] || obj['en-GB'] || obj;
      }


      // wg-Nestable stuff

      $scope.nest_options = {
        initState: null, //"collapsed", // "collapsed", "expanded", null - which is based on expand:true data
        // initState: (AuthService.canAccess('admin') ? "collapsed" : "expanded"), // "collapsed" or "expanded"
        // TODO: Find a way to expand selected sensors only
      }

      $scope.nest_items = [] as INestable[]; // Always a pointer/copy to one of the following


      // let nest_items_byGroup: INestable[]; // Deprecated
      let nest_items_byParameter: INestable[];
      let nest_items_byDevice: INestable[];
      let nest_items_byUnit: INestable[];
      let nest_items_byPlace: INestable[];

      $scope.orderBy = 'unit';
      $scope.changeOrderBy = function (order) {
        console.log("DevicesCompareController, order by", order);
        $scope.clearSelectedSensors();
        // if (order === 'group') {
        //   $scope.orderBy = 'group';
        //   $scope.nest_items = nest_items_byGroup;
        // } else
        if (order === 'parameter') {
          $scope.orderBy = 'parameter';
          $scope.nest_items = nest_items_byParameter;
        } else if (order === 'place') {
          $scope.orderBy = 'place';
          $scope.nest_items = nest_items_byPlace;
        } else if (order === 'device') {
          $scope.orderBy = 'device';
          $scope.nest_items = nest_items_byDevice;
        } else {
          $scope.orderBy = 'unit';
          $scope.nest_items = nest_items_byUnit;
        }

        // console.log($scope.nest_items);
        AuthService.update_url({nest_by: $scope.orderBy}, false, false, true);
        // console.log("DevicesCompareController, Ordered", $scope.orderBy);
      };

      $scope.selectAllChildren = function (_entry: INestable) {
        // console.log("DevicesCompareController, selectAllChildren", _entry);
        $scope.hasSelectedUnit = false;
        $scope.hasSelectedItems = false;
        $scope.hasSelectedUnitGraph = false;

        _entry.item.selected = true;

        if (_entry.item.uuid && _entry.item.sensor) {
          $scope.addToGraph(_entry.item.uuid, _entry.item.sensor);
        }

        for (let _child of _entry.children) {
          $scope.selectAllChildren(_child);
        }
      };

      $scope.selectItem = function (_item: INestable['item']) {
        // console.log("DevicesCompareController, selectItemt", _item);
        $scope.hasSelectedItems = true;
        $scope.hasSelectedUnitGraph = false;

        _item.selected = true;
        if (_item.uuid && _item.sensor)
          $scope.addToGraph(_item.uuid, _item.sensor);
      };

      $scope.unselectItem = function (_item: INestable['item']) {
        // console.log("sensorUnit", itemx);
        // $scope.hasSelectedItems = false;
        // $scope.hasSelectedUnitGraph = false;

        _item.selected = false;

        if (_item.uuid && _item.sensor) {
          // $scope.hideFromGraph(_entry.uuid, _entry.sensor, function (visible) {
          //   console.log('onToggleCb', visible)
          // });
          $scope.removeFromGraph(_item.uuid, _item.sensor);
        }
      };

      // $scope.expandTo = function(event=null) {
      //   return this.each(function () {
      //     var $parents = $(this).parents();
      //     $parents.each(function (i) {
      //       var list = $(this).data("nestable");
      //       if (list) {
      //         $parents.slice(0, i).filter(list.options.itemNodeName).each(function(){
      //           list.expandItem($(this));
      //         });
      //         return false;
      //       }
      //     });
      //   });
      // };

      $scope.toggleItem = function (_item: INestable['item'], event = null) {
        if (_item.isGroup) {
          // @ts-ignore
          let list = $('.dd')?.nestable({})?.data("nestable");
          let element = $(event.target)?.closest('.dd-item');
          if (element?.hasClass('dd-collapsed')) {
            list?.expandItem?.(element);
          } else {
            list?.collapseItem?.(element);
          }

          return;
        } else {
          if (!_item.selected) {
            $scope.selectItem(_item);
          } else {
            $scope.unselectItem(_item);
          }
        }
      };

      $scope.selectItems = function (_device_ids: number[], _parameters: string[]) {

        let search_and_select_items = function (_entries: INestable[], _device_ids: number[], _parameters: string[]) {
          for (let _entry of _entries) {
            if (_entry.item.uuid && _entry.item.sensor) {
              let _id = WGApiData.WGDevices.devices_uuid[_entry.item.uuid]?.id;
              let _param = WGApiData.WGSensors.sensors_name[_entry.item.sensor]?.internal_name;

              if (_id && _.includes(_device_ids, _id) &&
                  _param && _.includes(_parameters, _param)) {
                $scope.selectItem(_entry.item);
              }
            }

            if (!_.isEmpty(_entry.children)) {
              search_and_select_items(_entry.children, _device_ids, _parameters);
            }
          }
        }
        search_and_select_items($scope.nest_items, _device_ids, _parameters);
      }

      $scope.clearSelectedSensors = function () {
        if ($scope.hasSelectedUnitGraph) {
          // console.log("Graph-init");
          $scope.graphCtrl?.init();
        }
        $scope.hasSelectedItems = false;
        $scope.hasSelectedUnitGraph = false;

        let clear_selected_items = function (_nest_items: INestable[]) {
          for (let _entry of _nest_items) {
            if (_entry.item) {
              _entry.item.selected = false;
            }
            if (_entry.children) {
              // console.log("clear_selected_items, children");
              clear_selected_items(_entry.children);
            }
          }
        }
        // console.log("clear_selected_items, all", $scope.nest_items);
        clear_selected_items($scope.nest_items);
      };

      // Graph stuff

      $scope.addToGraph = (device_uuid, internal_name) => {
        // console.log(device, sensor, $scope.devices_uuid[device], global_sensors[sensor]);
        $scope.hasSelectedUnitGraph = true;
        $scope.graphCtrl?.getSensor(WGApiData.WGDevices.devices_uuid[device_uuid], WGApiData.WGSensors.sensors_name[internal_name]);
      };

      $scope.removeFromGraph = function (device, internal_name) {
        $scope.graphCtrl?.removeSensor($scope.devices_uuid[device], WGApiData.WGSensors.sensors_name[internal_name]);
      };

      $scope.hideFromGraph = function (device, internal_name, onToggleCb) {
        $scope.graphCtrl?.hideSensor($scope.devices_uuid[device], WGApiData.WGSensors.sensors_name[internal_name], onToggleCb);
      };


      // Init

      let init = function () {

        console.log("DevicesCompareController, building Nestables");
        _.forEach(WGApiData.WGDevices.devices, function (_device) {

          $scope.devices_uuid[_device.uuid] = {
            id: _device.id,
            name: _device.name,
            internal_name: _device.internal_name,
            uuid: _device.uuid,
            iid: _device.iid,
            path: _device.path,
            description: _device.description,
            configs: _device.configs,
            group: _device.groups.length === 1 ? _device.groups[0].name : '',
            unit_type: _device.unit_type,
            streams: _device.streams,


            isChecked: false,
          };
        });

        // Build nest_items_byParameter
        {
          let nest_items_groups_byParameter: { [internal_name: string]: INestable } = {};
          let nest_items_groups_byDevice: { [id: number]: INestable } = {};
          let nest_items_groups_byUnit: { [id: number]: INestable } = {};
          let nest_items_groups_byPlace: { [id: number]: INestable } = {};

          _.forEach(WGApiData.WGDevices.devices, function (device) {
            // if (SMARTBOX_MODELS.includes(device.model?.toUpperCase())) {
            //   // Ignore SmartBoxes ?
            //   return;
            // }

            _.forEach(device.last_values, (_last_values, internal_name) => {
              if (!internal_name || !_last_values) {
                return;
              }
              if (!DataUtils.canUserAccessSensor(internal_name)) {
                return;
              }
              let sensor = WGApiData.WGSensors.sensors_name[internal_name];
              if (!sensor || !sensor.configs || !sensor.configs.graph) {
                return;
              }


              // by Parameter
              let item_deviceInfo: INestable = {
                item: {
                  text: device.name + (device.name != device.sn ? " - sn:" + device.sn : ""),
                  uuid: device.uuid,
                  sensor: sensor.internal_name,
                  isGroup: false,
                  children: [],
                },
                children: [],
              };
              item_deviceInfo.item.children = item_deviceInfo.children;

              if (device.name != device.sn
                  && !SMARTBOX_MODELS.includes(device.model?.toUpperCase())) {
                item_deviceInfo.item.sn = device.sn;
              }
              let _group_byParameter = nest_items_groups_byParameter[sensor.internal_name];
              // Generate missing Group
              if (_group_byParameter) {
                _group_byParameter.children.push(item_deviceInfo);
              } else {
                _group_byParameter = {
                  item: {
                    // expand: true,
                    text: (sensor.configs.name_sref ? $translate.instant(sensor.configs.name_sref) : translate(sensor.name)) || sensor.internal_name,
                    sensor: sensor.internal_name,
                    admin: (sensor.configs.accessLevel === 'admin'),
                    isGroup: true,
                  },
                  children: [item_deviceInfo],
                };
                _group_byParameter.item.children = _group_byParameter.children;
                nest_items_groups_byParameter[sensor.internal_name] = _group_byParameter;
              }


              let item_parameterInfo: INestable = {
                item: {
                  text: (sensor.configs.name_sref ? $translate.instant(sensor.configs.name_sref) : translate(sensor.name)) || sensor.internal_name,
                  uuid: device.uuid,
                  sensor: sensor.internal_name,
                  admin: (sensor.configs.accessLevel === 'admin'),
                  isGroup: false,
                },
                children: []
              };
              item_parameterInfo.item.children = item_parameterInfo.children;

              // byDevice
              let _group_byDevice = nest_items_groups_byDevice[device.id];
              if (_group_byDevice) {
                _group_byDevice.children.push(item_parameterInfo);
              } else {
                _group_byDevice = {
                  item: {
                    text: device.name,
                    // uuid: device.uuid,
                    id: device.id,
                    // device: device,
                    isGroup: true,
                  },
                  children: [item_parameterInfo],
                };
                _group_byDevice.item.children = _group_byDevice.children;

                if (device.name != device.sn
                    && !SMARTBOX_MODELS.includes(device.model?.toUpperCase())) {
                  _group_byDevice.item.sn = device.sn;
                }
                nest_items_groups_byDevice[device.id] = _group_byDevice;
              }


              // byUnit
              let _unit = WGApiData.WGUnits.units_id[device.unit?.id];
              if (!_unit) {
                _unit = {
                  id: -device.id,
                  name: device.name,
                  owner: device.owner,
                } as IUnit;
              }
              let _group_byUnit = nest_items_groups_byUnit[_unit.id];
              // Generate missing Group
              if (_group_byUnit) {
                if (!_.find(_group_byUnit.children, {item: {id: item_parameterInfo.item.id}})) {
                  _group_byUnit.children.push(item_parameterInfo);
                }
              } else {
                _group_byUnit = {
                  item: {
                    text: _unit.name,
                    id: _unit.id,
                    isGroup: true,
                  },
                  children: [item_parameterInfo],
                };
                _group_byUnit.item.children = _group_byUnit.children;
                if (device.name != _unit.name) {
                  _group_byUnit.item.device_name = device.name;
                }
                if (device.name != device.sn
                    && !SMARTBOX_MODELS.includes(device.model?.toUpperCase())) {
                  _group_byUnit.item.sn = device.sn;
                }
                nest_items_groups_byUnit[_unit.id] = _group_byUnit;


                // byPlace
                let _place = WGApiData.WGPlaces.places_id[_unit.place?.id];
                if (!_place) {
                  _place = {
                    id: -_unit.owner?.id || -device.owner?.id || -1,
                    name: $translate.instant("app.overview.UNPLACED_UNITS_NAME"),
                    owner: _unit.owner || device.owner,
                  } as IPlace;
                }
                let _group_byPlace = nest_items_groups_byPlace[_place.id];
                // Generate missing Group
                if (_group_byPlace) {
                  if (!_.find(_group_byPlace.children, {item: {id: _group_byUnit.item.id}})) {
                    _group_byPlace.children.push(_group_byUnit);
                  }
                } else {
                  _group_byPlace = {
                    item: {
                      text: _place.name,
                      // text: _place.name + (_place.owner?.username ? " - " + _place.owner.username : ""),
                      // id: _place.id,
                      // owner: _place.owner,
                      isGroup: true,
                    },
                    children: [_group_byUnit],
                  };
                  _group_byPlace.item.children = _group_byPlace.children;
                  if (AuthService.view_as_owner.id < 0 && _place.owner?.username) {
                    _group_byPlace.item.text += " - " + _place.owner.username;
                  }

                  nest_items_groups_byPlace[_place.id] = _group_byPlace;
                }
              }

            })
          })

          // ####  Add Groups with some children to the main structures

          nest_items_byParameter = _.values(nest_items_groups_byParameter);

          nest_items_byDevice = _.values(nest_items_groups_byDevice);
          nest_items_byUnit = _.values(nest_items_groups_byUnit);
          nest_items_byPlace = _.values(nest_items_groups_byPlace);
        }


        // Get and Set desired state
        $scope.changeOrderBy($state.params?.nest_by);

        // Select sensors (devices) passed by params
        {
          let unWatch = $scope.$watch('graphCtrl', function (graphCtrl) {
            if (!graphCtrl)
              return;
            unWatch();
            // After the modal opens, add the selected devices and parameters to the graph

            let _state_params = _.cloneDeep($state.params) || {};


            let _state_devices: number[] = []; // devices to show

            if (_.isArray(_state_params.devices)) {
              for (let id of _state_params.devices) {
                let _id = Number(id);
                if (_.isFinite(_id)) {
                  _state_devices.push(_id);
                }
              }
            }
            if (_.isFinite(Number(_state_params.devices))) { // Single id passed.
              _state_devices.push(Number(_state_params.devices));
            }

            // device id passed
            if (_state_params.device && _.isFinite(Number(_state_params.device))) {
              _state_devices.push(Number(_state_params.device));
            }

            // device uuid passed
            if (_state_params.device_uuid && _.isFinite(Number(_state_params.device_uuid))) {
              let _id = WGApiData.WGDevices.devices_uuid[Number(_state_params.device)]?.id;
              if (_id) {
                _state_devices.push(_id);
              }
            }

            let _parameters = _.clone(_state_params.parameters) || [];

            if (_state_params.parameter && WGApiData.WGSensors.sensors_name[_state_params.parameter]?.internal_name) {
              _parameters.push(WGApiData.WGSensors.sensors_name[_state_params.parameter].internal_name)
            }

            if (_.isEmpty(_parameters)) {
              _parameters = _.clone($rootScope.cardview_sensors_list);
            }

            if (!_.isEmpty(_state_devices) && !_.isEmpty(_parameters)) {
              _state_devices = _.uniq(_state_devices);
              _parameters = _.uniq(_parameters);

              console.log("DevicesCompareController Selecting devices passed by params:", _state_devices);
              console.log("DevicesCompareController Searching in:", $scope.nest_items);
              $scope.selectItems(_state_devices, _parameters);
            }

            for (let i = 1; i < 19; i++) {
              if (_state_params['device' + i] && _state_params['param' + i]
                  && WGApiData.WGDevices.devices_uuid?.[_state_params['device' + i]]?.id)
                $scope.selectItems([WGApiData.WGDevices.devices_uuid[_state_params['device' + i]].id], [_state_params['param' + i]]);
            }
          });
        }
      }

      let unhook_init = $rootScope.$watchGroup(['WGPlaces.loading', 'WGUnits.loading', 'WGDevices.loading', 'WGSensors.loading'], function () {
        if (!WGApiData.WGPlaces.ready ||
            !WGApiData.WGUnits.ready ||
            !WGApiData.WGDevices.ready ||
            !WGApiData.WGSensors.ready) {
          return;
        }
        unhook_init();
        console.log("DevicesCompareController, All data ready. Init");
        init();
      });

    }
  ])
  ;
}