namespace wg {

  export class ClientManagerPicker {

    // an array referencing parent's client_managers, that should be set when the user selects a client manager
    public clientManagerBinding: IUser[];
    // the currently selected client manager that the dropdown shows
    public selectedClientManager: IUser;
    // the currently selected distributor, where we will get the client managers from
    public distributor: IDistributor;

    public onChangeCallback: Function;

    public clientManagers: IUser[];
    public distributorsRawData: IUser[];

    static $inject = ['$http', '$scope'];

    public loading: boolean = true;

    constructor(private $http: ng.IHttpService, private $scope: ng.IScope) {
    }

    $onInit() {
      // if(WG_debug) console.log("WG_ClientManagerPicker OnInit");

      if (!this.distributorsRawData) { //if the parent component didn't pass the data, we get it ourselves
        this.$http.get<IDataResponse>('api/dashboard/distributors/').then((response) => {
          this.distributorsRawData = response.data.results as IUser[];

          this.onSelectedDistributorChange();
          this.loading = false;
        });
      }

      this.$scope.$watch('ctrl.distributor', (newVal: IUser) => {
        // if(WG_debug) console.log("WG_ClientManagerPicker Watch");
        if (newVal) {
          this.onSelectedDistributorChange();
        }
      });
    }

    public getRelationsOfUser(wanted_distributor: IUser, distributors_list?: IUser[]): IUser[] {
      // if(WG_debug) console.log("WG_ClientManagerPicker getRelationsOfUser");

      if (!wanted_distributor) return [];

      if (!distributors_list) return [];

      const distributorWeWant = _.find(distributors_list, {id: wanted_distributor.id});
      if (!distributorWeWant) return [];

      const userRelations = distributorWeWant.permitted_users_relation;
      if (!userRelations) return [];

      return userRelations.map(
          (relation) => {
            return {
              username: relation.user.username,
              id: relation.user.id
            }
          })


    }

    public onSelectedDistributorChange() {
      if (this.distributor) {
        this.clientManagers = this.getRelationsOfUser(this.distributor, this.distributorsRawData);
      }
      if (_.isEmpty(this.distributor) || _.isEmpty(this.clientManagers)) return;

      _.forEach(this.clientManagerBinding, (manager) => {
        // find the clientManager with the same id
        let _manager = _.find(this.clientManagers, {id: manager.id}) || this.clientManagers?.[0];
        if (_manager) {
          manager.id = _manager.id;
          manager.username = _manager.username;
        }
      })

      this.selectedClientManager = _.find(this.clientManagers, {id: this.selectedClientManager?.id}) || this.clientManagers?.[0];
    }

    public onClientManagerChange(user: IUser) {
      // if(WG_debug) console.log("WG_ClientManagerPicker onClientManagerChange");

      if (this.onChangeCallback) {
        this.onChangeCallback({user: user});

      } else {
        this.selectedClientManager = user;

        this.clientManagerBinding.forEach((manager) => {
          manager.id = user.id;
          manager.username = user.username;
        });

      }
    }

  }

  App.component('wgClientManagerPicker', {
    controller: ClientManagerPicker,
    templateUrl: "app/views/directives/wg-client-manager-picker.html",
    controllerAs: 'ctrl',
    bindings: {
      clientManagerBinding: '=',
      distributor: '=',
      selectedClientManager: '=',
      onChangeCallback: '&'
    }
  });
}

