/**=========================================================
 * Module: admin-devices.js
 * Devices management
 =========================================================*/
namespace wg {
  App.controller('AdminDevicesController', ['$rootScope', '$scope', '$http', '$filter', '$q', '$timeout', 'ngTableParams', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $filter: ng.IFilterService, $q, $timeout: ng.ITimeoutService, ngTableParams: NgTable.ITableParamsConstructor<IDevice>, ngDialog: angular.dialog.IDialogService) {
      $scope.devices = [];
      $scope.showingFrom = 0;
      $scope.showingTo = 0;
      $scope.search = '';
      $scope.loading = false;
      $scope.filterUsers = [{'id': '', 'title': ''}];
      $scope.editUsers = [];
      $scope.usersNames = [];
      $scope.filterGroups = [{'id': '', 'title': ''}];
      $scope.editGroups = [];

      $scope.getUsers = function () {
        $http.get('api/auth/users/all/', {}).then(
            function (response: ng.IHttpPromiseCallbackArg<IUser[]>) {
              var data = response.data;
              $scope.editUsers = data;
              $scope.usersNames = _.map(data, 'username');
              $scope.filterUsers.length = 0;
              $scope.filterUsers.push({'id': '', 'title': ''});
              angular.forEach(data, function (user) {
                $scope.filterUsers.push({'id': user.username, 'title': user.username});
              });
            }, function (response) {
              console.error(response);
            });
      };

      $scope.getGroups = function () {
        $http.get('api/auth/groups/all/', {}).then(
            function (response) {
              var data = response.data;
              $scope.editGroups = data;
              $scope.filterGroups.length = 0;
              $scope.filterGroups.push({'id': '', 'title': ''});
              angular.forEach(data, function (group) {
                $scope.filterGroups.push({'id': group.name, 'title': group.name});
              });
            }, function (response) {
              console.error(response);
            });
      };

      $scope.updateTotals = function (total) {
        // if(WG_debug) console.log('updateTotals', total);
        $scope.tableParams.total(total);
        $scope.showingFrom = ($scope.tableParams.page() - 1) * $scope.tableParams.count() + 1;
        if ($scope.showingFrom > total)
          $scope.showingFrom = total;
        $scope.showingTo = $scope.showingFrom - 1 + $scope.tableParams.count();
        if ($scope.showingTo > total)
          $scope.showingTo = total;
      };

      $scope.tableParams = new ngTableParams({
        page: 1,            // show first page
        count: 10,          // count per page
        sorting: {
          name: 'asc'       // initial sorting
        },
        filter: {}
      }, {
        total: 0, // length of data
        getData: (params) => {
          $scope.loading = true;
          var search = $scope.search;
          var filter = '';
          var obj = params.filter();
          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (angular.isDefined(obj[key]) && obj[key] !== '')
                filter += key + '=' + obj[key] + '&';
            }
          }
          var query = '';
          if (params.orderBy() + '' !== '') {
            query += '&ordering=' + params.orderBy();
          }
          if (angular.isDefined(search) && search !== '') {
            query += '&search=' + search;
          } else {
            query += '&page=' + params.page();
          }
          if (filter !== '') {
            filter = filter.substring(0, filter.length - 1);
            query += '&' + filter;
          }

          return $http.get('api/dashboard/devices/?page_size=' + params.count() + query, {}).then(
              (response: ng.IHttpPromiseCallbackArg<IDataResponse>) => {
                $scope.devices = response.data.results;
                //angular.forEach(data, function(group) {
                //  var obj = _.find($scope.groups, {id: group.id});
                //  if (angular.isDefined(obj)) {
                //    angular.extend(obj, group);
                //    $scope.checkboxes.items[group.id] = false;
                //  } else {
                //    $scope.groups.push(group);
                //  }
                //});
                // update table params
                $scope.loading = false;
                $scope.updateTotals(response.data.count);
                return $scope.devices;
              }, function (response) {
                $scope.loading = false;
                console.error(response);
              });
          $scope.getUsers();
          $scope.getGroups();
        }
      });

      $scope.reload = function () {
        $rootScope.$broadcast('adminReload');
      };

      $scope.$on('adminReload', function (event, args) {
        //console.log('adminReload', 'admin-devices');
        $scope.tableParams.reload();
      });

      $scope.newDevice = function () {
        var dialog = ngDialog.open({
          template: 'adminDeviceNewDialog',
          data: {}
        });
        dialog.closePromise.then(
            function (dialogData) {
              $scope.reload();
            });
      };

      $scope.edit = function (device) {
        device.editGroups = objArrayDiff(device.groups, $scope.editGroups, 'id');
        device.groupsNames = _.map(device.editGroups, 'name');
        device.editUsers = objArrayDiff(device.users, $scope.editUsers, 'id');
        device.usersNames = _.map(device.editUsers, 'username');
        device.toAddOwnerName = device.owner.username;
        device.$edit = true;
      };

      $scope.save = function (device) {
        if (angular.isDefined(device.toAddOwnerName)) {
          var obj = _.find($scope.editUsers, {username: device.toAddOwnerName});
          device.owner = obj
        }
        $rootScope.WGDevices.changed = true;
        $http.patch('api/dashboard/devices/' + device.id + '/', device).then(
            function (response: ng.IHttpPromiseCallbackArg<IDevice>) {
              $rootScope.WGDevices.changed = true;
              device.$edit = false;
              //angular.extend(device, response.data);
              //angular.extend(device.owner, response.data.owner);
              //device = response.data;
              $scope.reload();
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  // var specificField = $('#id-' + key + '-' + group.id).parsley();
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };

      $scope.delete = function (device) {
        console.error("Forbidden action");
        return;
        // $rootScope.WGDevices.changed = true;
        // $http.delete('api/dashboard/devices/' + device.id + '/', device).then(
        //     function (response) {
        //       $rootScope.WGDevices.changed = true;
        //       device.$edit = false;
        //       obj = _.find($scope.devices, {id: device.id});
        //       if (angular.isDefined(obj)) {
        //         $scope.devices.splice(_.indexOf($scope.devices, obj), 1);
        //         $scope.checkboxes.items[device.id] = false;
        //         $scope.updateTotals($scope.tableParams.total() - 1);
        //         if ($scope.devices.length === 0 ) {
        //           $scope.reload();
        //         }
        //       }
        //     }, function (response) {
        //       console.error(response);
        //     });
      };


      $scope.addUser = function (device) {
        if (angular.isDefined(device['toAddUserName']))
          device.toAddUser = _.find(device.editUsers, {username: device['toAddUserName']});
        if (!angular.isDefined(device.toAddUser))
          return;
        var toAdd = device.toAddUser;
        angular.extend(toAdd, {pending: true, added: false});
        $rootScope.WGDevices.changed = true;
        $http.post('api/dashboard/devices/' + device.id + '/users/', {username: toAdd.username}).then(
            function (response) {
              $rootScope.WGDevices.changed = true;
              //user.$edit = false;
              if (response.status === 201) {
                toAdd.pending = false;
                toAdd.added = true;
                device.editUsers = removeFromArray(device.editUsers, toAdd, 'id');
                device.usersNames = _.map(device.editUsers, 'username');
              }
            }, function (response) {
              console.error(response);
            });
        device.users.push(toAdd);
      };

      $scope.removeUser = function (device, user) {
        if (!angular.isDefined(user))
          return;
        var toRemove = user;
        var obj = {};
        angular.copy(toRemove, obj);
        angular.extend(toRemove, {pending: true, removed: false});
        $rootScope.WGDevices.changed = true;
        $http.delete('api/dashboard/devices/' + device.id + '/users/' + toRemove.id + '/').then(
            function (response) {
              $rootScope.WGDevices.changed = true;
              if (response.status === 204) {
                toRemove.pending = false;
                toRemove.removed = true;
                device.editUsers.push(obj);
                device.usersNames = _.map(device.editUsers, 'username');
              }
            }, function (response) {
              console.error(response);
            });
      };

      $scope.addGroup = function (device) {
        if (angular.isDefined(device['toAddGroupName']))
          device.toAddGroup = _.find(device.editGroups, {name: device['toAddGroupName']});
        if (!angular.isDefined(device.toAddGroup))
          return;
        var toAdd = device.toAddGroup;
        angular.extend(toAdd, {pending: true, added: false});
        $rootScope.WGDevices.changed = true;
        $http.post('api/dashboard/devices/' + device.id + '/groups/', {name: toAdd.name}).then(
            function (response) {
              $rootScope.WGDevices.changed = true;
              //user.$edit = false;
              if (response.status === 201) {
                toAdd.pending = false;
                toAdd.added = true;
                device.editGroups = removeFromArray(device.editGroups, toAdd, 'id');
                device.goupsNames = _.map(device.editGroups, 'name');
              }
            }, function (response) {
              console.error(response);
            });
        device.groups.push(toAdd);
      };

      $scope.removeGroup = function (device, group) {
        if (!angular.isDefined(group))
          return;
        var toRemove = group;
        var obj = {};
        angular.copy(toRemove, obj);
        angular.extend(toRemove, {pending: true, removed: false});
        $rootScope.WGDevices.changed = true;
        $http.delete('api/dashboard/devices/' + device.id + '/groups/' + toRemove.id + '/').then(
            function (response) {
              $rootScope.WGDevices.changed = true;
              if (response.status === 204) {
                toRemove.pending = false;
                toRemove.removed = true;
                device.editGroups.push(obj);
                device.goupsNames = _.map(device.editGroups, 'name');
              }
            }, function (response) {
              console.error(response);
            });
      };


      // watch for new search value after 750 ms, so that the table can be reloaded
      // var promiseTimeoutSearch;
      $scope.$watch('search', function (value) {
        // $timeout.cancel(promiseTimeoutSearch);
        // promiseTimeoutSearch = $timeout(function () {
        $scope.tableParams.reload();
        // }, 750);
      });

      // watch if table is loading, if so only show loading animations if it takes more than 1000 ms
      // var promiseTimeoutLoading;
      // $scope.$watch('tableParams.settings().$loading', function (value) {
      //   $timeout.cancel(promiseTimeoutLoading);
      //   if (value) {
      //     promiseTimeoutLoading = $timeout(function () {
      //       $scope.loading = true;
      //     }, 1000);
      //   } else {
      //     $scope.loading = false;
      //   }
      // });
    }]);

  App.controller('AdminDevicesNewController', ['$rootScope', '$scope', '$http', 'ngDialog',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, ngDialog: ng.dialog.IDialogService) {
      $scope.data = {};
      $scope.users = [];
      $scope.usersNames = [];
      $scope.getUsers = function () {
        $http.get('api/auth/users/all/', {}).then(
            function (response: ng.IHttpPromiseCallbackArg<IUser[]>) {
              var data = response.data;
              $scope.users = data;
              $scope.usersNames = _.map($scope.users, 'username');
            }, function (response) {
              console.error(response);
            });
      };
      $scope.getUsers();
      $scope.save = function (data) {
        $rootScope.WGDevices.changed = true;
        $http.post('api/dashboard/devices/', $scope.data).then(
            function (response: ng.IHttpPromiseCallbackArg<IDevice[]>) {
              $rootScope.WGDevices.changed = true;
              if (response.status === 201) {
                // @ts-ignore
                ngDialog.close();
              }
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });

                }
              }
            });
      };
    }]);
}