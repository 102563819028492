/**=========================================================
 * Module: data-utils.js
 * Utility library to perform data operations across the dashboard
 =========================================================*/
namespace wg {

  export class APIUtils {
    static $inject = ['$rootScope', 'AuthService', 'WGApiData', '$http', '$state', '$q'];

    constructor(private $rootScope: IRootScope,
                private AuthService: IAuthService,
                private WGApiData: WGApiData,
                private $http: ng.IHttpService,
                private $state: _IState,
                private $q: ng.IQService) {
    }

    setUnitsAsUserFavorite(units: IUnit[], favorite = true): void {
      angular.forEach(units, (unit) => {
        if (!unit.id || unit.id <= 0) {
          return;
        }

        if (!unit.config_fields) {
          unit.config_fields = {};
        }
        if (!unit.config_fields.is_user_favorite) {
          unit.config_fields.is_user_favorite = {};
        }

        unit.config_fields.is_user_favorite[this.AuthService.user.uuid] = favorite;

        this.$http.patch(`api/dashboard/units/${unit.id}/`, {'config_fields': unit.config_fields}).then(
            (response: ng.IHttpPromiseCallbackArg<IUnit>) => {
              console.log("Favorite Unit change, Ok", response);
              this.$rootScope.WGUnits.merge_entry(response.data);
              this.$rootScope.WGUnits.process_data();
            },
            (response) => {
              console.log("Favorite Unit change, Fail! Setting back", response);
              unit.config_fields.is_user_favorite[this.AuthService.user.uuid] = !favorite;
            }
        );
      });
    }

    setUnitAtDevice(device_id: number, unit_id: number, returnRef?: IReturnResult, onFulfilled?: (any) => void, onRejected?: (any) => void): void {
      if (!device_id) {
        console.log("Missing Device ID");
        if (returnRef) {
          returnRef.loading = false;
          returnRef.result = 'error';
        }
        if (onRejected) {
          onRejected("Missing Device ID");
        }
        return;
      }
      if (!unit_id) {
        console.log("Missing Unit ID");
        if (returnRef) {
          returnRef.loading = false;
          returnRef.result = 'error';
        }
        if (onRejected) {
          onRejected("Missing Unit ID");
        }
        return;
      }
      if (returnRef) {
        returnRef.loading = true;
        returnRef.result = '';
      }
      if (WG_debug) console.log("Setting device at unit: ", device_id, unit_id);
      this.WGApiData.process_data_soon(3000);
      this.$http.patch(`api/dashboard/devices/${device_id}/`, {unit_id: unit_id}).then(
          (response: ng.IHttpPromiseCallbackArg<IDevice>) => {
            this.WGApiData.WGDevices.merge_entry(response.data);
            this.WGApiData.WGUnits.changed_locally = true;

            console.log("setUnitAtDevice SUCCESS", response);
            if (returnRef) {
              returnRef.loading = false;
              returnRef.result = 'success';
            }
            if (onFulfilled) {
              onFulfilled(response);
            }
            return true;
          },
          (response) => {
            console.log("setUnitAtDevice FAIL", response);
            if (returnRef) {
              returnRef.loading = false;
              returnRef.result = 'error';
            }
            if (onRejected) {
              onRejected(response);
            }
            return false;
          }
      ).finally(() => {
        this.WGApiData.process_data_soon();
      });
    }

    configure_density_reading(device: IDevice, enable = true, finish_callback = null): void {
      if (!device || !device.uuid || !device.iid || !device.path) {
        console.log("Device Disable density, Fail! Missing required fields.", device);
        return;
      }
      let _lkm_configs: IDeviceLKMConfigs = parseData(this.$rootScope.lastKnownMessages?.[device.uuid]?.configs?.payload?.value, {});
      let _new_lkm_configs = _.cloneDeep(_lkm_configs);

      let _disable_functions_supported = false;
      if (!_.isNil(_lkm_configs.disable_functions) || (vprog_gte(device.version, '8.1.6'))) {
        _disable_functions_supported = true;
      }

      // Delete Legacy upper-caps keys
      if (_new_lkm_configs['LDENSA_N_READS']) {
        _new_lkm_configs['LDENSA_N_READS'] = null;
        // delete _new_lkm_configs['LDENSA_N_READS'];
      }
      if (_new_lkm_configs['LDENSA_N_READS_ENABLED_VALUE']) {
        _new_lkm_configs['LDENSA_N_READS_ENABLED_VALUE'] = null;
        // delete _new_lkm_configs['LDENSA_N_READS_ENABLED_VALUE'];
      }

      if (enable) {
        if (_disable_functions_supported) {
          _new_lkm_configs.disable_functions = bit_clear(_new_lkm_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_DENSITY"]);
        }
        if (_new_lkm_configs.ldensa_n_reads <= 1) {
          _new_lkm_configs.ldensa_n_reads = _new_lkm_configs.ldensa_n_reads_enabled_value > 0 ? _new_lkm_configs.ldensa_n_reads_enabled_value : 750;
        }
      } else {
        if (_disable_functions_supported) {
          _new_lkm_configs.disable_functions = bit_set(_new_lkm_configs.disable_functions, DISABLE_FUNCTIONS_BITS["DISABLE_DENSITY"]);
        } else {
          if (!_new_lkm_configs.ldensa_n_reads_enabled_value && _new_lkm_configs.ldensa_n_reads > 1) {
            _new_lkm_configs.ldensa_n_reads_enabled_value = _new_lkm_configs.ldensa_n_reads;
          }
          _new_lkm_configs.ldensa_n_reads = 0;
        }
      }

      const payload = {
        timestamp: new Date().getTime(),
        iid: device.iid,
        value: _new_lkm_configs,
      };
      const url = this.$rootScope.base_url + 'data/' + device.path + '/configs?api_key=' + this.$rootScope.apiKey;
      this.$http.post(url, payload).then(
          (response) => {
            if (WG_debug) console.log(device, response);
            if (_disable_functions_supported) {
              _lkm_configs.disable_functions = _new_lkm_configs.disable_functions;
            } else {
              _lkm_configs.ldensa_n_reads = _new_lkm_configs.ldensa_n_reads;
              _lkm_configs.ldensa_n_reads_enabled_value = _new_lkm_configs.ldensa_n_reads_enabled_value;
            }
            device.status_density_reading = enable;
            console.log("Density Reading configured to ", enable, _new_lkm_configs, device);
            if (finish_callback) {
              finish_callback(device);
            }
          },
          (response) => {
            console.error("Density Reading configuration, Fail!");
            if (WG_debug) console.log(device, response);
            if (finish_callback) {
              finish_callback(device);
            }
          }
      );
    }

    save_device_configs(device: IDevice, new_configs: IDeviceLKMConfigs, onFulfilled?: (any) => void, onRejected?: (any) => void): boolean {
      if (!device || !device.uuid || !device.iid || !device.path) {
        console.warn("Device save configs, Fail! Missing required fields.", device);
        return false;
      }

      new_configs = parseData(new_configs, null);
      if (new_configs == null) {
        console.warn('Invalid configs', new_configs);
        return false;
      }

      let lkm = this.$rootScope.lastKnownMessages?.[device.uuid] || device.lkm || {};
      let dev_configs = _.cloneDeep(parseData(lkm?.configs?.payload?.value, {}));
      dev_configs = _.assign(dev_configs, new_configs);

      // TODO: Find a way to delete entries, maybe sending null values?
      // Recursively delete null values
      dev_configs = _.pickBy(dev_configs, (v) => {
        if (_.isObject(v)) {
          return !_.isEmpty(v);
        }
        return !_.isNil(v);
      });

      if (WG_debug) console.log('Removed null configs', dev_configs);

      const payload = {
        iid: device.iid,
        value: dev_configs,
        timestamp: new Date().getTime(),
      };
      const url = this.$rootScope.base_url + 'data/' + device.path + '/configs?api_key=' + this.$rootScope.apiKey;
      if (WG_debug) console.log('Save configs', 'url', url, 'payload', payload);
      this.$http.post(url, payload).then(
          (response) => {
            if (lkm?.configs?.payload) {
              _.assign(lkm.configs.payload.value, dev_configs);
            }
            if (onFulfilled) {
              onFulfilled(response);
            }
          },
          (response) => {
            if (onRejected) {
              onRejected(response);
            }
          }
      );
      return true;
    }

    force_device_read(device: IDevice, only_config = false, just_ping = false, onFulfilled?: (any) => void, onRejected?: (any) => void): void {

      let stream = 'READ_NOW';
      let value = {force_read: !only_config};

      if (just_ping === true) {
        stream = 'PING';
        // @ts-ignore
        value = {ping_request: true};
      }

      console.log("Sending " + stream + " for device ", device.sn, value);
      let payload = {
        iid: device.iid,
        value: value,
        timestamp: new Date().getTime(),
        gw_iid: this.WGApiData.WGDevices?.devices_id[device.comm_status?.last_lora_gw_id]?.iid,
      };
      if (payload.gw_iid == '0242ac190011')
        payload.gw_iid = null;
      { // When device.comm_status.last_lora_gw_id is not set
        let lkm = this.$rootScope.lastKnownMessages?.[device.uuid] || {};
        // Get via LORA_INFOS
        if (!payload.gw_iid && lkm
            && lkm['LORA_INFOS']?.payload?.timestamp
            && (payload.timestamp - lkm['LORA_INFOS'].payload.timestamp) < 60 * 24 * 60 * 60 * 1000) {
          if (lkm['LORA_INFOS']?.payload?.value?.['gw_iid']
              && lkm['LORA_INFOS'].payload.value['gw_iid'] != '0242ac190011') {
            payload.gw_iid = lkm['LORA_INFOS'].payload.value['gw_iid'];
          } else if (lkm['LORA_INFOS']?.payload?.value?.['gws']?.[0]?.['gw']
              && lkm['LORA_INFOS'].payload.value['gws'][0]['gw'] != '0242ac190011') {
            payload.gw_iid = lkm['LORA_INFOS'].payload.value['gws'][0]['gw'];
          }
        }
        // Get via SLEEP
        if (!payload.gw_iid && lkm
            && lkm['SLEEP']?.payload?.timestamp
            && (payload.timestamp - lkm['SLEEP'].payload.timestamp) < 60 * 24 * 60 * 60 * 1000) {
          if (lkm?.['SLEEP']?.payload?.['gw_iid']
              && lkm['SLEEP'].payload['gw_iid'] != '0242ac190011') {
            payload.gw_iid = lkm['SLEEP'].payload['gw_iid'];
          }
        }

        // Get via last_wifi_gw_id
        if (!payload.gw_iid
            && device.comm_status?.last_wifi_gw_id
            && this.WGApiData.WGDevices?.devices_id[device.comm_status?.last_wifi_gw_id]?.iid
            && this.WGApiData.WGDevices.devices_id[device.comm_status.last_wifi_gw_id].iid != '0242ac190011') {
          payload.gw_iid = this.WGApiData.WGDevices.devices_id[device.comm_status.last_wifi_gw_id].iid;
        }
        // Use the first device with model:'smartbox'
        if (!payload.gw_iid) {
          let _smartbox = _.find(this.WGApiData.WGDevices.devices, {model: 'smartbox'});
          if (_smartbox?.iid) {
            payload.gw_iid = _smartbox.iid;
            console.info("Device never seen in a GW. Using first smartbox from account", _smartbox);
          }
        }
      }
      if (!payload.gw_iid || payload.gw_iid == '0242ac190011') {
        delete payload.gw_iid;
        console.warn("Could not find GW IID for device. Sending " + stream + " without one", device);
      }
      const url = this.$rootScope.base_url + 'data/' + device.path + '/' + stream + '?api_key=' + this.$rootScope.apiKey;
      this.$http.post(url, payload).then(
          (response) => {
            if (onFulfilled) {
              onFulfilled(response);
            }
          },
          (response) => {
            if (onRejected) {
              onRejected(response);
            }
          }
      );
    }

    save_device_data(device: IDevice, stream, value, retain = false, extra_payload = {}, returnRef?: IReturnResult, onFulfilled?: (any) => void, onRejected?: (any) => void): void {
      const payload = {
        iid: device.iid,
        value: value,
        timestamp: new Date().getTime(),
      };
      _.assign(payload, extra_payload);

      let key = '?api_key=' + this.$rootScope.apiKey;
      if (retain) {
        key += '&retain=1';
      }
      const url = this.$rootScope.base_url + 'data/' + device.path + '/' + stream + key;

      if (returnRef) {
        returnRef.loading = true;
        returnRef.result = '';
      }
      this.$http.post(url, payload).then(
          (response) => {
            if (returnRef) {
              returnRef.loading = false;
              returnRef.result = 'success';
            }
            if (onFulfilled) {
              onFulfilled(response);
            }
          },
          (response) => {
            console.error(response);
            if (returnRef) {
              returnRef.loading = false;
              returnRef.result = 'error';
            }
            if (onRejected) {
              onRejected(response);
            }
          }
      );
    }

    device_set_threshold(device: IDevice, reset = false, returnRef?: IReturnResult, onFulfilled?: (any) => void, onRejected?: (any) => void): void {
      let stream = null;
      let value = 0;

      const extra_payload = {function: (reset ? 'reset' : 'set0')};

      if (this.$rootScope.lastKnownMessages[device.uuid]['PRESS_VOLUME_TOTALIZER_A']) {
        stream = 'PRESS_VOLUME_TOTALIZER_SET_OFFSET';
        if (!reset) {
          value = parseFloat(this.$rootScope.lastKnownMessages[device.uuid]['PRESS_VOLUME_TOTALIZER_A'].payload?.value?.['total'] || 0);
        }
      } else if (this.$rootScope.lastKnownMessages[device.uuid]['LLV_TREAT']) {
        stream = 'LLV_SET_OFFSET';
        if (!reset) {
          value = parseFloat(this.$rootScope.lastKnownMessages[device.uuid]['LLV_TREAT'].payload?.value?.level || 0);
        }
      }

      if (stream) {
        this.save_device_data(device, stream, value, true, extra_payload, returnRef, onFulfilled, onRejected);
      }
    }

    save_feedback(data): void {
      if (!data || !data.owner || !data.title) {
        console.log("Error saving feedback. Missing important fields", data);
        return;
      }
      this.$http.post("api/dashboard/feedback/", data).then(
          (response) => {
            console.log("Feedback save, Ok", response);
          },
          (response) => {
            console.log("Feedback save, Fail!", response);
          }
      );
    }

    save_feedback_with_attachments(feedback_title: string, feedback_message: string, file_to_upload, already_uploaded_files_ids: {
      id: number
    }[]): void {
      const _data = {
        'owner': {"id": this.AuthService.user.id},
        'title': feedback_title,
        'body': feedback_message,
        'view': this.$state.current.name,
      };

      if (already_uploaded_files_ids && already_uploaded_files_ids.length > 0 && already_uploaded_files_ids[0].id) {
        _data["attachments"] = already_uploaded_files_ids;
      }

      if (!file_to_upload) {
        console.log("No attachment to store");
        this.save_feedback(_data);
        return;
      }

      const _configs = {
        headers: {'Content-Type': undefined},
        transformRequest: function (data, headersGetter) {
          const formData = new FormData();
          _.forEach(data, function (value, key) {
            formData.append(key, value);
            console.log("Attaching: ", key, value);
          });
          return formData;
        }
      };
      this.$http.post('api/dashboard/feedback/files/', {file: file_to_upload}, _configs).then(
          (response: ng.IHttpPromiseCallbackArg<any>) => {
            console.log("Attachment stored", response);
            if (response?.data?.id > 0) {
              if (!_data["attachments"]) {
                _data["attachments"] = [];
              }
              _data["attachments"].push({"id": response.data.id});
              this.save_feedback(_data);
            }
          },
          (response) => {
            console.log("Attachment failed. Saving feedback without", response);
            this.save_feedback(_data);
          }
      );
    }

    get_users(): ng.IPromise<any[]> {
      const deferred = this.$q.defer<any[]>();
      this.$http.get('api/auth/users/?page_size=1000&ordering=+username', {}).then(
          (response: ng.IHttpPromiseCallbackArg<IDataResponse>) => {
            deferred.resolve(response.data.results);
          }, (response) => {
            deferred.reject(response);
          }
      );
      return deferred.promise;
    }
  }

  App.service('APIUtils', APIUtils);
}
