/**
 * Created by pmec on 07/10/16.
 */
namespace wg {
  export class ManualEntryModalInstanceController {
    static $inject = ['$rootScope', '$scope', '$filter', '$http', '$location', '$stateParams', 'WGApiData', 'ngDialog', 'AuthService', '$translate'];

    constructor(
        private $rootScope: IRootScope,
        private $scope,
        private $filter: ng.IFilterService,
        private $http: ng.IHttpService,
        private $location: ng.ILocationService,
        private $stateParams: ng.ui.IStateParamsService,
        private WGApiData: WGApiData,
        private ngDialog: ng.dialog.IDialogService,
        private AuthService: IAuthService,
        private $translate: ng.translate.ITranslateService
    ) {
      // App.controller("ManualEntryModalInstance", ['$rootScope', '$scope', '$filter', '$http', '$location', '$stateParams', 'WGApiData', 'ngDialog', 'AuthService', '$translate',
      //   function ($rootScope:IRootScope, $scope, $filter: ng.IFilterService, $http: ng.IHttpService, $location: ng.ILocationService, $stateParams, WGApiData: WGApiData, ngDialog: angular.dialog.IDialogService, AuthService:IAuthService, $translate: ng.translate.ITranslateService) {


      $scope.UpdateIsValid = () => {
        // if (WG_debug) console.log('ManualEntry UpdateIsValid', $scope.newData);

        if (!$scope.newData || !$scope.newData.sensor) {
          $scope.dataInvalidTitle = "Missing Sensor";
          return;
        }
        if (!$scope.newData.datetime || !$scope.newData.datetime.getTime()) {
          $scope.dataInvalidTitle = "Missing Date";
          return;
        }
        if (!_.isFinite($scope.newData.value)) {
          $scope.dataInvalidTitle = "Missing Value";
          return;
        }
        if (!_.isFinite($scope.newData.value_conv)) {
          $scope.dataInvalidTitle = "Wrong Conversion. Please report";
          return;
        }
        $scope.dataInvalidTitle = "";
      }

      $scope.convert = () => {
        $scope.newData.value_conv = convert($scope.newData.value, $scope.newData.sensor?.conversion?.id, null, false);
      }

      $scope.deconvert = () => {
        $scope.newData.value = convert($scope.newData.value_conv, $scope.newData.sensor?.conversion?.id, null, true);
      }

      if (WG_debug) console.log('Manual Entry Modal', $scope.ngDialogData);

      var openMode = $scope.ngDialogData?.mode || 'open';


      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'manualEntryRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      $scope.mode = "add";
      $scope.device = $scope.ngDialogData?.device;

      $scope.userSensors = $scope.ngDialogData?.userSensors || WGApiData.WGSensors.manual_sensors || $rootScope?.userSensors;
      _.forEach(this.$scope.userSensors as ISensor[], (sensor) => {
        if (this.$rootScope.lastSensorValues?.[$scope.device.uuid]?.[sensor.internal_name]) {
          sensor.preferred = true;
        } else {
          sensor.preferred = false;
        }
      });


      // console.log($scope.device);
      $scope.newData = {
        sensor: null,
        datetime: null,
        value: null,
        value_conv: null,
        observation: null,
      };

      if ($scope.ngDialogData?.timestamp) {
        $scope.newData.datetime = new Date($scope.ngDialogData.timestamp);
      }
      if ($scope.ngDialogData?.sensor) {
        if ($scope.ngDialogData.sensor.configs?.manual === true) {
          $scope.newData.sensor = $scope.ngDialogData.sensor;

          if (!_.isNil($scope.ngDialogData.value)) {
            $scope.mode = "edit";
            $scope.newData.value_conv = $scope.ngDialogData.value;
            $scope.deconvert();
          }
          if (!_.isNil($scope.ngDialogData.observation)) {
            $scope.newData.observation = $scope.ngDialogData.observation;
          }
        }
      }

      // Show some suggestions
      $scope.dataInvalidTitle = "";

      $scope.saveData = (data) => {
        parentRet.loading = true;
        var payload = {
          timestamp: data.datetime.getTime(),
          value: data.value,
        };
        if (data.deleted === true) {
          payload['deleted'] = true;
        }
        if (angular.isDefined(data.observation)) {
          payload['observation'] = data.observation;
        }
        if (angular.isDefined($scope.device.iid)) {
          payload['iid'] = $scope.device.iid;
        }
        var key = '?api_key=' + $rootScope.apiKey;
        var url = $rootScope.base_url + 'data/' + $scope.device.path + '/' + data.sensor.stream + key;
        $http.post(url, payload).then(
            function (response) {
              console.log(response.data);
              // $scope.$parent.checkDataDefinition($scope.device.uuid, data.sensor.stream);
              // if (!_.includes($scope.sensors, data.sensor.stream)) {
              //   $scope.sensors.push(data.sensor.stream);
              //   $scope.sensors.sort();
              // }
              // $scope.selectSensor(data.sensor.stream, true);
              parentRet.loading = false;
              parentRet.result = 'success';

              // Mark deleted in LKM
              let lkm = $rootScope.lastKnownMessages?.[$scope.device.uuid]?.[data.sensor.stream];
              if (lkm?.payload && payload['deleted'] && payload.timestamp === lkm.timestamp) {
                lkm.payload.deleted = true;
              }
              // Mark deleted in LSV
              let lsv = $rootScope.lastSensorValues?.[$scope.device.uuid]?.[data.sensor.stream];
              if (lsv && payload['deleted'] && payload.timestamp === lsv.timestamp) {
                lsv.deleted = true;
              }

              $scope.confirm({device: $scope.device, sensor: data.sensor, payload: payload, response: response.data});
            }, function (response) {
              console.error(response);
              parentRet.result = 'error';
              parentRet.loading = false;
            });
      };

      $scope.canNewSensor = true;
      // $scope.searchSensor =  (select) => {
      //   if (select.search.length > 0) {
      //     $scope.canNewSensor = false;
      //     if (select.items.length === 0) {
      //       $scope.canNewSensor = true;
      //     }
      //   } else {
      //     $scope.canNewSensor = true;
      //   }
      // };

      $scope.newSensorData = {};
      $scope.newSensor = () => {
        ngDialog.openConfirm({
          template: 'modalDialogIdNewSensor',
          className: 'ngdialog-theme-default',
          scope: $scope
        }).then(function (data) {
          console.log('newSensor', data);
          //$scope.addSensor(data);
        }, function (reason) {
          console.log('Modal promise rejected. Reason: ', reason);
        });
      };

      function get_manual_sensor_name(name) {
        let _user_id = AuthService.user.id;
        if (AuthService.view_as_owner && AuthService.view_as_owner.id > 0) {
          _user_id = AuthService.view_as_owner.id;
        }
        return "manual_" + _user_id + name.replace(/[^0-9a-zA-Z]/g, '_');
      }

      $scope.addSensor = (data) => {

        data.internal_name = data.stream = get_manual_sensor_name(data.name);
        data.configs = {
          graph: true,
          manual: true,
          accessException: [AuthService.view_as_owner.uuid || AuthService.user.uuid],
          accessLevel: "admin"
        };
        data.configs = JSON.stringify(data.configs);
        console.log('addSensor', data);
        WGApiData.WGSensors.changed = true;
        $http.post('api/dashboard/sensors/', data).then(
            function (response: ng.IHttpPromiseCallbackArg<ISensor>) {
              if (response.status === 201) {
                let sensor = response.data;
                sensor.configs = parseData(sensor.configs);
                $scope.newData.sensor = sensor;
                $scope.newData.value = null;
                $scope.newData.value_conv = null;

                WGApiData.WGSensors.changed = true;
                WGApiData.update_changed_data_soon();
              }
              ngDialog.close('response ok')
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };

      $scope.$watch('newSensorData.name', function (name) {
        if (!angular.isDefined(name))
          return;
        $scope.newSensorData.stream = get_manual_sensor_name(name);
      });

      $scope.dateTimeNow = () => {
        $scope.newData.datetime = new Date();
      };

      $scope.maxDate = new Date();
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };

      if (!$scope.newData.datetime) {
        $scope.dateTimeNow();
      }
      $scope.UpdateIsValid();
    }
  }

  App.controller('ManualEntryModalInstance', ManualEntryModalInstanceController);
}