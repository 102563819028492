namespace wg {
  App.controller("DevicesIndividualController", ['$rootScope', '$scope', '$filter', '$http', '$location', '$stateParams', '$window', 'ngDialog', 'AuthService', '$translate',
    function ($rootScope: IRootScope, $scope, $filter: ng.IFilterService, $http: ng.IHttpService, $location: ng.ILocationService, $stateParams, $window, ngDialog: angular.dialog.IDialogService, AuthService: IAuthService, $translate: ng.translate.ITranslateService) {
      $scope.mRealTime = true;
      $scope.values = {};
      $scope.sensors = [];
      $scope.selectedSensor = null;
      var deviceuuid = $stateParams.device;
      var _chart;

      var selectedButton = 0;
      var selectedMin, selectedMax;

      $scope.$parent.$watch('devices', function () {
        if ($scope.$parent.devices.length == 0)
          return;

        var found = false;
        for (var i = 0; i < $scope.$parent.devices.length; i++) {
          if ($scope.$parent.devices[i].uuid === $stateParams.device) {
            found = true;
            $scope.device = $scope.$parent.devices[i];
          }
        }
        if (!found) {
          $location.path("/404");
          return;
        }
      });

      Highcharts.setOptions({
        lang: {
          loading: $translate.instant('highchart.lang.loading'),
          months: $translate.instant('highchart.lang.months').split(', '),
          shortMonths: $translate.instant('highchart.lang.shortMonths').split(', '),
          weekdays: $translate.instant('highchart.lang.weekdays').split(', '),
          // @ts-ignore
          exportButtonTitle: $translate.instant('highchart.lang.exportButtonTitle'),
          printButtonTitle: $translate.instant('highchart.lang.printButtonTitle'),
          rangeSelectorFrom: $translate.instant('highchart.lang.rangeSelectorFrom'),
          rangeSelectorTo: $translate.instant('highchart.lang.rangeSelectorTo'),
          rangeSelectorZoom: $translate.instant('highchart.lang.rangeSelectorZoom'),
          resetZoom: $translate.instant('highchart.lang.resetZoom'),
          resetZoomTitle: $translate.instant('highchart.lang.resetZoomTitle'),
          decimalPoint: $translate.instant('highchart.lang.decimalPoint'),
          thousandsSep: $translate.instant('highchart.lang.thousandsSep'),
          numericSymbols: $translate.instant('highchart.lang.numericSymbols').split(', '),
          printChart: $translate.instant('highchart.lang.printChart'),
          downloadPNG: $translate.instant('highchart.lang.downloadPNG'),
          downloadJPEG: $translate.instant('highchart.lang.downloadJPEG'),
          downloadPDF: $translate.instant('highchart.lang.downloadPDF'),
          downloadSVG: $translate.instant('highchart.lang.downloadSVG'),
          contextButtonTitle: $translate.instant('highchart.lang.contextButtonTitle')
        }
      });
      var exportData = {
        sensor: '',
        min: 0,
        max: 0
      };
      $scope.chartConfig = {
        options: {
          chart: {
            zoomType: 'x'
          },
          rangeSelector: {
            allButtonsEnabled: true,
            enabled: true,
            buttons: [{
              type: 'day',
              count: 1,
              query: '1d',
              text: '1' + $translate.instant('highchart.buttons.d')
            }, {
              type: 'week',
              count: 1,
              query: '1w',
              text: '1' + $translate.instant('highchart.buttons.w')
            }, {
              type: 'month',
              count: 1,
              query: '1mth',
              text: '1' + $translate.instant('highchart.buttons.m')
            }, {
              type: 'month',
              count: 3,
              query: '3mth',
              text: '3' + $translate.instant('highchart.buttons.m')
            }, {
              type: 'month',
              count: 6,
              query: '6mth',
              text: '6' + $translate.instant('highchart.buttons.m')
            }, {
              type: 'year',
              count: 1,
              query: '1y',
              text: '1' + $translate.instant('highchart.buttons.y')
            }, {
              type: 'all',
              query: '30y',
              text: $translate.instant('highchart.buttons.All')
            }]
          },
          xAxis: {
            events: {
              afterSetExtremes: afterSetExtremes
            },
            ordinal: false,
            title: {
              text: ""
            }
          },
          yAxis: {
            title: {
              text: ""
            }
          },
          legend: {
            enabled: true
          },
          navigator: {
            enabled: true
          },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  {
                    text: $translate.instant('highchart.lang.downloadPNG'),
                    onclick: function () {
                      _chart.exportChart({type: 'image/png'});
                    }
                  }, {
                    text: $translate.instant('highchart.lang.downloadJPEG'),
                    onclick: function () {
                      _chart.exportChart({type: 'image/jpeg'});
                    }
                  },
                  {
                    text: $translate.instant('highchart.lang.downloadPDF'),
                    onclick: function () {
                      _chart.exportChart({type: 'application/pdf'});
                    }
                  },
                  {
                    text: $translate.instant('highchart.lang.downloadSVG'),
                    onclick: function () {
                      _chart.exportChart({type: 'image/svg+xml'});
                    }
                  },
                  {
                    text: $translate.instant('highchart.export', {format: 'CSV'}),
                    onclick: function () {
                      $window.open($scope.$parent.getUrlExport(deviceuuid,
                          exportData.sensor, exportData.min, exportData.max,
                          $scope.chartConfig.options.exporting.filename,
                          '.csv'), '_blank');
                    }
                  },
                  {
                    text: $translate.instant('highchart.export', {format: 'XML'}),
                    onclick: function () {
                      $window.open($scope.$parent.getUrlExport(deviceuuid,
                          exportData.sensor, exportData.min, exportData.max,
                          $scope.chartConfig.options.exporting.filename,
                          '.xml'), '_blank');
                    }
                  },
                  {
                    text: $translate.instant('highchart.export', {format: 'JSON'}),
                    onclick: function () {
                      $window.open($scope.$parent.getUrlExport(deviceuuid,
                          exportData.sensor, exportData.min, exportData.max,
                          $scope.chartConfig.options.exporting.filename,
                          '.json'), '_blank');
                    }
                  }]
              }
            },
            enabled: true,
            filename: deviceuuid
          },
          credits: {
            enabled: false
          }
        },
        series: [],
        title: {
          //text: 'Hello'
        },
        useHighStocks: true,
        loading: false,
        func: function (chart) {
          _chart = chart;
        }
      };

      function afterSetExtremes(e) {
        console.log('afterSetExtremes', e.trigger);
        //console.log(e);
        $scope.loading = true;
        var min, max;
        if (e.trigger == 'rangeSelectorInput') {
          var rangeSelector = $('input.highcharts-range-selector');
          // @ts-ignore
          min = Date.parse(rangeSelector[0].value);
          // @ts-ignore
          max = Date.parse(rangeSelector[1].value);
          selectedMin = min;
          selectedMax = max;
        } else if (e.trigger == 'rangeSelectorButton') {
          var defaultTime = e.rangeSelectorButton.query;
          console.log('e.rangeSelectorButton.query', defaultTime);
          //selectedButton = _.findIndex($scope.chartConfig.options.rangeSelector.buttons, "query", e.rangeSelectorButton.query);
          selectedButton = _.findIndex($scope.chartConfig.options.rangeSelector.buttons, {query: e.rangeSelectorButton.query});
          max = new Date().getTime();
          min = max - defaultTime.parseTime('s') * 1000;
          if (min < 0)
            min = 0;
        } else {
          min = e.min;
          max = e.max;
        }

        if (e.trigger != "updatedData") {
          exportData.min = min;
          exportData.max = max;
          $scope.$parent.getDeviceSensorData(deviceuuid, $scope.selectedSensor, min, max, null, sensorLoadedData, [$scope.device, $scope.selectedSensor]);
        } else {
          if (selectedButton != undefined) {
            setTimeout(function () {
              $($(".highcharts-button")[selectedButton]).click();
              selectedButton = undefined;
            }, 10);
          }
          if (selectedMin != undefined && selectedMax != undefined) {
            var seriesData = $scope.chartConfig.series[0].data;
            _chart.xAxis[0].setExtremes(
                selectedMin > seriesData[0][0] ? selectedMin : seriesData[0][0],
                selectedMax < seriesData[seriesData.length - 1][0] ? selectedMax : seriesData[seriesData.length - 1][0]);
            selectedMin = undefined;
            selectedMax = undefined;
          }
          $scope.loading = false;
        }
      }

      $scope.selectSensor = function (sensor: string, force: boolean) {
        selectedButton = selectedButton || 0;
        $scope.loading = true;
        $scope.selectedSensor = sensor;
        var defaultTime = $scope.chartConfig.options.rangeSelector.buttons[selectedButton].query;
        var begin = new Date().getTime() - defaultTime.parseTime('s') * 1000;

        var devname = (!angular.isDefined($scope.device)) ? deviceuuid : $scope.device.name;
        $scope.chartConfig.options.exporting.filename = sensor + "_" + devname;
        exportData.sensor = sensor;

        if (angular.isDefined($scope.chartConfig.series[0]))
          $scope.chartConfig.series[0].data.length = 0;
        $scope.$parent.getDeviceSensorData(deviceuuid, sensor, begin, null, null, sensorLoadedData, [$scope.device, sensor], force);
        $scope.chartConfig.options.yAxis.title.text = (angular.isDefined($rootScope.watgridSensorNames[sensor]) ?
            $rootScope.watgridSensorNames[sensor].unit : "");
      };

      /*
       * Args:
       * 0 - device
       * 1 - sensor
       */
      function sensorLoadedData(args) {
        var device = args[0];
        var sensor = args[1];
        var serieIndex = 0;
        if (!angular.isDefined($scope.chartConfig.series[serieIndex])) {
          $scope.chartConfig.series[serieIndex] = {
            name: ((!angular.isDefined(device)) ? deviceuuid : device.name + " (" + device.description + ")"),
            visible: true,
            data: []
          };
          angular.copy($scope.$parent.data[device.uuid][sensor].seriesData, $scope.chartConfig.series[serieIndex].data);
          $rootScope.$watch('lastKnownMessages["' + device.uuid + '"]["' + sensor + '"].payload.timestamp', function (newTimestamp, oldTimestamp) {
            if (!angular.isDefined(newTimestamp) || newTimestamp <= oldTimestamp || !$scope.mRealTime) {
              return;
            }
            var value = $rootScope.lastKnownMessages[device.uuid][sensor].payload.value;
            //console.log(newTimestamp, oldTimestamp, 'lastKnownMessages["' + device.uuid + '"]["' + sensor + '"]', $rootScope.lastKnownMessages[device.uuid][sensor].payload, value);
            var dataLength = $scope.chartConfig.series[serieIndex].data.length;
            if (dataLength > 0 && newTimestamp > $scope.chartConfig.series[serieIndex].data[dataLength - 1][0]) {
              $scope.chartConfig.series[serieIndex].data.push([newTimestamp, value]);
              if (!angular.isDefined($scope.values[device.uuid])) {
                $scope.values[device.uuid] = {};
              }
              if (!angular.isDefined($scope.values[device.uuid][sensor])) {
                $scope.values[device.uuid][sensor] = {}
              }
              $scope.values[device.uuid][sensor] = value;
              //  console.log('realtime data added');
              //} else {
              //  console.log('realtime data to old');
            }
          }, true);
        } else {
          if ($scope.$parent.data[device.uuid][sensor].seriesData.length > $scope.chartConfig.series[serieIndex].data.length) {
            angular.copy($scope.$parent.data[device.uuid][sensor].seriesData, $scope.chartConfig.series[serieIndex].data);
          }
        }
        $scope.loading = false;
      }

      $rootScope.$watch('WGApiData.AllReady', function () {
        if (!$rootScope.WGApiData.AllReady) {
          return;
        }
        for (var sensor in $scope.$parent.data[deviceuuid]) {
          if (angular.isDefined($scope.$parent.watgridSensorNames[sensor])) {
            if (!angular.isDefined($scope.$parent.watgridSensorNames[sensor].configs) || !$scope.$parent.watgridSensorNames[sensor].configs.graph)
              continue
          } else {
            continue
          }
          $scope.sensors.push(sensor);
        }
        $scope.sensors.sort();
      });

      $scope.newData = {};
      $scope.saveData = function (data) {
        //console.log('saveData', data);
        var payload = {timestamp: data.datetime.getTime(), value: data.value};
        if (angular.isDefined(data.observation)) {
          payload['observation'] = data.observation;
        }
        if (angular.isDefined($scope.device.iid)) {
          payload['iid'] = $scope.device.iid;
        }
        var key = '?api_key=' + $rootScope.apiKey;
        var url = $rootScope.base_url + 'data/' + $scope.device.path + '/' + data.sensor.stream + key;

        $http.post(url, payload).then(
            function (response) {
              // console.log(response.data);
              $scope.$parent.checkDataDefinition($scope.device.uuid, data.sensor.stream);
              if (!_.includes($scope.sensors, data.sensor.stream)) {
                $scope.sensors.push(data.sensor.stream);
                $scope.sensors.sort();
              }
              $scope.selectSensor(data.sensor.stream, true);
            }, function (response) {
              console.error(response);
            });
      };

      $scope.canNewSensor = true;
      // $scope.searchSensor = function (select) {
      //   if (select.search.length > 0) {
      //     $scope.canNewSensor = false;
      //     if (select.items.length === 0) {
      //       $scope.canNewSensor = true;
      //     }
      //   } else {
      //     $scope.canNewSensor = true;
      //   }
      // };

      $scope.newSensorData = {};
      $scope.newSensor = function () {
        ngDialog.openConfirm({
          template: 'modalDialogIdNewSensor',
          className: 'ngdialog-theme-default',
          scope: $scope
        }).then(
            function (data) {
              console.log('newSensor', data);
              //$scope.addSensor(data);
            }, function (reason) {
              console.log('Modal promise rejected. Reason: ', reason);
            });
      };

      $scope.addSensor = function (data) {
        console.log('addSensor', data);
        data.configs = {graph: true, accessLevel: AuthService.user.role, manual: true};
        data.configs = JSON.stringify(data.configs);
        $http.post('api/dashboard/sensors/', data).then(
            function (response: ng.IHttpPromiseCallbackArg<ISensor>) {
              if (response.status === 201) {
                $rootScope.WGDevices.changed = true;
                $rootScope.WGSensors.changed = true;
                $rootScope.WGApiData.update_changed_data_soon();

                var sensor = response.data;
                sensor.configs = parseData(sensor.configs);
                $scope.newData.sensor = sensor;
              }
              ngDialog.close('response ok')
            }, function (response) {
              console.error(response);
              if (response.status === 400 && response.data) {
                for (var key in response.data) {
                  var specificField = $('#id-' + key).parsley();
                  var i = 0;
                  angular.forEach(response.data[key], function (error) {
                    try {
                      specificField.addError('error-' + i, {message: error});
                      // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                    } catch (e) {
                    }
                    i++;
                  });
                }
              }
            });
      };

      $scope.$watch('newSensorData.name', function (name) {
        if (!angular.isDefined(name))
          return;
        $scope.newSensorData.stream = name.replace(/[^0-9a-zA-Z]/g, '_');
      });

      $scope.dateTimeNow = function () {
        $scope.newData.datetime = new Date();
      };

      $scope.dateTimeNow();
      $scope.maxDate = new Date();
      $scope.dateOptions = {
        startingDay: 1,
        showWeeks: false
      };
      $scope.dateTimeNow();
    }
  ])

}