/**
 * Created by pmec on 30/05/16.
 */

namespace wg {
  "use strict";

  /**
   * Converts a JSON-string to an Object.
   * Useful to process i18 names (that are actually dicts)
   * @param data
   * @param default_data - returned in case of failure. ==data if left undefined
   * @param msg - message to show in case of failure. Otherwise, it's silent
   */
  export function parseData(data: string | object, default_data: object = undefined, msg = null): object {
    if (default_data === undefined) {
      default_data = data as object;
    }
    if (_.isObject(data))
      return data; // Already an object
    if (!_.isString(data))
      return default_data; // Not a string, can't parse
    if(data === "")
      return default_data; // Empty string, can't parse
    try {
      return JSON.parse(data);
    } catch (e) {
      if (msg && WG_debug) console.warn('Error parsing JSON', msg, data, e);
      return default_data;
    }
  }

  // Extracts given keys/elements from the passed data object
  export function select(_query: string | object, _data: any): any[] {
    var ret = [];
    if (!angular.isDefined(_data)) {
      return ret;
    }
    if (typeof (_query) === 'object') {
      for (var k in _query) {
        if (_query instanceof Array) {
          ret = ret.concat(select(_query[k], _data));
        } else if (_data.hasOwnProperty(k)) {
          ret = ret.concat(select(_query[k], _data[k]));
        }
      }
    } else if (_data.hasOwnProperty(_query)) { // query is already a string
      ret.push(_data[_query]);
    }
    return ret;
  }

  /**
   * like _.pick, but for nested objects and returns a flat object with the selected keys
   * @param data : JSON data that you want to select fields from
   * @param keys : list of keys to select from the data, nested keys are supported like 'parent.child1.child2' , if no keys are provided, the data is returned as is
   * @returns
   */
  export function nestedPick(data: {}[], keys: string[]): {}[] {
    if (!keys || keys.length === 0) {
      return data;
    }
    return data.map(item => {
      const selectedItem = {};
      keys.forEach(key => {

        const parts = key.split('.');
        const value = parts.reduce((acc, part) => acc && acc[part], item as any);
        if (value !== undefined) {

          const newKey = parts[parts.length - 1];
          selectedItem[newKey] = value;
        }
      });
      return selectedItem;
    });
  }


  export function convert_poly_array(value: number, poly: number[]): number {
    if (_.isEmpty(poly) || !_.isFinite(value)) {
      return null;
    }
    return convert_poly(value, poly[0] || 0, poly[1] || 0, poly[2] || 0, poly[3] || 0, poly[4] || 0);
  }

  export function convert_poly(value: number, x_1: number = 0, x0: number = 0, x1: number = 0, x2: number = 0, x3: number = 0): number {
    if (_.isFinite(x_1) && x_1 != 0) {
      if (value == 0) {
        return 0;
      }
      return (x_1 / value) + x0 + (x1 * value) + (x2 * value * value) + (x3 * value * value * value);
    }
    return x0 + (x1 * value) + (x2 * value * value) + (x3 * value * value * value);
  }

  function isArrayOfStrings(value: any): boolean {
    return Array.isArray(value) && value.every(item => typeof item === "string");
  }

  function isArrayOfNumbers(value: any): boolean {
    return Array.isArray(value) && value.every(item => isNumber(item));
  }

  function isNumber(value: any): boolean {
    return typeof value === "number";
  }

  /**
   * Convert from SI to a given Unit
   * deconvert: Convert back to SI from the chosen Unit
   */
  export function convert(value: null | number | number[] | {
    x: number,
    y: number
  }, to: string, device_lkm: any, deconvert: boolean = false): number {
    let ret: number;
    let _value: number;
    let _values: number[] = [];
    let _dens: number;// Temp
    if (_.isNil(value)) {
      return <number>value;
    }
    if (isNumber(value)) {
      _value = <number>value;
    } else if (isNumber(value[1])) {
      // value[0] == timestamp, [1] == measured, [2] == aux (dens? temp?)
      _value = value[1];
      _values = <number[]>value;
    } else if (isNumber(value['y'])) {
      // value[0] == timestamp, [1] == measured, [2] == aux (dens? temp?)
      _value = value['y'];
      _values = <number[]>[value['y']];
    }
    switch (to) {
        // Wi-Fi
      case 'wifi_quality':
        if (deconvert) {
          return Math.round(linearInterpolation(_value, 1, -87, 100, -40));
        }
        if (_value >= 0) // Fix when rssi is positive (common chips' bug)
          _value = _value < 256 ? (_value - 256) : -_value;
        if (_value <= -87)
          return 1
        if (_value >= -40)
          return 100
        return Math.round(linearInterpolation(_value, -87, 1, -40, 100));

        // LoRa
      case 'lora_quality':
        if (deconvert) {
          return Math.round(linearInterpolation(_value, 1, -125, 100, -50));
        }
        if (_value >= 0) // Fix when rssi is positive (common chips' bug)
          _value = _value < 256 ? (_value - 256) : -256;
        if (_value <= -125)
          return 1
        if (_value >= -50)
          return 100
        return Math.round(linearInterpolation(_value, -125, 1, -50, 100));

        // Temperature
      case 'fahrenheit':
        if (deconvert) {
          // (ret - 32) * 5/9 = _value
          return convert_poly(_value, 0, -32 * 5.0 / 9, 5.0 / 9);
        }
        // ret = _value * 9/5 + 32;
        return convert_poly(_value, 0, 32, 9.0 / 5);

        // Distance
      case 'inches':
        if (deconvert) {
          // ret * 25.4 = _value;
          return convert_poly(_value, 0, 0, 25.4);
        }
        // ret = _value * (1 / 25.4);
        return convert_poly(_value, 0, 0, (1.0 / 25.4));

        // Pressure
      case 'psi':
        if (deconvert) {
          // psi * 14.503773773 = bar;
          return convert_poly(_value, 0, 0, (1.0 / 14.503773773));
        }
        // psi = bar * (1 / 14.503773773);
        return convert_poly(_value, 0, 0, 14.503773773);

        // Volume
      case 'hectoliter':
        // hectoliter * 100 = liter;
        if (deconvert) {
          return _value * 100.0;
        }
        return _value / 100.0;
      case 'gallon':
        if (deconvert) {
          // gallon * 3.78541 = liter;
          return convert_poly(_value, 0, 0, 3.78541);
        }
        // gallon = liter / 3.78541;
        return convert_poly(_value, 0, 0, (1.0 / 3.78541));

        // Flow
      case 'gallon/min':
        if (deconvert) {
          // gallon * 3.78541 = liter;
          return convert_poly(_value, 0, 0, 3.78541);
        }
        // gallon = liter / 3.78541;
        return convert_poly(_value, 0, 0, (1.0 / 3.78541));

        // Flow
      case 'hl/h':
        if (deconvert) {
          // hl/h * 100 / 60 = liter/min;
          return _value / 0.6;
        }
        // hl/h = liter/min * 60 / 100;
        return _value * 0.6;

        // Density
      case 'kg/m3':
        if (deconvert) {
          return _value / 1000;
        }
        return _value * 1000;

      case 'g/cm3':
      case 'g/mL':
      case 'kg/L':
        return _value;

      case 'lb/ft3':
        if (deconvert) {
          // ret / 62.427961 = _value;
          return convert_poly(_value, 0, 0, 1 / 62.427961);
        }
        // ret = _value * 62.427961;
        return convert_poly(_value, 0, 0, 62.427961);

      case 'lb/in3':
        if (deconvert) {
          // ret / 0.036127 = _value;
          return convert_poly(_value, 0, 0, 1 / 0.036127);
        }
        // ret = _value * 0.036127;
        return convert_poly(_value, 0, 0, 0.036127);

        // Fermentation Kinetic
      case 'kg/m3/day':
      case 'g/dm3/day':
        if (deconvert) {
          return _value / 1000.0;
        }
        return _value * 1000.0;

      case 'kg/m3/hour':
      case 'g/dm3/hour':
        if (deconvert) {
          return _value / 1000.0 * 24.0;
        }
        return _value * 1000.0 / 24.0;

      case 'brix_jacob':
        if (deconvert) {
          // SG = −261.3/(Brix - 261.3)
          return -261.3 / (_value - 261.3);
        }
        // Brix = 261.3 − 261.3 / SG
        return convert_poly(_value, -261.3, 261.3);

      case 'brix_wiki':
        if (deconvert) {
          // From google: (Brix / (258.6-((Brix / 258.2)*227.1))) + 1
          return (_value / (258.6 - ((_value / 258.2) * 227.1))) + 1;
          // From google 2:  sg = 0.00000005785037196 * brix3 + 0.00001261831344 * brix2 + 0.003873042366 * brix + 0.9999994636
          // return (0.00000005785037196 * _value * _value * _value + 0.00001261831344 * _value * _value + 0.003873042366 * _value + 0.9999994636);
        }
        return convert_poly(_value, 0, -669.5622, 1262.7794, -775.6821, 182.4601);

      case 'brix/day':
        if (deconvert) {
          return NaN;
        }
        ret = NaN;
        if (device_lkm
            && device_lkm['AI_LDENSA']
            && device_lkm['AI_LDENSA'].payload
            && device_lkm['AI_LDENSA'].payload.value) {
          _dens = device_lkm['AI_LDENSA'].payload.value['Mass_Dens_class'];
        } else if (_values.length >= 3) {
          _dens = _values[2]
        }
        if (_dens && _dens > 0) {
          ret = 261.3 * _value / (_dens * (_dens - _value));
        } else {
          if (WG_debug) console.info("Converting to Brix/Day failed. Missing Density!");
        }
        return ret;


      case 'baume':
        if (deconvert) {
          // SG =  -144.32 / (ret - 144.32)
          return -144.32 / (_value - 144.32);
        }
        // Baume = 144.32 -  144.32 / SG
        ret = convert_poly(_value, -144.32, 144.32, 0, 0); // Old textbooks formula, very similar to Table
        // ret = convert_poly(value, 0, -264.05, 384.9, -120.84); // Table, and taylor's regression
        // ret = convert_poly(value, -145.0, 145.0, 0, 0); // New formula. Some differences.
        if (ret > 16.0) {
          ret = 16.0;
        }
        if (ret < -2.0) {
          ret = -2.0;
        }
        return ret;

      case 'baume_alt':
        if (deconvert) {
          return (_value + 132.7898311490398) / 132.5638524839986;
        }
        ret = -132.7898311490398 + 132.5638524839986 * _value;
        if (ret < -2.0) {
          ret = -2.0;
        }
        return ret;

      case 'baume/day':
        if (deconvert) {
          return null;
        }
        ret = null;
        if (device_lkm
            && device_lkm['AI_LDENSA']
            && device_lkm['AI_LDENSA'].payload
            && device_lkm['AI_LDENSA'].payload.value) {
          _dens = device_lkm['AI_LDENSA'].payload.value['Mass_Dens_class'];
        } else if (_values.length >= 3) {
          _dens = _values[2]
        }
        // kin = x - y, y = x[t-1], y = x - kin
        // kin_baume = x_baume - y_baume
        if (_dens && _dens > 0) {
          ret = 144.32 * _value / (_dens * (_dens - _value));
        } else {
          if (WG_debug) console.warn("Converting to Baume/Day failed. Missing Density!");
        }

        if (!_.isFinite(ret)) {
          ret = null;
        } else if (ret > 30) {
          ret = 30;
        } else if (ret < -30) {
          ret = -30;
        }
        return ret;


      default:
        return _value;

    }

    console.error("Unreachable code in convert_value");
    return ret;
  }

  /*
  def calc(liquid_height):
      if liquid_height <= 0:
          return None
      liquid_dip = installation_dip - liquid_height
      if liquid_dip < -5:
          return None
      if liquid_dip < 0:
          liquid_dip = 0
      return np.interp(liquid_dip, dip, volume)
  */

  /**
   * Converts Liquid Level to Volume, according to information given/configured by user
   * @param value measured Liquid Level.
   * @param conversion.interp.x_poly Conversion function to apply to measured liquid level. Usually converts "Level over sensor" to "dip from top"
   * @param conversion.interp.x|y dip to volume list of points, to interpolate results
   * @param conversion.interp.y_poly Conversion function to apply to "dip from top" results.
   *
   */
  export function convert_liquid_height_to_volume(value: number, conversion: any) {
    if (!conversion.interp) {
      return null;
    }
    // value = "Level over sensor"
    if (value === null || value < 0) {
      return null;
    }
    if (conversion.interp.x_poly) {
      value = convert_poly_array(value, conversion.interp.x_poly);
    }
    // value = "dip from top"
    let i = wg.linearInterpolate(value, conversion.interp.x, conversion.interp.y)
    if (i && i.length == 1) {
      value = i[0]
      // value = volume
      if (conversion.interp.y_poly) {
        value = convert_poly_array(value, conversion.interp.y_poly);
      }
      // value = converted volume
      value = parseFloat(value.toFixed(conversion.decimals))
      return value
    }
    return null;
  }

  // Returns a dict of param:value from a passed URL. Inverse of $.param()
  export function deparam(url: string) {
    let _params, _pair, i;
    // start bucket; can't cheat by setting it in scope declaration or it overwrites
    _params = {};
    // remove preceding non-querystring, correct spaces, and split
    let _URL_params = url.substring(url.indexOf('?') + 1).replace(/\+/g, ' ').split('&');
    // march and parse
    for (i = _URL_params.length; i > 0;) {
      _pair = _URL_params[--i].split('=');
      _params[decodeURIComponent(_pair[0])] = decodeURIComponent(_pair[1]);
    }
    return _params;
  }

  export function updateURLParameter(url: string, key: string, value: string) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    if (url.match(re)) {
      return url.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      let separator = url.indexOf('?') !== -1 ? "&" : "?";
      return url + separator + key + "=" + value;
    }
  }

  export function rgbToHsl(r, g, b) {
    /**
     * Converts an RGB color value to HSL. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   Number  r       The red color value
     * @param   Number  g       The green color value
     * @param   Number  b       The blue color value
     * @return  Array           The HSL representation
     */

    r /= 255, g /= 255, b /= 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }
    /*  s = s*100;
      s = Math.round(s);
      l = l*100;
      l = Math.round(l);
      h = Math.round(360*h);*/
    /* var colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
     console.log(colorInHSL, "colorInHSL")*/
    return [h, s, l];

  }

  function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  export function hslToRgb(h, s, l) {
    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   Number  h       The hue
     * @param   Number  s       The saturation
     * @param   Number  l       The lightness
     * @return  Array           The RGB representation
     */
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = this.hue2rgb(p, q, h + 1 / 3);
      g = this.hue2rgb(p, q, h);
      b = this.hue2rgb(p, q, h - 1 / 3);
    }
    return [r * 255, g * 255, b * 255];
    // return [ r , g , b  ];
  }

  export function hexToRgb(hex: string): [r: number, g: number, b: number] {
    hex = hex.replace("#", "").replace(" ", "");
    if (hex.length !== 6) {
      if (WG_debug) console.warn('RGB, wrong hex length!', hex);
      return [101, 101, 101];
    }
    let bigint = parseInt(hex, 16),
        r = (bigint >> 16) & 255,
        g = (bigint >> 8) & 255,
        b = bigint & 255;

    return [r, g, b];
  }

  // Always start with #, then have anything between 6 and 9 chars, to allow some legacy bugs
  export function wgHexToRGB(hex: string): [r: number, g: number, b: number] {
    let r = 101, g = 101, b = 101;
    try {
      if (hex.length === 7) {
        // We have a standard hex color.
        return hexToRgb(hex);
      }

      let r_txt: string, g_txt: string, b_txt: string;

      if (hex.length === 10) {
        // #rrrgggbbb
        r_txt = hex.slice(1, 4);
        g_txt = hex.slice(4, 7);
        b_txt = hex.slice(7, 10);
        if (WG_debug) console.warn('RGB sensor with 10 of length?!?', hex);
      } else if (hex.length === 9) {
        // #rrrgggbb
        r_txt = hex.slice(1, 4);
        g_txt = hex.slice(4, 7);
        b_txt = hex.slice(7, 9);
        if (parseInt(r_txt, 16) > 0x130) {
          // #rrgggbbb
          r_txt = hex.slice(1, 3);
          g_txt = hex.slice(3, 6);
          b_txt = hex.slice(6, 9);
        } else if (parseInt(g_txt, 16) > 0x130) {
          // #rrrggbbb
          r_txt = hex.slice(1, 4);
          g_txt = hex.slice(4, 6);
          b_txt = hex.slice(6, 9);
        }
        if (WG_debug) console.warn('RGB sensor with 9 of length?!?', hex);
      } else if (hex.length === 8) {
        // #rrrggbb
        r_txt = hex.slice(1, 4);
        g_txt = hex.slice(4, 6);
        b_txt = hex.slice(6, 8);
        if (parseInt(r_txt, 16) > 0x130) {
          // #rrgggbb
          r_txt = hex.slice(1, 3);
          g_txt = hex.slice(3, 6);
          b_txt = hex.slice(6, 8);
        }
        if (parseInt(g_txt, 16) > 0x130) {
          // #rrggbbb
          r_txt = hex.slice(1, 3);
          g_txt = hex.slice(3, 5);
          b_txt = hex.slice(5, 8);
        }
        if (WG_debug) console.warn('RGB sensor with 8 of length?!?', hex);
      } else if (hex.length === 7) {
        // #rrggbb
        r_txt = hex.slice(1, 3);
        g_txt = hex.slice(3, 5);
        b_txt = hex.slice(5, 7);
      }

      r = parseInt(r_txt, 16);
      g = parseInt(g_txt, 16);
      b = parseInt(b_txt, 16);
      if (r > 0x130 || g > 0x130 || b > 0x130) {
        if (WG_debug) console.error('RGB sensor with out-of-range values', hex, r, g, b);
      }
      if (r > 255) {
        r = 255;
      }
      if (g > 255) {
        g = 255;
      }
      if (b > 255) {
        b = 255;
      }
    } catch (e) {
    }
    return [r, g, b];
  }

  function componentToHex(c: number): string {
    if (c > 255) {
      c = 255;
    }
    if (c < 0) {
      c = 0;
    }
    c = Math.round(c);
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  export function rgbToHex(r: number, g: number, b: number): string {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  export function getColor(hex, rgb) {
    let _rgb = wgHexToRGB(hex);
    return rgbToHex(_rgb[0], _rgb[1], _rgb[2]);

    if (hex.length != 7) {
      if (hex.length === 10) {
        // #rrrgggbbb
        let r = hex.slice(1, 4);
        let g = hex.slice(4, 7);
        let b = hex.slice(7, 10);
        r = parseInt(r, 16);
        g = parseInt(g, 16);
        b = parseInt(b, 16);
        if (r > 255) {
          r = 255;
        }
        if (g > 255) {
          g = 255;
        }
        if (b > 255) {
          b = 255;
        }
        return rgbToHex(r, g, b);
      } else if (hex.length === 9) {
        // #rrrgggbb
        let r = hex.slice(1, 4);
        let g = hex.slice(4, 7);
        let b = hex.slice(7, 9);
        r = parseInt(r, 16);
        g = parseInt(g, 16);
        b = parseInt(b, 16);
        if (r > 0xFF && r <= 0x130) {
          r = 255;
          if (g > 0xFF && g <= 0x130) {
            g = 255;
            return rgbToHex(r, g, b);
          } else {
            // #rrrggbbb
            g = hex.slice(4, 6);
            b = hex.slice(6, 9);
            g = parseInt(g, 16);
            b = parseInt(b, 16);
            if (b > 0xFF && b <= 0x130) {
              b = 255;
              return rgbToHex(r, g, b);
            }
          }
        }
        if (WG_debug) console.log(9, '? ? ?', hex);
        return '#656565';
      } else if (hex.length === 8) {
        // #rrrggbb
        let r = hex.slice(1, 4);
        let g = hex.slice(4, 6);
        let b = hex.slice(6, 8);
        r = parseInt(r, 16);
        g = parseInt(g, 16);
        b = parseInt(b, 16);
        if (r > 0xFF && r <= 0x130) {
          r = 255;
          return rgbToHex(r, g, b);
        } else {
          // #rrggbbb
          r = hex.slice(1, 3);
          g = hex.slice(3, 5);
          b = hex.slice(5, 8);
          r = parseInt(r, 16);
          g = parseInt(g, 16);
          b = parseInt(b, 16);
          if (b > 0xFF && b <= 0x130) {
            b = 255;
            return rgbToHex(r, g, b);
          }
        }
        if (WG_debug) console.log(8, '? ? ?', hex);
        return '#656565';
      } else {
        if (WG_debug) console.log(hex.length, '? ? ?', hex);
        return '#656565';
        // return rgbToHex(rgb[0], rgb[1], rgb[2]);
      }
    } else {
      return hex;
    }
  }

  // From: https://github.com/BorisChumichev/everpolate

  /**
   * Makes argument to be an array if it's not
   *
   * @param input
   * @returns {Array}
   */
  function makeItArrayIfItsNot(input) {
    return Object.prototype.toString.call(input) !== '[object Array]'
        ? [input]
        : input
  }

  /**
   *
   * Utilizes bisection method to search an interval to which
   * point belongs to, then returns an index of left or right
   * border of the interval
   *
   * @param {Number} point
   * @param {Array} intervals
   * @param {Boolean} useRightBorder
   * @returns {Number}
   */
  function findIntervalBorderIndex(point, intervals, useRightBorder) {
    //If point is beyond given intervals
    if (point < intervals[0])
      return 0
    if (point > intervals[intervals.length - 1])
      return intervals.length - 1
    //If point is inside interval
    //Start searching on a full range of intervals
    var indexOfNumberToCompare
        , leftBorderIndex = 0
        , rightBorderIndex = intervals.length - 1
    //Reduce searching range till it find an interval point belongs to using binary search
    while (rightBorderIndex - leftBorderIndex !== 1) {
      indexOfNumberToCompare = leftBorderIndex + Math.floor((rightBorderIndex - leftBorderIndex) / 2)
      point >= intervals[indexOfNumberToCompare]
          ? leftBorderIndex = indexOfNumberToCompare
          : rightBorderIndex = indexOfNumberToCompare
    }
    return useRightBorder ? rightBorderIndex : leftBorderIndex
  }

  // Returns the value at a given percentile in a sorted numeric array.
  // "Linear interpolation between closest ranks" method
  export function percentile(arr: number[], p: number) {
    if (arr.length === 0) return 0;
    if (typeof p !== 'number') throw new TypeError('p must be a number');
    if (p <= 0) return arr[0];
    if (p >= 1) return arr[arr.length - 1];

    var index = (arr.length - 1) * p,
        lower = Math.floor(index),
        upper = lower + 1,
        weight = index % 1;

    if (upper >= arr.length) return arr[lower];
    return arr[lower] * (1 - weight) + arr[upper] * weight;
  }

  /**
   * Evaluates interpolating line/lines at the set of numbers
   * or at a single number for the function y=f(x)
   *
   * @param {Number|Array} pointsToEvaluate     number or set of numbers
   *                                            for which polynomial is calculated
   * @param {Array} functionValuesX             set of distinct x values
   * @param {Array} functionValuesY             set of distinct y=f(x) values
   * @returns {Array}
   */
  export function linearInterpolate(pointsToEvaluate, functionValuesX, functionValuesY) {
    var results = []
    pointsToEvaluate = makeItArrayIfItsNot(pointsToEvaluate)
    pointsToEvaluate.forEach(function (point) {
      var index = findIntervalBorderIndex(point, functionValuesX, false)
      if (index == functionValuesX.length - 1)
        index--
      results.push(linearInterpolation(point, functionValuesX[index], functionValuesY[index]
          , functionValuesX[index + 1], functionValuesY[index + 1]))
    })
    return results
  }

  /**
   *
   * Evaluates y-value at given x point for line that passes
   * through the points (x0,y0) and (y1,y1)
   *
   * @param x
   * @param x0
   * @param y0
   * @param x1
   * @param y1
   * @returns {Number}
   */
  function linearInterpolation(x, x0, y0, x1, y1) {
    var a = (y1 - y0) / (x1 - x0)
    var b = -a * x0 + y0
    return a * x + b
  }

  // Sort callbFn, sorting elements based on their ".name" property
  export function order_names(a, b) {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }

  export function deep_compare(a, b) {

    let result = {
      different: [],
      missing_from_first: [],
      missing_from_second: []
    };

    _.reduce(a, function (result, value, key) {
      if (b.hasOwnProperty(key)) {
        if (_.isEqual(value, b[key])) {
          return result;
        } else {
          if (typeof (a[key]) != typeof ({}) || typeof (b[key]) != typeof ({})) {
            //dead end.
            result.different.push(key);
            return result;
          } else {
            var deeper = deep_compare(a[key], b[key]);
            result.different = result.different.concat(_.map(deeper.different, (sub_path) => {
              return key + "." + sub_path;
            }));

            result.missing_from_second = result.missing_from_second.concat(_.map(deeper.missing_from_second, (sub_path) => {
              return key + "." + sub_path;
            }));

            result.missing_from_first = result.missing_from_first.concat(_.map(deeper.missing_from_first, (sub_path) => {
              return key + "." + sub_path;
            }));
            return result;
          }
        }
      } else {
        result.missing_from_second.push(key);
        return result;
      }
    }, result);

    _.reduce(b, function (result, value, key) {
      if (a.hasOwnProperty(key)) {
        return result;
      } else {
        result.missing_from_first.push(key);
        return result;
      }
    }, result);

    return result;
  }


  export function flatten_nestable(entries: INestable[], flat: INestable[] = []): INestable[] {
    _.forEach(entries, (entry) => {
          if (entry.item.isGroup) {
            flatten_nestable(entry.children, flat)
          } else {
            flat.push(entry);
          }
        }
    );
    return flat;
  }

  export function scroll_to(div_id: string) {
    let _target_div = $(div_id);
    if (_target_div && _target_div.offset()) {
      $('html, body').animate({scrollTop: _target_div.offset().top - 55 - 100}, 1500, 'swing');
    }

    // let graphElement = angular.element(document.getElementById(div_id));
    // graphElement.trigger('scroll');
    // _$doc.scrollToElement(graphElement);
    // _$doc.scrollToElementAnimated(graphElement);
    // _$doc.scrollToElementAnimated(graphElement, 75);

    // console.log("Scrolling to ", _target_div);
    // // set the location.hash to the id of
    // // the element you wish to scroll to.
    // $location.hash('bottom');
    //
    // // call $anchorScroll()
    // $anchorScroll();
  }

  export function spitData(_title = "", _scope: any = {}, _arg3 = "", _arg4 = "", _arg5 = "") {
    console.log(_title + " spitdata. $scope:", _scope?.['$parent'] || _scope, _.cloneDeep(_arg3), _.cloneDeep(_arg4), _.cloneDeep(_arg5));
  }


  export function isBeta() {
    return WG_debug
        || (window.location.origin || window.location.href).includes("beta.winegrid.")
        || (window.location.origin || window.location.href).includes("winegrid.io");
  }

  export function isMobile() {
    return (function (a) {
      return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    })(navigator.userAgent || navigator.vendor || window['opera']);
  }

  export function isMobileOrTablet() {
    return (function (a) {
      return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    })(navigator.userAgent || navigator.vendor || window['opera']);
  }

  export function isTouch() {
    return ('ontouchstart' in window && !!navigator.userAgent.toLowerCase().match(/mobile|tablet/)) ||
        (window['DocumentTouch'] && document instanceof window['DocumentTouch']) ||
        (window.navigator['msPointerEnabled'] && window.navigator['msMaxTouchPoints'] > 0) || //IE 10
        (window.navigator['pointerEnabled'] && window.navigator['maxTouchPoints'] > 0) || //IE >=11
        false;
  }

  export function hash_md5(data: string | number, length = 6): string {
    data = <string>_.toString(data);
    let hash = 0, i, chr;
    if (data.length === 0)
      return hash.toString(16).padStart(length, '0').slice(-length);
    for (i = 0; i < data.length; i++) {
      chr = data.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(16).padStart(length, '0').slice(-length);
  }

  // Checks if the given bit is set on the num value
  export function bit_test(num: number, bit: number): boolean {
    return ((num >> bit) % 2 != 0)
  }

  // Sets the given bit on the num value
  export function bit_set(num: number, bit: number): number {
    return num | (1 << bit);
  }

  // Clears the given bit on the num value
  export function bit_clear(num: number, bit: number): number {
    return num & ~(1 << bit);
  }

  // Toggles the given bit on the num value
  export function bit_toggle(num: number, bit: number): number {
    return (bit_test(num, bit) ? bit_clear(num, bit) : bit_set(num, bit));
  }

  // Given a bitmasked-value, get true if any of the passed bits are true
  export function bitmask_test(bitmasked_value: number, bitmask: number): boolean {
    return (bitmasked_value & bitmask) != 0;
  }

  // Set the passed bitmask on the bitmasked_value, and return it.
  export function bitmask_set(bitmasked_value: number, bitmask: number): number {
    return bitmasked_value | bitmask;
  }

  // Unset the passed bitmask on the bitmasked_value, and return it.
  export function bitmask_clear(bitmasked_value: number, bitmask: number): number {
    return bitmasked_value & ~bitmask;
  }


  export function formatXml(xml) {
    if (typeof (xml) === 'undefined')
      return;
    var formatted = '';
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, '$1\r\n$2$3');
    var pad = 0;
    jQuery.each(xml.split('\r\n'), function (index, node) {
      var indent = 0;
      if (node.match(/.+<\/\w[^>]*>$/)) {
        indent = 0;
      } else if (node.match(/^<\/\w/)) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        indent = 1;
      } else {
        indent = 0;
      }

      var padding = '';
      for (var i = 0; i < pad; i++) {
        padding += '  ';
      }

      formatted += padding + node + '\r\n';
      pad += indent;
    });

    return formatted;
  }

  /**
   * Checks if all the object-keys of subset are contained and equal on haystack. considers null == undefined
   * @param subset object with key:values to search for.
   * @param haystack object that will be searched for the subset.
   * @param null_is_undefined considers null == undefined
   */
  export function obj_contained_in_obj(subset, haystack) {
    let subset_contained = true;
    _.forEach(subset, function (value, key) {
      if (!_.isNil(value) && !_.isNil(haystack) && value != haystack[key]) {
        // new/different entries
        subset_contained = false;
        return false;
      }
      if (_.isNil(value) && !_.isNil(haystack[key])) {
        // removing entries
        subset_contained = false;
        return false;
      }
    });
    return subset_contained;
  }

  export function objArrayDiff(a1, a2, property) {
    if (!Array.isArray(a1))
      return a2;
    if (!a1.hasOwnProperty(property) && !a2.hasOwnProperty(property))
      var o1 = {}, o2 = {}, i1 = {}, i2 = {}, diff = [], i, len, k;
    for (i = 0, len = a1.length; i < len; i++) {
      if (a1[i].hasOwnProperty(property)) {
        k = a1[i][property] + '';
        o1[k] = true;
        i1[k] = i;
      }
    }
    for (i = 0, len = a2.length; i < len; i++) {
      if (a2[i].hasOwnProperty(property)) {
        k = a2[i][property] + '';
        o2[k] = true;
        i2[k] = i;
      }
    }
    for (k in o1) {
      if (!(k in o2)) {
        diff.push(a1[i1[k]]);
      }
    }
    for (k in o2) {
      if (!(k in o1)) {
        diff.push(a2[i2[k]]);
      }
    }
    return diff;
  }

  /**
   * Removes an obj from an array of objects that matches a given property
   * @param array - Array of Objects to search for
   * @param obj - obj to compare its .property
   * @param property - obj.property to match
   * @returns [{*}]
   */
  export function removeFromArray(array, obj, property) {
    if (!Array.isArray(array) || !obj.hasOwnProperty(property))
      return;
    var i, len, k;
    var ok = obj[property];
    for (i = 0, len = array.length; i < len; i++) {
      if (array[i].hasOwnProperty(property)) {
        k = array[i][property];
        if (ok === k) {
          break;
        }
      }
    }
    //var idx = array.indexOf(obj);
    if (i < len) {
      array.splice(i, 1);
    }
    return array;
  }

  /**
   * Sort an array, inplace, based on multiple possible keys
   */
  export function sortByInPlace<T>(data: T[], keys: (string | number)[] = []): T[] {
    try {
      data.sort((a, b) => {
        let _a = a;
        let _b = b;
        if (!_.isEmpty(keys)) {
          for (let key of keys) {
            if (a.hasOwnProperty(key)) {
              _a = a[key]
            }
            if (b.hasOwnProperty(key)) {
              _b = b[key]
            }
          }
        }
        // if (_a === a || _b === b) {
        //   console.log("sortByInPlace warning, didn't find property", a, b)
        // }
        if (_a > _b) {
          return 1;
        } else if (_a < _b) {
          return -1;
        } else {
          return 0;
        }
      });
    } catch (e) {
      console.log("sortByInPlace error", e)
    }
    return data;
  }

  /**
   * Insert a new item into a sorted array, inplace, specifying multiple comparison key/property.
   * Returns the Index of the <= value
   */
  export function insertSortedByInPlace<T>(targetArray: T[], new_entry: any, keys: (string | number)[] = [], replace = true): number {
    let i = targetArray.length - 1;
    let current;
    try {
      let new_entry_key = new_entry;
      if (!_.isEmpty(keys)) {
        for (let key of keys) {
          if (new_entry.hasOwnProperty(key)) {
            new_entry_key = new_entry[key];
            break;
          }
        }
      }
      for (i = targetArray.length - 1; i >= 0; i--) {
        if (_.isNil(targetArray[i]))
          continue;
        current = targetArray[i];
        if (!_.isEmpty(keys)) {
          for (let key of keys) {
            if (targetArray[i].hasOwnProperty(key)) {
              current = targetArray[i][key];
              break;
            }
          }
        }
        if (current <= new_entry_key) {
          break;
        }
      }
      if (replace && current == new_entry_key) {
        targetArray.splice(i, 1, new_entry);
      } else {
        targetArray.splice(i + 1, 0, new_entry);
      }
    } catch (e) {
      console.log("insertSortedByInPlace error", e)
    }
    return i;
  }

  /**
   * Delete duplicate entries from a sorted array, specifying multiple comparison key/property
   */
  export function uniqBySortedInPlace<T>(data: T[], keys: (string | number)[] = []): T[] {
    try {
      let current, next;
      for (let i = 0; i < data.length - 1; i++) {
        current = data[i];
        next = data[i + 1];

        if (!_.isEmpty(keys)) {
          for (let key of keys) {
            if (data[i]?.hasOwnProperty(key)) {
              current = data[i][key]
            }
            if (data[i + 1]?.hasOwnProperty(key)) {
              next = data[i + 1][key]
            }
          }
        }
        if (current === next) {
          data.splice(i + 1, 1);
          i--; // array got shorter. test again new next entry
        }
      }
    } catch (e) {
      console.log("uniqBySortedInPlace error", e)
    }
    return data;
  }

  /**
   * Updates fields already on targetObj that are present on sourceObj and were changed.
   * Not recursive
   * @param targetObj
   * @param sourceObj
   * @param validKeys
   * @param excludedKeys - keys excluded. By default, dangerous and circular ones.
   */
  export function updateObjectPropertiesWithNewData(targetObj, sourceObj
      , validKeys = null
      , excludedKeys = ['place', 'places', 'unit', 'units', 'process', 'processes', 'device', 'devices', 'batch', 'batches']) {
    let _keys = angular.copy(validKeys);
    if (!_keys) {
      _keys = Object.keys(targetObj);
    }
    if (!_keys || !_keys.length) {
      if (WG_debug) console.log("Nothing to update");
      return;
    }
    angular.forEach(_keys, function (key) {
      if (!!excludedKeys && excludedKeys.includes(key)) {
        // if (WG_debug) console.log("Excluded key: " + key);
        return;
      }
      if (key in targetObj
          && key in sourceObj
          && !_.isEqual(targetObj[key], sourceObj[key])) {
        // Key exists in both and its value was changed
        if (WG_debug) console.log("Updated key: " + key, targetObj[key], sourceObj[key]);
        targetObj[key] = sourceObj[key];
      }
    });
  }

  /**
   * Creates targetArray if undefined.
   * Removes from targetArray[] entries with [key] missing from sourceObj.
   * Then updates targetArray[] with all data changed in sourceArray[] sharing the same [key], recursively
   * @param targetArray
   * @param sourceArray
   * @param key - key to use. Must be unique, else the behaviour is undefined
   * @param excludedKeys - keys excluded from deletion and analysis.
   * @returns Array containing the delete entries
   */
  export function synchronizeArrayOfObjects<T>(targetArray: T[], sourceArray: T[], key: string = 'id', excludedKeys: string[] = [], add_only = false) {
    // let excludedArray = [];
    if (_.isNil(targetArray)) {
      targetArray = [];
      // console.error("Nil array passed");
      // return excludedArray;
    }
    _.forEach(_.cloneDeep(targetArray), function (entry, i) {
      if (_.isNil(entry[key])) {
        return;
      }

      // Delete duplicates from targetArray
      if (!add_only) {
        let target_last_i = _.findLastIndex(targetArray, (_entry) => {
          return _entry[key] == entry[key];
        });
        if (i != target_last_i && target_last_i > 0) {
          targetArray.splice(target_last_i, 1);
          return;
        }
      }

      // Delete entries not existing in sourceArray, else Merge
      let source_i = _.findIndex(sourceArray, (_entry) => {
        return _entry[key] == entry[key];
      });
      if (source_i < 0) {
        if (!add_only) {
          // excludedArray.push(entry);
          targetArray.splice(i, 1);
        }
        return;
      } else {
        _.assign(targetArray[i], sourceArray[source_i]);
        return;
      }
    });

    // Add new entries (to the same position)
    _.forEach(sourceArray, function (entry, i) {
      if (_.isNil(entry[key])) {
        return;
      }
      let target_i = _.findIndex(targetArray, (_entry) => {
        return _entry[key] == entry[key];
      });
      if (target_i < 0) {
        targetArray.splice(i, 0, entry);
        return;
      }
    });
    return targetArray;
    // return excludedArray;
  }

  /**
   * Creates targetObj if undefined.
   * Removes from targetObj properties missing from sourceObj.
   * Then updates targetObj with all data changed in sourceObj, recursively
   * @param targetObj
   * @param sourceObj
   * @param excludedKeys - keys excluded from deletion and analysis.
   * @returns the targetObj creating if necessary
   */
  export function synchronizeObjects(targetObj: object, sourceObj: object, excludedKeys = []): object {
    if (_.isNil(targetObj)) {
      targetObj = {};
    }
    angular.forEach(Object.keys(targetObj), function (key) {
      if (excludedKeys.includes(key)
          || !(key in targetObj)) {
        return;
      }
      if (!(key in sourceObj)) {
        // Key only exists in target. Delete
        delete targetObj[key];
      }
    });
    _.assign(targetObj, sourceObj);
    return targetObj;
  }

  /***
   * Checks if passed var is empty: undefined, null, NaN, [], {}, ''
   * @param value: variable to test
   */
  export function isEmptyValues(value) {
    return value === undefined
        || value === null
        || (typeof value === 'number' && isNaN(value))
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0);
  }

  /**
   * Returns the given array but empty, creating a new array if it doesn't exist.
   * Keeps the reference, and therefore is Angular-friendly
   * @param array to clean or create
   * @returns {*[]}
   */
  export function emptyOrCreateArray<T>(array: T[]): T[] {
    if (array && Array.isArray(array))
      array.length = 0;
    else
      array = [];
    return array;
  }

  /**
   * Returns the given dict but empty, creating a new one if it doesn't exist.
   * @param dict to clean or create
   * @returns {*[]}
   */
  export function emptyOrCreateDict<T>(dict: T): T {
    if (dict && typeof dict === 'object') {
      Object.keys(dict).forEach(function (key) {
        delete dict[key];
      });
    } else {
      dict = {} as T;
    }
    return dict;
  }

  export function stringStartsWith(string: string, prefix: string) {
    return string.slice(0, prefix.length) == prefix;
  }

  // checks if `array` contains only equal elements
  export function compareAllPairs(array: any[]): boolean {
    for (let i = 0; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (!_.isEqual(array[i], array[j])) {
          return false;
        }
      }
    }
    return true;
  }


  function version_parse(version: string): false | { major: number, minor: number, build: number } {
    if (_.isEmpty(version)) {
      return false;
    }

    let version_arr = version.split('.');
    var maj = parseInt(version_arr[0]) || 0;
    var min = parseInt(version_arr[1]) || 0;
    var rest = parseInt(version_arr[2]) || 0;

    return {
      major: maj,
      minor: min,
      build: rest
    }
  }

  export function vprog_parse(str: string): false | { major: number, minor: number, build: number } {
    if (typeof (str) != 'string') {
      return false;
    }
    let v_parsed = str.match("^(?:([a-zA-Z0-9]+)_)?(?:([a-zA-Z0-9]+)_)?([a-zA-Z])?(\\d+(?:\\.\\d+)*)(?:_(.*))?$");

    if (_.isEmpty(v_parsed) || _.isEmpty(v_parsed[4])) {
      return false;
    }

    // let product = v_parsed[1];
    // let type = v_parsed[2];
    // let build_v = v_parsed[3];
    // let version = v_parsed[4];
    // let build_date = v_parsed[5];

    return version_parse(v_parsed[4]);
  }

  /**
   * Checks if given VPROG is greater-or-equal than target_version
   */

  export function vprog_gte(VPROG: string, target_version: string) {
    if (!VPROG) {
      return false;
    }
    let _version = vprog_parse(VPROG);
    if (!_version) {
      return false;
    }
    // if (_version['version']) {
    //   _version = _version['version'];
    // }

    let _target = vprog_parse(target_version);
    if (!_target) {
      return false;
    }

    if (_version.major > _target.major) {
      return true;
    }

    if (_version.major == _target.major &&
        _version.minor > _target.minor) {
      return true;
    }

    if (_version.major == _target.major && _version.minor == _target.minor &&
        _version.build >= _target.build) {
      return true;
    }

    return false;
  }

// to fix safari storage problem
// https://gist.github.com/philfreo/68ea3cd980d72383c951
// implement memory store spec'd to Storage prototype
  (function (window) {
    var items = {};

    function MemoryStorage() {
    }

    MemoryStorage.prototype.getItem = function (key) {
      return items[key];
    };

    MemoryStorage.prototype.removeItem = function (key) {
      delete items[key];
    };

    MemoryStorage.prototype.setItem = function (key, value) {
      items[key] = value;
    };

    MemoryStorage.prototype.key = function (index) {
      return Object.keys(items)[index];
    };

    MemoryStorage.prototype.get = function () {
      return items;
    };

    Object.defineProperty(MemoryStorage.prototype, "length", {
      get: function length() {
        return Object.keys(items).length;
      }
    });

    window['memoryStorage'] = new MemoryStorage();
  })(window);

// helper function to swap to memory storage

  export function getLocalStorage(storage) {
    var x = '__storage_test__';
    try {
      storage.setItem(x, x);
      storage.removeItem(x);
      return storage;
    } catch (e) {
      return getLocalStorage.prototype.FALLBACK_STORAGE;
    }
  }

  getLocalStorage.prototype.FALLBACK_STORAGE = window['memoryStorage'];

}

let select = wg.select;
let parseData = wg.parseData;
let scroll_to = wg.scroll_to;
let spitData = wg.spitData;
let isMobile = wg.isMobile;
let isMobileOrTablet = wg.isMobileOrTablet;
let isTouch = wg.isTouch;
let hash_md5 = wg.hash_md5;

let objArrayDiff = wg.objArrayDiff;
// let synchronizeObjects = wg.synchronizeObjects;
let isEmptyValues = wg.isEmptyValues;
let emptyOrCreateArray = wg.emptyOrCreateArray;
let emptyOrCreateDict = wg.emptyOrCreateDict;
let obj_contained_in_obj = wg.obj_contained_in_obj;
let stringStartsWith = wg.stringStartsWith;