/**
 * Created by pmec on 23/12/14.
 */
// Used in /app/manage/devices, /app/manage/smartbox
namespace wg {
  interface ILocalScope {
    smartboxes?: IDevice[];
    devices?: IDevice[];

    [key: string]: any;
  }

  App.controller('HomeController', ['$rootScope', '$state', '$scope', '$interval', 'ngDialog', 'DataUtils', 'WGApiData',
    function ($rootScope: IRootScope, $state: _IState, $scope: ILocalScope, $interval, ngDialog: angular.dialog.IDialogService, DataUtils: DataUtils, WGApiData: WGApiData) {
      // @ts-ignore
      console.log("HOMEController SPEAKING, URL IS:", $state.current.url.slice?.(1));
      let sensorsON = 0;
      let sensorsOFF = 0;
      let sensorsTOTAL = 0;

      let smartboxON = 0;
      let smartboxOFF = 0;
      let smartboxTOTAL = 0;

      $scope.isHelpCollapsed = {
        sites: true,
        time: true,
        more: true
      };
      $scope.toggleHelp = {
        sites: false,
        time: false,
        dynf: false,
        desc: false,
      };
      $scope.smartboxes = [];
      $scope.devices = [];
      var safeDeleteThisVar = true;
      let hasOrderedDevicesOnce = false;

      $scope.selectedDevice = {
        uuid: null,
        name: null,
        id: null,
        link: "#",
        set: function (obj) {
          if (this.uuid === obj.uuid) {
            this.uuid = null;
            this.name = null;
            this.id = null;
          } else {
            _.assign(this, obj);
          }
        },
        view: function (id) {
          $state.go('app.devices.units', {'device': this.uuid});
        },
        edit: function () {
          alert('implement this de#343rg5bw45rgw4rg');
        },
        logDevice: function () {
          console.log("selected device is:", _.find($scope.devices, {uuid: this.uuid}))
        }
      };

      $scope.sensorsON = 0;
      $scope.sensorsOFF = 0;
      $scope.sensorsTOTAL = 0;

      $scope.smartboxON = 0;
      $scope.smartboxOFF = 0;
      $scope.smartboxTOTAL = 0;

      // Run whenever devices change

      let unhook = $rootScope.$watch('WGApiData.AllReady', function () {
        if (!$rootScope.WGApiData.AllReady) {
          return;
        }
        // $rootScope.$on('devices_updated', function (event, args) {
        unhook();
        run_once();
        //console.log("roort", $rootScope.lastKnownMessages);
      }, true);

      let interval = $interval(run, 10000);


      function run_once() {
        for (let device of WGApiData.WGDevices.devices) {
          if (SMARTBOX_MODELS.includes(device.model.toUpperCase())) {
            $scope.smartboxes.push(device);
          } else {
            $scope.devices.push(device);
            if (device.model.toUpperCase() == "PRESS_BOX") {
              // Add press_box in both places
              $scope.smartboxes.push(device);
            }
          }
        }
        run();
      }

      function run() {
        // console.log($rootScope.userDevices);

        sensorsON = 0;
        sensorsOFF = 0;
        sensorsTOTAL = 0;

        smartboxON = 0;
        smartboxOFF = 0;
        smartboxTOTAL = 0;

        let _current_time = new Date().getTime();

        // TODO: Change this to cycle through Devices, not LKMs (Virtual_Boxes don't have LKMs, etc)
        _.forEach($rootScope.lastKnownMessages, function (lkm, uuid) {

          if (!angular.isDefined(lkm)) {
            return;
          }
          if (Object.keys(lkm).length < 1) {
            return;
          }

          let _device = $scope.smartboxes.find(object => object.uuid === uuid) || $scope.devices.find(object => object.uuid === uuid);

          // if (_device.model === 'virtual_smartbox') {
          //   smartboxON++;
          //   return;
          // }

          // false = device is a smartbox / GateWay
          if (SMARTBOX_MODELS.includes(_device.model.toUpperCase())) {
            if (_device.status === "ON" || _device.status === "FAULT")
              smartboxON++;
            else
              smartboxOFF++;
          } else {
            if (_device.status === "ON" || _device.status === "FAULT")
              sensorsON++;
            else
              sensorsOFF++;
          }
        });

        if (!hasOrderedDevicesOnce) {
          // sort devices by name and then by status
          // so that off devices show up first?
          // console.log("missing");
          hasOrderedDevicesOnce = true;
        }

        sensorsTOTAL = sensorsON + sensorsOFF;
        smartboxTOTAL = smartboxON + smartboxOFF;

        // console.log('lastKnownMessages.length', Object.keys($rootScope.lastKnownMessages).length);
        // console.log('userDevices.length', $rootScope.userDevices.length);
        // console.log('TOTAL', sensorsTOTAL + smartboxTOTAL, $scope.devices.length + $scope.smartboxes.length);
        // console.log('sensorsTOTAL', sensorsTOTAL, $scope.devices.length);
        // console.log('smartboxTOTAL', smartboxTOTAL, $scope.smartboxes.length);

        if (sensorsTOTAL + smartboxTOTAL !== $scope.devices.length + $scope.smartboxes.length) {
          // console.log('check#43t645hdfg6gd5n6bv5AA+'+
          //   sensorsTOTAL +'+'+ smartboxTOTAL +'+'+  $scope.devices.length +'+'+ $scope.smartboxes.length);
          // console.log('Count not ok');
          // console.log('sensorsTOTAL', sensorsTOTAL);
          // console.log('smartboxTOTAL', smartboxTOTAL);
          // console.log('devices.length', $scope.devices.length);
          // console.log('smartboxes.length', $scope.smartboxes.length);

          if (sensorsTOTAL < $scope.devices.length) {
            sensorsOFF = sensorsOFF + $scope.devices.length - sensorsTOTAL;
          }

          if (smartboxTOTAL < $scope.smartboxes.length) {
            smartboxOFF = smartboxOFF + $scope.smartboxes.length - smartboxTOTAL;
          }

          sensorsTOTAL = sensorsON + sensorsOFF;
          smartboxTOTAL = smartboxON + smartboxOFF;
        }
        if (sensorsTOTAL + smartboxTOTAL !== $scope.devices.length + $scope.smartboxes.length) {
          // console.log('Count not ok again');
          console.log('check#43t645hdfg6gd5n6bv5AB+', sensorsTOTAL, smartboxTOTAL, $scope.devices.length, $scope.smartboxes.length);
        }

        $scope.sensorsON = sensorsON;
        $scope.sensorsOFF = sensorsOFF;
        $scope.smartboxON = smartboxON;
        $scope.smartboxOFF = smartboxOFF;

        $scope.sensorsTOTAL = sensorsTOTAL;
        $scope.smartboxTOTAL = smartboxTOTAL;
      }

      $scope.edit = function (device) {
        // console.log('edit', device);
        ngDialog.open(
            {
              template: 'app/views/modals/edit-device.html',
              controller: "EditDeviceModalInstance",
              data: {
                device: device,
                result: 'selectedUnitEditDevice',
                reload: {
                  state: 'app.home'
                }
              },
            }
        );

      };
      $scope.$on('$destroy', function () {
        $interval.cancel(interval);
      });


    }
  ])
  ;
}