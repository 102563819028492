/**
 * Created by pmec on 04/02/16.
 */
namespace wg {
  App.controller("ProfileController", ['$rootScope', '$scope', '$http', '$location', '$timeout', 'ngDialog', '$translate', 'AuthService', 'DataUtils',
    function ($rootScope: IRootScope, $scope, $http: ng.IHttpService, $location: ng.ILocationService, $timeout: ng.ITimeoutService, ngDialog: angular.dialog.IDialogService, $translate: ng.translate.ITranslateService, AuthService: IAuthService, DataUtils) {

      if (!AuthService.isLogged) {
        // console.error('User logged out');
        return;
      }

      // User Details
      {
        $scope.dataDetails = {};
        angular.extend($scope.dataDetails, AuthService.user);

        let updateDetailsError = null;
        $scope.updateDetails = function () {
          $scope.updateDetailsLoad = true;
          $scope.updateDetailsResult = '';

          // var data = angular.copy($scope.dataDetails);
          var data = {
            id: $scope.dataDetails.id,
            username: $scope.dataDetails.username,
            full_name: $scope.dataDetails.full_name,
            email: $scope.dataDetails.email,
            phone_number: $scope.dataDetails.phone_number,
          };
          // var configs = angular.copy(AuthService.user.configs);
          // data.configs = JSON.stringify(configs);
          console.log('Update user details', data);

          $http.patch('api/auth/me/', data).then(
              function (response: ng.IHttpPromiseCallbackArg<IUser>) {
                $scope.updateDetailsLoad = false;
                $scope.updateDetailsResult = 'success';
                console.log("User details updated");
                if (WG_debug) console.log(response.data);
                // This comes without configs?? Update them here.
                // angular.extend(response.data.configs, AuthService.user.configs);
                AuthService.setUser(response.data);
                angular.extend($scope.dataDetails, AuthService.user);
                $scope.dataPass.username = AuthService.user.username;
                if (updateDetailsError !== null) {
                  for (var key in updateDetailsError) {
                    var specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
              }, function (response) {
                $scope.updateDetailsLoad = false;
                $scope.updateDetailsResult = 'error';
                console.error(response);
                var key, specificField;
                if (updateDetailsError !== null) {
                  for (key in updateDetailsError) {
                    specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
                if (response.status === 400 && response.data) {
                  updateDetailsError = response.data;
                  for (key in response.data) {
                    specificField = $('#id-' + key).parsley();
                    var i = 0;
                    angular.forEach(response.data[key], function (error) {
                      try {
                        specificField.addError('error-' + i, {message: error});
                        // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                      } catch (e) {
                      }
                      i++;
                    });
                  }
                }
              });
        };
      }

      // User Pass
      {
        $scope.dataPass = {username: AuthService.user.username};

        let updatePassError = null;
        $scope.updatePass = function () {
          $scope.updatePassLoad = true;
          $scope.updatePassResult = '';
          $http.put('api/auth/me/password/', $scope.dataPass).then(
              function (response) {
                $scope.updatePassLoad = false;
                $scope.updatePassResult = 'success';
                console.log("Password changed");
                // No need to AuthService.setUser, only password changed.
                // AuthService.setUser(response.data);
                if (updatePassError !== null) {
                  for (var key in updatePassError) {
                    var specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
              }, function (response) {
                $scope.updatePassLoad = false;
                $scope.updatePassResult = 'error';
                console.error(response);
                var key, specificField;
                if (updatePassError !== null) {
                  for (key in updatePassError) {
                    specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
                if (response.status === 400 && response.data) {
                  updatePassError = response.data;
                  for (key in response.data) {
                    specificField = $('#id-' + key).parsley();
                    var i = 0;
                    angular.forEach(response.data[key], function (error) {
                      try {
                        specificField.addError('error-' + i, {message: error});
                        // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                      } catch (e) {
                      }
                      i++;
                    });
                  }
                }
              });
        };
      }

      // Share Account
      $scope.shareAccount = {
        loading: false,
        result: '',
        share: function () {
          if (WG_debug) console.log('Share Account openModal');
          $scope.shareAccount.loading = true;
          $scope.shareAccount.result = "";
          ngDialog.openConfirm({
            template: 'app/views/modals/share-account.html',
            className: 'ngdialog-theme-default',
            data: {},
          }).then((data) => {
            console.log('Share Account success');
            $scope.shareAccount.loading = false;
            $scope.shareAccount.result = "success";
            $timeout(function () {
              $scope.shareAccount.result = '';
            }, 2000);
            if (WG_debug) console.log('Share Account success. Data:', data);
          }, (reason) => {
            console.log('Share Account failed. Reason:', reason);
            $scope.shareAccount.loading = false;
            $scope.shareAccount.result = "error";
            $timeout(function () {
              $scope.shareAccount.result = '';
            }, 2000);
          });
        },
      }

      // Conversions
      {
        // Default units received from backend, (SI)
        // Replaced by user.configs.conversions
        $scope.userSelectedConversions = _.assign({
          temperature: 'celsius',
          distance: 'mm',
          pressure: 'bar',
          volume: 'liter',
          flow: 'liter/min',
          mass_density: 'g/cm3',
          fermentation_kinetic: 'g/cm3/day',
        }, AuthService.user.configs.conversions);

        $scope.conversionsAvailable = {
          temperature: [
            {id: 'celsius', unit: 'ºC', name: 'Celsius'},
            {id: 'fahrenheit', unit: 'ºF', name: 'Fahrenheit'},
          ],
          distance: [
            {id: 'mm', unit: 'mm', name: 'Millimetre', name_sref: 'app.profile.conversions.MILLIMETER'},
            {id: 'inches', unit: 'in', name: 'Inch', name_sref: 'app.profile.conversions.INCHES'},
          ],
          pressure: [
            {id: 'bar', unit: 'bar', name: 'bar'},
            {id: 'psi', unit: 'psi', name: 'Pounds per square inch', name_sref: 'app.profile.conversions.PSI'},
          ],
          volume: [
            {id: 'liter', unit: 'L', name: 'Liters', name_sref: 'app.profile.conversions.LITER'},
            {id: 'hectoliter', unit: 'hL', name: 'Hectoliters', name_sref: 'app.profile.conversions.HECTOLITER'},
            {id: 'gallon', unit: 'gal', name: 'Gallons', name_sref: 'app.profile.conversions.GALLONS'},
          ],
          flow: [
            {
              id: 'liter/min',
              unit: 'L/min',
              name: 'Liters per minute',
              unit_sref: 'app.profile.conversions.unit.LITER_MIN',
              name_sref: 'app.profile.conversions.LITER_MIN'
            },
            {
              id: 'gallon/min',
              unit: 'gal/min',
              name: 'Gallons per minute',
              unit_sref: 'app.profile.conversions.unit.GALLONS_MIN',
              name_sref: 'app.profile.conversions.GALLONS_MIN'
            },
            {
              id: 'hl/h',
              unit: 'hl/h',
              name: 'Hectoliter per hour',
              unit_sref: 'app.profile.conversions.unit.HECTOL_HOUR',
              name_sref: 'app.profile.conversions.HECTOL_HOUR'
            },
          ],
          mass_density: [
            {id: 'g/cm3', unit: 'g/cm³', name: 'Gram per cubic centimeter', name_sref: 'app.profile.conversions.G_CM3'},
            {id: 'kg/m3', unit: 'kg/m³', name: 'Kilogram per cubic meter', name_sref: 'app.profile.conversions.KG_M3'},
            {
              id: 'brix_jacob',
              unit: 'ºBx',
              name: 'Degrees Brix (Jacobson)',
              name_sref: 'app.profile.conversions.BRIX_JACOBSON'
            },
            {id: 'baume', unit: 'ºBé', name: 'Baumé', name_sref: 'app.profile.conversions.BAUME'},
            // {id: 'brix_wiki', unit: 'ºBx', name: 'Degrees Brix (Wikipedia)', name_sref: 'app.profile.conversions.BRIX_WIKI'},
            // {id: 'baume_generic', unit: 'ºBé', name: 'Baumé'},
            // {id: 'baume_taylors', unit: 'ºBé T', name: 'Baumé (Taylor\'s)'},
            //   {id: 'g/mL',  unit: 'g/mL',   name: 'Gram per milliliter'},
            //   {id: 'kg/m3',  unit: 'kg/m³',  name: 'Kilogram per cubic meter'},
            //   {id: 'kg/L',  unit: 'kg/L',   name: 'Kilogram per liter'},
            //   {id: 'lb/ft3', unit: 'lb/ft³', name: 'pound per cubic foot'},
            //   {id: 'lb/in3', unit: 'lb/in³', name: 'pound per cubic inch'},
          ],
          fermentation_kinetic: [
            {
              id: 'g/cm3/day',
              unit: 'g/cm³/day',
              name: 'Gram per cubic centimeter per day',
              name_sref: 'app.profile.conversions.G_CM3_DAY'
            },
            {
              id: 'kg/m3/day',
              unit: 'kg/m³/day',
              name: 'Kilogram per cubic meter per day',
              name_sref: 'app.profile.conversions.KG_M3_DAY'
            },
            {
              id: 'baume/day',
              unit: 'Baume/day',
              name: 'Degrees Baume per day',
              name_sref: 'app.profile.conversions.BAUME_DAY'
            },
            {
              id: 'brix/day',
              unit: 'Brix/day',
              name: 'Degrees Brix per day',
              name_sref: 'app.profile.conversions.BRIX_DAY'
            },
          ],
        };
        // Show General baume's formula to some accounts, and Esporão's to them
        if (AuthService.canAccess('admin')
            || AuthService.user.uuid === '181e6f25-b09e-4571-8fd9-9e882dcd63ad') { // Esporao
          $scope.conversionsAvailable.mass_density.push({
            id: 'baume_alt',
            unit: 'ºBé',
            name: 'Baumé (alternative)',
            name_sref: 'app.profile.conversions.BAUME_ALT'
          });
        }

        // if (AuthService.canAccess('admin')) {
        //   $scope.conversionsAvailable.mass_density.push({id: 'brix_wiki', unit: 'ºBx', name: 'Degrees Brix (Wikipedia)', name_sref: 'app.profile.conversions.BRIX_WIKI'});
        // }

        var updateConversionsError = null;
        $scope.updateSelectedConversions = function () {
          $scope.updateConversionsLoad = true;
          $scope.updateConversionsResult = '';


          let configs = _.cloneDeep(AuthService.user.configs);
          _.assign(configs.conversions, $scope.userSelectedConversions);
          configs.custom_overview = _.map(configs.custom_overview, parameter => {
            return {
              internal_name: parameter.internal_name,
              // show_last: parameter.show_last,
            };
          });


          let data = {
            configs: JSON.stringify(configs)
          };
          console.log('updateSelectedConversions', data);
          $http.patch('api/auth/me/', data).then(
              function (response: ng.IHttpPromiseCallbackArg<IUser>) {
                $scope.updateConversionsLoad = false;
                $scope.updateConversionsResult = 'success';
                $timeout(function () {
                  $scope.updateConversionsResult = '';
                }, 2000);
                console.log("Desired conversions updated");

                $rootScope.WGSensors.reset();
                $rootScope.WGDevices.reset();

                if (WG_debug) console.log(response.data);
                AuthService.setUser(response.data);
                _.assign($scope.dataDetails, AuthService.user);
                // $route.reload();
                $rootScope.reload();
                if (updateConversionsError !== null) {
                  for (var key in updateConversionsError) {
                    var specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
              }, function (response) {
                $scope.updateConversionsLoad = false;
                $scope.updateConversionsResult = 'error';
                $timeout(function () {
                  $scope.updateConversionsResult = '';
                }, 2000);
                console.error(response);
                var key, specificField;
                if (updateConversionsError !== null) {
                  for (key in updateConversionsError) {
                    specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
                if (response.status === 400 && response.data) {
                  updateConversionsError = response.data;
                  for (key in response.data) {
                    specificField = $('#id-' + key).parsley();
                    var i = 0;
                    angular.forEach(response.data[key], function (error) {
                      try {
                        specificField.addError('error-' + i, {message: error});
                        // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                      } catch (e) {
                      }
                      i++;
                    });
                  }
                }
              });
        };
      }

      // Overview Customization
      {
        $scope.customizationsSortableOptions = {
          placeholder: "sortable-parameter",
          connectWith: ".linked-sortable-lists",
        };
        $scope.customizationParametersAvailable = [];
        $scope.customizationParametersSelected = [];

        $scope.addParameter = function (parameter) {
          let obj = _.remove($scope.customizationParametersAvailable, {internal_name: parameter.internal_name});
          if (!_.isEmpty(obj))
            $scope.customizationParametersSelected.push(...obj);
        }
        $scope.removeParameter = function (parameter) {
          let obj = _.remove($scope.customizationParametersSelected, {internal_name: parameter.internal_name});
          if (!_.isEmpty(obj))
            $scope.customizationParametersAvailable.push(...obj);
        }
        $scope.reloadParameters = function (reset = false) {
          $scope.customizationParametersAvailable = [];
          $scope.customizationParametersSelected = [];
          getOverviewCustomizations(reset);
        }

        $scope.shiftParameter = function (parameter, direction) {
          let index = _.findIndex($scope.customizationParametersSelected, {internal_name: parameter.internal_name});
          if (direction === 'up' && index > 0) {
            let _tmp = $scope.customizationParametersSelected[index - 1];
            $scope.customizationParametersSelected[index - 1] = parameter;
            $scope.customizationParametersSelected[index] = _tmp;
          }
          if (direction === 'down' && index < _.size($scope.customizationParametersSelected) - 1) {
            let _tmp = $scope.customizationParametersSelected[index + 1];
            $scope.customizationParametersSelected[index + 1] = parameter;
            $scope.customizationParametersSelected[index] = _tmp;
          }
        }

        // Populates arrays required for showing/editing in profile page
        function getOverviewCustomizations(reset = false) {

          if (WG_debug) console.debug("Profile getOverviewCustomizations");
          if (!_.isEmpty($scope.customizationParametersSelected) || !_.isEmpty($scope.customizationParametersAvailable)) {
            if (WG_debug) console.warn("Customization arrays have already been initialized.");
            return;
          }

          // let _list_before_assign = $rootScope.cardview_sensors_list;
          // if (WG_debug && !_.isEqual(_list_before_assign, _.map(AuthService.user.configs.custom_overview, 'internal_name'))) {
          //   console.warn("Lists diverge, In-use from Configured", _list_before_assign, _.map(AuthService.user.configs.custom_overview, 'internal_name'));
          // }
          if (reset)
            $rootScope.cardview_sensors_list = CARDVIEW_SENSORS_LIST;

          if (!$rootScope.WGSensors.ready) {
            let _unhook = $rootScope.$on('sensors_updated', function (event, args) {
              _unhook();
              getOverviewCustomizations(reset);
            });
            return;
          }

          let ordered_params = _.concat($rootScope.cardview_sensors_list, TABLEVIEW_SENSORS_LIST);
          if ($rootScope.WG_is_beta) {
            ordered_params.push('MESHVINES_SIMULATOR_EthInh'
                , 'MESHVINES_SIMULATOR_Viability'
                , 'MESHVINES_SIMULATOR_InitialNitrogen'
                , 'MESHVINES_SIMULATOR_Lag'
                , 'MESHVINES_SIMULATOR_Maintenance');
          }

          ordered_params = _.uniq(ordered_params);

          _.forEach(ordered_params, internal_name => {
            if (!DataUtils.canUserAccessSensor(internal_name))
              return;

            // We merge both QL_TEMP and TEMP into one
            if (internal_name === 'QL_TEMP')
              return;

            // We merge both QL_TEMP and TEMP into one
            if (internal_name === 'IN_HUMF')
              return;

            let _root_sensor = $rootScope.WGSensors.sensors_name[internal_name];
            if (!_root_sensor && internal_name === 'MANUAL_Entries') {
              _root_sensor = {
                id: 0,
                internal_name: internal_name,
                name_sref: 'app.devices.individual.MANUAL_ENTRY',
                name: $translate.instant('app.devices.individual.MANUAL_ENTRY'),
                stream: internal_name,
                configs: {
                  icon: 'icon-wg-manual-entry',
                  graph: true,
                  manual: true,
                  accessLevel: "user"
                },
              };
            }

            // TODO: Implement this "show_last"
            let _param = {
              internal_name: internal_name,
              // show_last: false,
              sensor: _root_sensor,
            };

            if (reset || _.isEmpty(AuthService.user.configs.custom_overview)) { // Use default config
              if (_.includes(CARDVIEW_SENSORS_LIST, internal_name)) {
                $scope.customizationParametersSelected.push(_param);
              } else {
                $scope.customizationParametersAvailable.push(_param);
              }
            } else { // User has a previous config
              let _config = _.find(AuthService.user.configs.custom_overview, {internal_name: internal_name});
              if (_config) {
                _.defaults(_config, _param); // Fills "sensor" and other fields
                $scope.customizationParametersSelected.push(_config);
              } else {
                $scope.customizationParametersAvailable.push(_param);
              }
            }
          });

          // if (WG_debug) {
          //   let _list_after_assign = _.map($scope.customizationParametersSelected, 'internal_name');
          //   if (!_.isEqual(_list_before_assign, _list_after_assign)) {
          //     console.warn("Parameter lists were changed!", _list_before_assign, _list_after_assign);
          //   }
          // }
        }

        let unhook = $rootScope.$on('sensors_updated', function (event, args) {
          unhook(); // cancels watcher
          getOverviewCustomizations();
        });

        getOverviewCustomizations();


        let updateCustomizationError = null;
        $scope.updateCustomization = function () {
          $scope.updateCustomizationLoad = true;
          $scope.updateCustomizationResult = '';

          var configs = angular.copy(AuthService.user.configs);
          // var configs = {};
          // angular.extend(configs, AuthService.user.configs);
          // configs.custom_overview = $scope.customizationParametersSelected;
          // Shallowify
          configs.custom_overview = _.map($scope.customizationParametersSelected, parameter => {
            return {
              internal_name: parameter.internal_name,
              // show_last: parameter.show_last,
            };
          })
          var data = {
            configs: JSON.stringify(configs)
          };
          // console.log('updateCustomization', data);
          $http.patch('api/auth/me/', data).then(
              function (response: ng.IHttpPromiseCallbackArg<IUser>) { // Success
                $scope.updateCustomizationLoad = false;
                $scope.updateCustomizationResult = 'success';
                $timeout(function () {
                  $scope.updateCustomizationResult = '';
                }, 2000);

                console.log("Overview Customization saved");
                if (WG_debug) console.log(response.data);
                AuthService.setUser(response.data); // Also saves new "custom_overview" to AuthService.user.configs and rebuilds $rootScope.cardview_sensors_list
                angular.extend($scope.dataDetails, AuthService.user);

                $scope.reloadParameters();
                _.forEach($rootScope.WGDevices.devices, function (device) {
                  DataUtils.update_device_card_parameters(device, true);
                });

                // $rootScope.reload();
              }, function (response) { // Error
                $scope.updateCustomizationLoad = false;
                $scope.updateCustomizationResult = 'error';
                $timeout(function () {
                  $scope.updateCustomizationResult = '';
                }, 2000);
                $scope.reloadParameters();
                console.error(response);
                var key, specificField;
                if (updateCustomizationError !== null) {
                  for (key in updateCustomizationError) {
                    specificField = $('#id-' + key).parsley();
                    specificField.reset();
                  }
                }
                if (response.status === 400 && response.data) {
                  updateCustomizationError = response.data;
                  for (key in response.data) {
                    specificField = $('#id-' + key).parsley();
                    var i = 0;
                    angular.forEach(response.data[key], function (error) {
                      try {
                        specificField.addError('error-' + i, {message: error});
                        // window.ParsleyUI.addError(specificField, 'error-' + i, error);
                      } catch (e) {
                      }

                      i++;
                    });
                  }
                }
              });
        };
      }

    }
  ])
  ;
}