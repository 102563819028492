/**
 * Created by pmec on 12/01/16.
 */
namespace wg {

  interface IScope extends ng.IScope {

    ngDialogData: {
      device: IDevice,
      // TODO: implement multiple devices alarm
      devices: IDevice[],
      // Sensor internal_name to use
      sensor: string,
      sensors: ISensor[],
      // Key in parent scope to store result
      result: string,
    };

    // Form data
    data: any;

    // Selected device
    device: Partial<IDevice>;
    // TODO: implement multiple devices alarm
    // devices: IDevice[],
    // List of possible sensors to choose from
    sensors: ISensor[];
    // Selected sensor internal_name
    sensor: string;

    alarms_loading: boolean;
    alarms: IAlarm[];
    ret: IReturnResult,
    availableOptions: IAlarmRuleOptions[],
    actions_available: IAlarm['actions'],

  }

  App.controller('AlarmModalInstance', ['$rootScope', '$scope', '$http', '$filter', 'ngDialog', 'moment', '$translate', 'AuthService', 'WGApiData', 'DataUtils',
    function ($rootScope: IRootScope, $scope: IScope, $http: ng.IHttpService, $filter: ng.IFilterService, ngDialog: angular.dialog.IDialogService, moment, $translate: ng.translate.ITranslateService, AuthService: IAuthService, WGApiData: WGApiData, DataUtils: DataUtils) {

      let parentRet: IReturnResult = {
        loading: false,
        result: '',
        message: '',
      };
      $scope.ret = parentRet;

      let ret_key = $scope.ngDialogData?.result || 'alarmRet';
      if ($scope.$parent?.[ret_key]) {
        $scope.$parent[ret_key] = parentRet;
      } else if ($scope.$parent?.$parent?.[ret_key]) {
        $scope.$parent.$parent[ret_key] = parentRet;
      }


      activate();

      function activate() {
        // console.log('AlarmModalInstance activate');
        $scope.actions_available = {
          email: true,
          sms: false,
          neo_pixel_rgb: false,
        };
        $scope.spectrum_color_picker_options = {
          theme: 'sp-wg',
          hideAfterPaletteSelect: true,
          allowEmpty: false,
          showPalette: true,
          clickoutFiresChange: false,
          showPaletteOnly: true,
          togglePaletteOnly: true,
          togglePaletteMoreText: '+',
          togglePaletteLessText: '-',
          palette: [
            ['#FF0000', '#FF9900', '#ffff00'],
            ['#00FF00', '#00FFFF', '#0000FF'],
            ['#9900FF', '#FF00FF', '#FFFFFF']
          ],
          cancelText: $translate.instant('app.common.CANCEL'),
          chooseText: $translate.instant('spectrum_color_picker.chooseText'),
          clearText: $translate.instant('spectrum_color_picker.clearText'),
          noColorSelectedText: $translate.instant('spectrum_color_picker.noColorSelectedText'),
          disabled: false
        };

        $scope.device = {
          type: 'device',
          name: $scope.ngDialogData.device.name,
          sn: $scope.ngDialogData.device.sn,
          id: $scope.ngDialogData.device.id,
          iid: $scope.ngDialogData.device.iid,
          uuid: $scope.ngDialogData.device.uuid,
          path: $scope.ngDialogData.device.path,
          configs: $scope.ngDialogData.device.configs,
        }
        $scope.sensors = _.uniq($scope.ngDialogData.sensors);

        if (_.isEmpty($scope.sensors)) {
          $scope.sensors = emptyOrCreateArray($scope.sensors);
          if (WG_debug) console.log('no sensors. Getting them');
          let _devices = [$scope.ngDialogData.device];
          if (_.isArray($scope.ngDialogData.devices)) {
            _devices.push(...$scope.ngDialogData.devices);
          }
          _.forEach(WGApiData.extractDevices(_devices), function (device) {
            if (!device.uuid) {
              return;
            }
            let lkm = $rootScope.lastKnownMessages[device.uuid] || {};
            if (!_.isEmpty(lkm)) {
              let _lsvs_with_data = DataUtils.get_last_sensors_values(null, device.uuid, false, ['BAT_TREAT', 'LORA_INFOS_RSSI', 'WIFI_RSSI']);
              _.forEach(_lsvs_with_data, function (_lsv) {
                if (!_lsv?.sensor?.internal_name) return;
                let internal_name = _lsv.sensor.internal_name;
                let _sensor = _.cloneDeep(_lsv.sensor);
                // if (['BAT_TREAT', 'LORA_INFOS_RSSI', 'WIFI_RSSI'].includes(internal_name)) {
                //   _sensor.admin = false;
                // }
                if (internal_name == 'LORA_INFOS_RSSI') {
                  _sensor.conversion = {
                    id: 'lora_quality',
                    unit: '%',
                    decimals: 0,
                  };
                  _sensor.unit_orig = _sensor.unit;
                  _sensor.unit = _sensor.conversion.unit;
                } else if (internal_name == 'WIFI_RSSI') {
                  _sensor.conversion = {
                    id: 'wifi_quality',
                    unit: '%',
                    decimals: 0,
                  };
                  _sensor.unit_orig = _sensor.unit;
                  _sensor.unit = _sensor.conversion.unit;
                }
                $scope.sensors.push(_sensor);
              });
            }
          });
        }

        $scope.sensor = $scope.ngDialogData.sensor; // parameter_name

        $scope.availableOptions = [
          {id: '1', name: '> (Greater-Than)', name_sref: 'app.modal.alarm.options.GREATER', symbol: '>', logical: '>'},
          {id: '2', name: '< (Less-Than)', name_sref: 'app.modal.alarm.options.LESS', symbol: '<', logical: '<'},
          {
            id: '3',
            name: '≥ (Greater-Than-or-Equal-to)',
            name_sref: 'app.modal.alarm.options.GREATER_EQUAL',
            symbol: '≥',
            logical: '>='
          },
          {
            id: '4',
            name: '≤ (Less-Than-or-Equal-to)',
            name_sref: 'app.modal.alarm.options.LESS_EQUAL',
            symbol: '≤',
            logical: '<='
          },
          {id: '5', name: '= (Equal-to)', name_sref: 'app.modal.alarm.options.EQUAL', symbol: '=', logical: '=='},
          {
            id: '6',
            name: '≠ (Not-Equal-to)',
            name_sref: 'app.modal.alarm.options.NOT_EQUAL',
            symbol: '≠',
            logical: '!='
          }
        ] as IAlarmRuleOptions[];

        for (let option of $scope.availableOptions) {
          if (!option.name_sref)
            continue;
          option.name = option.symbol + ' (' + $translate.instant(option.name_sref) + ')';
        }

        var optionsMapper: { [logical: string]: IAlarmRuleOptions } = {
          '>': $scope.availableOptions[0],
          '<': $scope.availableOptions[1],
          '>=': $scope.availableOptions[2],
          '<=': $scope.availableOptions[3],
          '==': $scope.availableOptions[4],
          '!=': $scope.availableOptions[5]
        };

        $scope.availableRearmOptions = [
          {id: '1', name: '1h', duration: 1, key: 'hours', secondsDuration: 1 * 60 * 60},
          {id: '2', name: '2h', duration: 2, key: 'hours', secondsDuration: 2 * 60 * 60},
          {id: '4', name: '4h', duration: 4, key: 'hours', secondsDuration: 4 * 60 * 60},
          {id: '8', name: '8h', duration: 8, key: 'hours', secondsDuration: 8 * 60 * 60},
          {id: '12', name: '12h', duration: 12, key: 'hours', secondsDuration: 12 * 60 * 60},
          {id: '24', name: '24h', duration: 24, key: 'hours', secondsDuration: 24 * 60 * 60},
          // {id: '0', name: '30s', duration: 30, key: 'second', secondsDuration: 30},
        ];

        angular.forEach($scope.availableRearmOptions, function (option) {
          // @ts-ignore
          option.humanDuration = $filter('amDurationFormat')(option.duration, option.key);
          // option.secondsDuration = moment.duration(option.duration, option.key).asSeconds();
        });

        $scope.sensorSelected = WGApiData.WGSensors.sensors_name[$scope.sensor];
        $scope.alarms = [];
        $scope.alarms_loading = false;
        $scope.has_alarm = false;
        $scope.sensor_select_disabled = false;
        $scope.data = null;

        if (DataUtils.can_device_set_RGB($scope.device)) {
          $scope.actions_available.neo_pixel_rgb = true;
        }


        $rootScope.$on('alarms_updated', (event, args) => {
          if (WG_debug) console.log('alarms_updated');
          getAlarms();
        })
        getAlarms();

        function getAlarms() {
          if (!WGApiData.WGAlarms.ready) return;

          $scope.alarms = emptyOrCreateArray($scope.alarms);
          _.forEach(WGApiData.WGAlarms.alarms, function (alarm) {
            if (!alarm || !alarm.sensor || !alarm.device) return;

            let _device = WGApiData.WGDevices.devices_id[alarm.device.id];
            if (!_device) {
              // device not found. Exit
              return;
            }
            if (_device.id == $scope.device.id
                || _device.iid == $scope.device.iid
                || _device.uuid == $scope.device.uuid) {
              // related_to_device
            } else {
              // not related. Exit
              return;
            }

            let _sensor = WGApiData.WGSensors.sensors_name[alarm.sensor.internal_name];
            if (!_sensor) {
              // sensor not found. Exit
              return;
            }

            AuthService.anonymize_entity("Alarm", alarm);

            let rules: IAlarmRule[] = [];
            _.forEach(alarm.rules, function (rule) {
              rules.push({
                selectedOption: optionsMapper[rule.logical],
                value: rule.value,
                value_conv: convert(rule.value, _sensor?.conversion?.id, null, false),
                logical: rule.logical,
                id: rule.id
              });
            });

            let default_actions: IAlarm['actions'] = {
              email: true,
              sms: false,
              // neo_pixel_rgb_active: true,
              // neo_pixel_rgb: '#FF0000',
            };
            if (_.isNil(alarm.actions)) {
              alarm.actions = default_actions;
            } else
                // @ts-ignore
            if (alarm.actions == '') {
              alarm.actions = default_actions;
            } else {
              try {
                alarm.actions = parseData(alarm.actions, default_actions) as IAlarm['actions'];
              } catch (e) {
                console.error('Unable to load alarm actions', alarm.actions);
                alarm.actions = default_actions;
              }
            }

            if (alarm.actions.neo_pixel_rgb_active) {
              $scope.actions_available.neo_pixel_rgb = true;
            }
            var rearm_interval_option = {};
            angular.forEach($scope.availableRearmOptions, function (option) {
              if (alarm.rearm_interval === option.secondsDuration) {
                rearm_interval_option = option;
                // console.log('rearm_interval', option);
              }
            });

            $scope.alarms.push({
              id: alarm.id,
              name: alarm.name,
              active: alarm.active,
              actions: alarm.actions,
              device: $scope.device,
              owner: alarm.owner,
              sensor: _sensor,
              rules: rules,
              rearm_interval: alarm.rearm_interval,
              rearm_interval_option: rearm_interval_option,
            });
          });
          // if (angular.isDefined($scope.sensorSelected) && $scope.sensorSelected !== null) {
          //   $scope.onSelect($scope.sensorSelected);
          // }
        }

        $scope.convert = function (rule) {
          rule.value_conv = convert(rule.value, $scope.data.sensor?.conversion?.id, null, false);
        }

        $scope.deconvert = function (rule) {
          rule.value = convert(rule.value_conv, $scope.data.sensor?.conversion?.id, null, true);
        }

        $scope.save = function (_data) {
          // console.log('save', _data);
          if (!_data) {
            return;
          }
          if (_data.id && !WGApiData.WGAlarms.alarms_id[_data.id])
            delete _data.id;

          let data = _.cloneDeep(_data);

          data.rearm_interval = data.rearm_interval_option.secondsDuration;
          delete data.rearm_interval_option;

          if (data.sensor.configs?.si_type
              && AuthService.user.configs?.conversions?.[data.sensor.configs.si_type]) {
            if (!data.actions)
              data.actions = {};
            data.actions.conversions = {
              desired_conversion: AuthService.user.configs.conversions[data.sensor.configs.si_type]
            };
          }
          data.actions = JSON.stringify(data.actions);
          parentRet.loading = true;
          parentRet.message = '';
          angular.forEach(data.rules, function (rule: IAlarmRule) {
            rule.logical = rule.selectedOption.logical;
            rule.expression = rule.logical + ' ' + rule.value;
            delete rule.selectedOption;
            if (!data.id) {
              delete rule.id;
            }
          });
          if (WG_debug) console.log('save_alarm', data);


          let http_method = $http.post;
          let url = "api/dashboard/alarm/";
          if (data.id) {
            http_method = $http.patch;
            url += data.id + '/';
          }
          data.timeout = 40000;
          http_method(url, data).then(
              function (response: ng.IHttpPromiseCallbackArg<IAlarm>) {
                console.log('then onFulfilled', response.data);
                if (!response?.data) {
                  console.warn('Failed to save Alarm', response);
                  parentRet.result = 'error';
                  parentRet.loading = false;
                  parentRet.message = 'No data from server';
                  return;
                }

                const key = '?api_key=' + $rootScope.apiKey;
                const alarm_stream = response.data.sensor.internal_name + '/SET_ALARM';
                const url = $rootScope.base_url + 'data/' + $scope.device.path + '/' + alarm_stream + key;
                const payload = {
                  iid: $scope.device.iid,
                  value: response.data,
                  timestamp: new Date().getTime(),
                  timeout: 40000,
                };

                if (WG_debug) console.log('setAlarm_data', url, payload);
                $http.post(url, payload).then(
                    function (response) {
                      if (WG_debug) console.log('setAlarm_data saved', response);

                      WGApiData.WGAlarms.changed = true;
                      WGApiData.update_changed_data();
                    }, function (response) {
                      console.error(response);

                      WGApiData.WGAlarms.changed = true;
                      WGApiData.update_changed_data();
                    });

                parentRet.loading = false;
                parentRet.result = 'success';
                parentRet.message = '';

                $scope.data = null;
                $scope.has_alarm = false;
                $scope.sensor_select_disabled = false;
              },
              function (response) {
                console.error('then onRejected', response);
                parentRet.result = 'error';
                parentRet.loading = false;
                parentRet.message = response?.data?.message || 'Error ' + response?.status;

                WGApiData.WGAlarms.changed = true;
                WGApiData.update_changed_data();
              });

        };

        $scope.remove_alarm = function ($event, item) {
          // console.log('remove_alarm', item);
          if (!angular.isDefined(item)) {
            return;
          }

          WGApiData.WGAlarms.delete(item.id, function (result) {
            if (result == 'success') {
              if (WG_debug) console.log('WGAlarms.delete Success', result);
            } else {
              console.error('WGAlarms.delete Fail!', result);
            }

            if ($scope.data?.id && !WGApiData.WGAlarms.alarms_id[$scope.data.id]) {
              console.info('Selected alarm action changed to Create-New');
              delete $scope.data.id;
            }

            // $scope.data = null;
            // $scope.has_alarm = false;
            // $scope.sensor_select_disabled = false;
          })
        };

        $scope.onNew = function () {
          // console.log('onNew');
          $scope.data = {
            name: '',
            device: $scope.device,
            sensor: $scope.sensorSelected,
            active: true,
            actions: {
              email: true,
              sms: false,
              // neo_pixel_rgb_active: true,
              // neo_pixel_rgb: '#ff0000'
            },
            rules: [],
            rearm_interval_option: $scope.availableRearmOptions[3],
          };

          if (AuthService.clients_view && AuthService.canUserAccess('admin')) {
            $scope.data.owner = {
              id: AuthService.view_as_owner.id,
              username: AuthService.view_as_owner.username,
              email: AuthService.view_as_owner.email,
            };
          } else {
            $scope.data.owner = {
              id: AuthService.user.id,
              username: AuthService.user.username,
              email: AuthService.user.email,
            };
          }
          AuthService.anonymize_entity("User", $scope.data);


          if ($scope.actions_available.neo_pixel_rgb) {
            $scope.data.actions.neo_pixel_rgb_active = true;
            $scope.data.actions.neo_pixel_rgb = '#ff0000';
          }

          parentRet.loading = false;
          parentRet.result = '';
          parentRet.message = '';
          $scope.has_alarm = true;
          $scope.sensor_select_disabled = false;
        };

        $scope.onActiveAlarm = function ($event, item) {
          // console.log('onActiveAlarm', $event, item);
          $event.stopPropagation();
        };

        $scope.onSelect = function ($event, item) {
          // console.log('onSelect', $event, item);
          $scope.data = item;
          $scope.has_alarm = true;
          $scope.sensor_select_disabled = true;

          _.forEach($scope.data.rules, function (rule) {
            $scope.convert(rule);
          });
        };

        $scope.onSelectSensor = function (item) {
          _.forEach($scope.data.rules, function (rule) {
            $scope.deconvert(rule);
          });
          // console.log('onSelectSensor', item);
          // if (angular.isString(item)) {
          //   item = _.find($scope.sensors, function (s) {
          //     return s.internal_name === item;
          //   });
          // }
          // $scope.sensorSelected = item;

          // $scope.sensor = item.internal_name;
          // if (angular.isDefined($scope.alarms[item.internal_name])) {
          //   $scope.data = $scope.alarms[item.internal_name];
          // } else {
          //   $scope.data = $scope.alarms[item.internal_name] = {
          //     name: '',
          //     sensor: item,
          //     rules: []
          //   }
          // }
          //
          // $scope.data.device = $scope.device;
          // if ($scope.data.rules.length === 0) {
          //   $scope.data.rules.push({
          //     selectedOption: $scope.availableOptions[0] // This sets the default value of the select in the ui
          //   });
          // }
        };

        $scope.onColorChange = function (color) {
          // console.log('Color change', color);
        };
        $scope.cancel = function (value) {
          parentRet.loading = false;
          parentRet.result = '';
          parentRet.message = '';
          $scope.closeThisDialog(value);
        };
      }
    }]
  );
}